import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError, AxiosResponse } from 'axios';
import {
  BranchOffice,
  MyBranchOfficesResponse,
  UserNotificationSettings,
  UserProfile,
  UserProfileResponse,
  UserProfileUpdates,
} from '../api/marketx';
import { AxiosCallContext, getCallContext, setAxiosOfficeHeaders } from '../utils/axiosInit';
import { RootStore } from './StoreManager';
import { ErrorStore } from './ErrorStore';
import { AppUserGroupCodes, AppUserOfficeCodes, AppUserRoleCodes } from 'src/types/AppUserGroup';
import { ApiStore } from './Global/ApiStore';
import * as Sentry from '@sentry/nextjs';
import { SessionContextValue, signIn, signOut } from 'next-auth/react';
import Cookies from 'js-cookie';
import { Session } from 'next-auth';

export class AuthStore {
  apiStore: ApiStore;
  errorStore: ErrorStore;
  sessionData?: Session;

  requestTime: Date;
  ignoreBeforeDate?: Date;

  // станет true, когда keycloak свяжется с сервером
  isInitialized = false;

  // станет true, если авторизация в keycloak будет пройдена
  isAuthenticated = false;

  // isLoaded станет true после получения первых данных от бэкенда
  isLoaded = false;

  isAppError = false;

  // адрес для редиректа после авторизации
  loginRedirect: string;

  profile?: UserProfile = null;
  /**
   * Офисы, по которым можно переключаться с наличием нужных прав  isSwitchingBetweenBranches
   */

  myBranchOffices?: BranchOffice[] = [];

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.errorStore = rootStore.getError();
    makeAutoObservable(this, {
      errorStore: false,
    });
  }

  updateProfileSubscribes(subscribes: UserNotificationSettings): void {
    this.profile.notificationSettings.receivePush = subscribes.receivePush;
    this.profile.notificationSettings.receiveWeb = subscribes.receiveWeb;
    this.profile.notificationSettings.receiveEmail = subscribes.receiveEmail;
  }

  get isNewPartnersSchemaVisible(): boolean {
    const permittedOffices = ['mkm-spb', 'mkm-mos', 'mkm-krn'];
    const branchOfficeCode = this.profile.chosenBranchOfficeCode || this.profile.branchOfficeCode;
    return permittedOffices.includes(branchOfficeCode);
  }

  get isDocumentFiltersHidden(): boolean {
    return (
      this.inAnyGroup(['seller', 'seller_chief']) &&
      !this.inAnyGroup(['company_chief', 'division_chief', 'customer_editor', 'division_marketer', 'company_marketer', 'office_chief'])
    );
  }

  isGuest(): boolean {
    if (!this.isLoaded || !this.profile) {
      console.warn('isGuest call on not loaded profile');
      return true;
    }
    return !this.profile.groups || !this.profile.groups.length || this.profile.groups.filter(s => s === 'guest').length > 0;
  }

  inGroup(group: string): boolean {
    return this.inAnyGroup([group]);
  }

  /**
   * Фильтр сотрудников по умолчанию для страницы "Согласование"
   */
  getDefaultApprovalsEmployeeFilter(): string {
    const listType = this.profile?.features?.dealsDefaultFilter;
    if (listType) {
      return listType;
    }
    if (this.inGroup(AppUserGroupCodes.DIVISION_CHIEF)) {
      return undefined;
    }
    return this.getDefaultClientsEmployeeFilter();
  }

  /**
   * Фильтр сотрудников по умолчанию для страницы "Заявки"
   */
  getDefaultDealsEmployeeFilter(): string {
    const listType = this.profile?.features?.dealsDefaultFilter;
    if (listType) {
      return listType;
    }
    return this.getDefaultClientsEmployeeFilter();
  }

  /**
   * Фильтр сотрудников по умолчанию для страницы "Клиенты"
   */
  getDefaultClientsEmployeeFilter(): string {
    const listType = this.profile?.features?.clientsDefaultFilter;
    if (listType) {
      return listType;
    }
    const isOfficeChief = this.inGroup(AppUserGroupCodes.OFFICE_CHIEF); // директор филиала ~myOffice
    const isOfficeMarketer = this.inGroup(AppUserGroupCodes.OFFICE_MARKETER); // маркетолог филиала ~myOffice
    const isSellerChief = this.inGroup(AppUserGroupCodes.SELLER_CHIEF); // руководитель отдела ~myDirectSubdivision
    const isCompanySeller = this.inGroup(AppUserGroupCodes.COMPANY_SELLER); // НОП без подчиненных ?
    const isDivisionMarketer = this.inGroup(AppUserGroupCodes.DIVISION_MARKETER); // маркетолог дивизиона ~myOffice
    const isCompanyMarketer = this.inGroup(AppUserGroupCodes.COMPANY_MARKETER); // маркетолог компании ~myOffice
    const isCallCenterOperator = this.inGroup(AppUserGroupCodes.CALL_CENTER_OPERATOR); // оператор колл-центра ~myOffice

    if (this.inGroup(AppUserGroupCodes.DIVISION_CHIEF)) {
      return AppUserRoleCodes.MY_OFFICE;
    }
    if (isOfficeChief || isDivisionMarketer || isOfficeMarketer || isCallCenterOperator) {
      return AppUserRoleCodes.MY_OFFICE;
    }
    if (isCompanySeller || isCompanyMarketer) {
      return AppUserRoleCodes.OFFICE_COMPANY;
    }
    if (isSellerChief) {
      return AppUserRoleCodes.MY_DIRECT_SUBDIVISION;
    }
    return AppUserRoleCodes.MY;
  }

  hasFrontOfficeAccess(): boolean {
    return this.inAnyGroup(AppUserGroupCodes.ALL_EMPLOYEE_GROUPS) || this.profile?.features?.hasBackofficeAccess;
  }

  inAnyGroup(groups: string[]): boolean {
    if (!this.isLoaded || !this.profile || !this.profile.groups || !groups) {
      return false;
    }
    let found = false;
    groups.forEach((g: string): void => {
      this.profile.groups.forEach((pg: string): void => {
        found = found || g === pg;
      });
    });
    return found;
  }

  /**
   * Наличие прав у данного юзера для переключения офисов
   */
  get isSwitchingBetweenBranches(): boolean {
    return (
      this.inGroup(AppUserGroupCodes.DIVISION_CHIEF) ||
      this.inGroup(AppUserGroupCodes.DIVISION_MARKETER) ||
      this.inGroup(AppUserGroupCodes.COMPANY_MARKETER) ||
      this.profile?.branchOfficeCode === AppUserOfficeCodes.MKMCENTER ||
      this.profile.employee?.branchOffices?.length > 0
    );
  }

  /**
   * Показывать ли возможность изменять офис
   */
  get isShowSwitchingBetweenBranches(): boolean {
    return this.isSwitchingBetweenBranches && this.myBranchOffices.length > 1;
  }

  /**
   * Список филиалов, которые может выбрать сотрудник
   */
  getMyBranchOffices(): void {
    if (this.isSwitchingBetweenBranches) {
      this.loadMyBranchOffices();
    }
  }

  setMyBranchOffice(chosenBranchOfficeCode: string): Promise<void> {
    const updates = { chosenBranchOfficeCode };
    setAxiosOfficeHeaders(chosenBranchOfficeCode, this.apiStore.axios());
    return this.executeUpdate(updates);
  }

  setResultMyBranchOffices(data: MyBranchOfficesResponse): void {
    this.myBranchOffices = data.branchOffices || [];
  }

  loadMyBranchOffices(): void {
    this.apiStore
      .apiClientMy()
      .myBranchOffices()
      .then((res: AxiosResponse<MyBranchOfficesResponse>) => {
        this.setResultMyBranchOffices(res.data);
      });
  }

  async executeUpdate(updates: UserProfileUpdates): Promise<void> {
    await this.apiStore.apiClientUser().userProfileSave({ updates });
    this.reloadHard();
  }

  /**
   * Список филиалов, которые может выбрать сотрудник
   */
  executeOne(): void {
    const rqTime = new Date();
    // console.log('AuthStore: execute', rqTime);
    this.apiStore
      .apiClientUser()
      .userProfile('guest')
      .then((res: AxiosResponse<UserProfileResponse>): void => {
        this.setAppError(false);
        if (this.requestTime && this.requestTime.getTime() > rqTime.getTime()) {
          console.log('ignore irrelevant profile response');
          runInAction(() => {
            this.isLoaded = true;
          });
          return;
        }
        if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > rqTime.getTime()) {
          console.log('ignore irrelevant profile response 2');
          runInAction(() => {
            this.isLoaded = true;
          });
          return;
        }
        this.setResult(getCallContext(res), res.data.profile);
        setAxiosOfficeHeaders(res.data.profile.chosenBranchOfficeCode || res.data.profile.branchOfficeCode, this.apiStore.axios());
      })
      .catch((e: AxiosError) => {
        console.log('AuthStore: execute userProfile', e);
        this.setAppError(true);
        this.setResult(null, <UserProfile>{});
      });
  }

  setAppError(value: boolean): void {
    this.isAppError = value;
  }

  setResult(ctx: AxiosCallContext, data: UserProfile): void {
    runInAction(() => {
      this.requestTime = ctx?.startTime;
      this.profile = data;
      this.isLoaded = true;
    });
    if (this.profile.email) {
      Sentry.setUser({ email: this.profile.email, ip_address: '{{auto}}' });
    } else if (this.profile.employee?.code) {
      Sentry.setUser({ username: this.profile.employee.code, ip_address: '{{auto}}' });
    } else {
      Sentry.configureScope(scope => scope.setUser(null));
    }
    this.getMyBranchOffices();
  }

  // Почистить устаревшие куки (предыдущий способ авторизации)
  cleanDeprecatedCookies(): void {
    Cookies.remove('kcToken');
    Cookies.remove('kcIdToken');
  }

  // Синхронизация куки с SSO-токеном с состоянием стора.
  // Необходимо так как помимо запросов с реакта, браузер клиента делает на бэкенд и другие запросы (скачивание файлов, system tools и т.д.).
  // На бэкенде этот токен парсится как fallback, если не пришёл заголовок
  syncSSOTokenCookie(): void {
    if (this.sessionData?.accessToken) {
      this.cleanDeprecatedCookies();
      Cookies.set('ssoToken', this.sessionData?.accessToken, { secure: true, sameSite: 'Lax' });
    } else {
      Cookies.remove('ssoToken');
    }
  }

  // Сброс состояния и принудительный запуск запроса.
  // Следует вызывать при смене авторизации (login, logout и т.п.).
  reloadHard(): void {
    runInAction(() => {
      this.isLoaded = false;
      this.ignoreBeforeDate = new Date();
      this.syncSSOTokenCookie();
      if (this.isAuthenticated) {
        this.executeOne();
      }
    });
  }

  setSession(session: SessionContextValue): void {
    runInAction(() => {
      // Не позволяем установить сессию с ошибкой
      if (session?.data?.error) {
        signOut().then(this.reloadHard);
        return;
      }
      this.isAuthenticated = session.status === 'authenticated';
      this.sessionData = session.data;
      this.apiStore.setAccessToken(this.sessionData?.accessToken);
      this.syncSSOTokenCookie();
      if (this.isAuthenticated) {
        if (!this.sessionData) {
          this.errorStore.pushAppError(new Error('Не получен токен авторизации от Keycloak'));
        }
        this.executeOne();
      }
      this.isInitialized = session.status !== 'loading';
    });
  }

  /**
   *
   * @param userUri Адрес страницы, на которой находится пользователь
   */
  login(userUri: string): void {
    if (!this.isInitialized) {
      throw new Error('auth is not initialized');
    }
    userUri = userUri || '';
    this.loginRedirect = userUri;
    signIn('keycloak')
      .then(() => {
        this.reloadHard();
      })
      .catch((e: any) => {
        console.warn('login failed', e);
        this.errorStore.pushAppError(new Error('Авторизация не удалась'));
      });
  }

  logout(): void {
    this.apiStore
      .apiClientAuth()
      .authLogout()
      .then(() => {
        return signOut({ redirect: true, callbackUrl: `${window?.location?.origin}` });
      })
      .catch((e: any) => {
        console.warn('logout failed', e);
        this.errorStore.pushAppError(new Error('Не удалось завершить выход из системы'));
      });
  }
}
