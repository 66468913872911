import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formatNumberByUnit } from '@mx-ui/helpers';
import { AppWarehouse } from 'src/types/AppWarehouse';
import { Product, ProductItemStock } from '../../../api/marketx';
import { observer } from 'mobx-react-lite';
import LightTooltip from '../../../components/ToolTip/LightTooltip';
import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';

interface WarehouseDetailsProps {
  product: Product;
  warehouses: ProductItemStock[];
  warehousesList: AppWarehouse[];
  selectedWarehouse?: string; // выбранный склад в фильтрах
}

/**
 * Информация об остатках товара на складе.
 */
export const ProductWarehouseDetails: FC<WarehouseDetailsProps> = observer(
  ({ product, warehouses, warehousesList, selectedWarehouse }): JSX.Element => {
    const matches = useMediaQuery('(min-width:740px)');
    const whs = [...warehouses].sort(function (x, y) {
      return x.warehouseCode === selectedWarehouse ? -1 : y.warehouseCode === selectedWarehouse ? 1 : 0;
    });
    if (!warehouses?.length) {
      return (
        <Box p={2}>
          <Typography variant="body2">Остатков нет</Typography>
        </Box>
      );
    }
    return (
      <>
        {matches ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Склад</TableCell>
                <TableCell align="center">Всего</TableCell>
                <TableCell align="center">Остаток</TableCell>
                <TableCell align="center">Резерв сформирован</TableCell>
                <TableCell align="center">Резерв подтверждён</TableCell>
                <TableCell align="center">Накладные</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {whs.map(ele => {
                const hasAltUnits =
                  ele.unit &&
                  ele.unit === product.baseUnitCode &&
                  product.multiplicityUnitCode &&
                  ele.unit !== product.multiplicityUnitCode &&
                  product.multiplicity &&
                  product.multiplicity !== 1;
                const altFactor = hasAltUnits ? 1 / product.multiplicity : 1;
                const isSelectedWarehouse = selectedWarehouse ? ele.warehouseCode === selectedWarehouse : true;
                const colorText = isSelectedWarehouse ? 'textPrimary' : 'text.secondary';
                const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;

                return (
                  <TableRow key={ele.warehouseCode}>
                    <TableCell align="center">
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {warehousesList.find(wh => wh.code == ele.warehouseCode)?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {ele.inStock ? (
                        hasAltUnits ? (
                          <LightTooltip
                            arrow
                            placement="bottom"
                            TransitionComponent={Fade}
                            title={
                              <Box p={2}>
                                {formatNumberByUnit(ele.inStock * altFactor, product.multiplicityUnitCode, product.multiplicityUnitShort)}
                              </Box>
                            }
                          >
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              {formatNumberByUnit(ele.inStock, ele.unit, ele.unitShortName)}
                            </Typography>
                          </LightTooltip>
                        ) : (
                          formatNumberByUnit(ele.inStock, ele.unit, ele.unitShortName)
                        )
                      ) : (
                        <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                          -
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {ele.inStockForSale ? (
                        <LightTooltip
                          arrow
                          placement="bottom"
                          TransitionComponent={Fade}
                          title={
                            <Box p={2}>
                              {formatNumberByUnit(
                                ele.inStockForSale * altFactor,
                                product.multiplicityUnitCode,
                                product.multiplicityUnitShort
                              )}
                            </Box>
                          }
                        >
                          <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                            {formatNumberByUnit(ele.inStockForSale, ele.unit, ele.unitShortName)}
                          </Typography>
                        </LightTooltip>
                      ) : (
                        <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                          -
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {ele.inSoftReserve ? (
                        <LightTooltip
                          arrow
                          placement="bottom"
                          TransitionComponent={Fade}
                          title={
                            <Box p={2}>
                              {formatNumberByUnit(
                                ele.inSoftReserve * altFactor,
                                product.multiplicityUnitCode,
                                product.multiplicityUnitShort
                              )}
                            </Box>
                          }
                        >
                          <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                            {formatNumberByUnit(ele.inSoftReserve, ele.unit, ele.unitShortName)}
                          </Typography>
                        </LightTooltip>
                      ) : (
                        <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                          -
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {ele.inReserve ? (
                        hasAltUnits ? (
                          <LightTooltip
                            arrow
                            placement="bottom"
                            TransitionComponent={Fade}
                            title={
                              <Box p={2}>
                                {formatNumberByUnit(ele.inReserve * altFactor, product.multiplicityUnitCode, product.multiplicityUnitShort)}
                              </Box>
                            }
                          >
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              {formatNumberByUnit(ele.inReserve, ele.unit, ele.unitShortName)}
                            </Typography>
                          </LightTooltip>
                        ) : (
                          formatNumberByUnit(ele.inReserve, ele.unit, ele.unitShortName)
                        )
                      ) : (
                        <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                          -
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {ele.inConsignment ? (
                        <LightTooltip
                          arrow
                          placement="bottom"
                          TransitionComponent={Fade}
                          title={
                            <Box p={2}>
                              {formatNumberByUnit(
                                ele.inConsignment * altFactor,
                                product.multiplicityUnitCode,
                                product.multiplicityUnitShort
                              )}
                            </Box>
                          }
                        >
                          <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                            {formatNumberByUnit(ele.inConsignment, ele.unit, ele.unitShortName)}
                          </Typography>
                        </LightTooltip>
                      ) : (
                        <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                          -
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          whs.map((ele, index) => {
            const hasAltUnits =
              ele.unit &&
              ele.unit === 'TNE' &&
              product.multiplicityUnitCode &&
              ele.unit !== product.multiplicityUnitCode &&
              product.multiplicity &&
              product.multiplicity !== 1;
            const altFactor = hasAltUnits ? 1 / product.multiplicity : 1;
            const isSelectedWarehouse = selectedWarehouse ? ele.warehouseCode === selectedWarehouse : true;
            const colorText = isSelectedWarehouse ? 'textPrimary' : 'text.secondary';
            const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;
            return (
              <Accordion
                key={index}
                sx={{
                  margin: '0px !important',
                  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
                  ':before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      <Typography variant={'body2'}>{warehousesList.find(wh => wh.code == ele.warehouseCode)?.name}</Typography>
                    </Grid>
                    <Grid item>
                      {ele.inStock ? (
                        hasAltUnits ? (
                          <LightTooltip
                            arrow
                            placement="bottom"
                            TransitionComponent={Fade}
                            title={
                              <Box p={2}>
                                {formatNumberByUnit(ele.inStock * altFactor, product.multiplicityUnitCode, product.multiplicityUnitShort)}
                              </Box>
                            }
                          >
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              {formatNumberByUnit(ele.inStock, ele.unit, ele.unitShortName)}
                            </Typography>
                          </LightTooltip>
                        ) : (
                          formatNumberByUnit(ele.inStock, ele.unit, ele.unitShortName)
                        )
                      ) : (
                        <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                          -
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <Divider />
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant={'body2'}>Склад</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant={'body2'} textAlign={'right'}>
                            {warehousesList.find(wh => wh.code == ele.warehouseCode)?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant={'body2'}>Всего</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                          {ele.inStock ? (
                            hasAltUnits ? (
                              <LightTooltip
                                arrow
                                placement="bottom"
                                TransitionComponent={Fade}
                                title={
                                  <Box p={2}>
                                    {formatNumberByUnit(
                                      ele.inStock * altFactor,
                                      product.multiplicityUnitCode,
                                      product.multiplicityUnitShort
                                    )}
                                  </Box>
                                }
                              >
                                <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                                  {formatNumberByUnit(ele.inStock, ele.unit, ele.unitShortName)}
                                </Typography>
                              </LightTooltip>
                            ) : (
                              formatNumberByUnit(ele.inStock, ele.unit, ele.unitShortName)
                            )
                          ) : (
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              -
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant={'body2'}>Остаток</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                          {ele.inStockForSale ? (
                            <LightTooltip
                              arrow
                              placement="bottom"
                              TransitionComponent={Fade}
                              title={
                                <Box p={2}>
                                  {formatNumberByUnit(
                                    ele.inStockForSale * altFactor,
                                    product.multiplicityUnitCode,
                                    product.multiplicityUnitShort
                                  )}
                                </Box>
                              }
                            >
                              <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                                {formatNumberByUnit(ele.inStockForSale, ele.unit, ele.unitShortName)}
                              </Typography>
                            </LightTooltip>
                          ) : (
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              -
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant={'body2'}>Резерв сформирован</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                          {ele.inSoftReserve ? (
                            <LightTooltip
                              arrow
                              placement="bottom"
                              TransitionComponent={Fade}
                              title={
                                <Box p={2}>
                                  {formatNumberByUnit(
                                    ele.inSoftReserve * altFactor,
                                    product.multiplicityUnitCode,
                                    product.multiplicityUnitShort
                                  )}
                                </Box>
                              }
                            >
                              <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                                {formatNumberByUnit(ele.inSoftReserve, ele.unit, ele.unitShortName)}
                              </Typography>
                            </LightTooltip>
                          ) : (
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              -
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant={'body2'}>Резерв подтверждён</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                          {ele.inReserve ? (
                            hasAltUnits ? (
                              <LightTooltip
                                arrow
                                placement="bottom"
                                TransitionComponent={Fade}
                                title={
                                  <Box p={2}>
                                    {formatNumberByUnit(
                                      ele.inReserve * altFactor,
                                      product.multiplicityUnitCode,
                                      product.multiplicityUnitShort
                                    )}
                                  </Box>
                                }
                              >
                                <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                                  {formatNumberByUnit(ele.inReserve, ele.unit, ele.unitShortName)}
                                </Typography>
                              </LightTooltip>
                            ) : (
                              formatNumberByUnit(ele.inReserve, ele.unit, ele.unitShortName)
                            )
                          ) : (
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              -
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant={'body2'}>Накладные</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                          {ele.inConsignment ? (
                            <LightTooltip
                              arrow
                              placement="bottom"
                              TransitionComponent={Fade}
                              title={
                                <Box p={2}>
                                  {formatNumberByUnit(
                                    ele.inConsignment * altFactor,
                                    product.multiplicityUnitCode,
                                    product.multiplicityUnitShort
                                  )}
                                </Box>
                              }
                            >
                              <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                                {formatNumberByUnit(ele.inConsignment, ele.unit, ele.unitShortName)}
                              </Typography>
                            </LightTooltip>
                          ) : (
                            <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                              -
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </>
    );
  }
);
