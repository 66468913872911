export const AppUserGroupCodes = {
  ADMIN: 'admin' as const,
  AUTHORIZED: 'authorized' as const,
  SELLER: 'seller' as const,
  SELLER_CHIEF: 'seller_chief' as const,
  DIVISION_CHIEF: 'division_chief' as const,
  DIVISION_MARKETER: 'division_marketer' as const,
  COMPANY_SELLER: 'company_seller' as const,
  COMPANY_CHIEF: 'company_chief' as const,
  COMPANY_MARKETER: 'company_marketer' as const,
  CUSTOMER_EDITOR: 'customer_editor' as const,
  RESERVE_ADMIN: 'reserve_admin' as const,

  /**
   * Оператор колл-центра
   */
  CALL_CENTER_OPERATOR: 'call_center_operator' as const,

  /**
   *  Техническая поддержка
   */
  SUPPORT: 'support' as const,

  /**
   * Директор филиала
   */
  OFFICE_CHIEF: 'office_chief' as const,

  /**
   * Маркетолог филиала
   */
  OFFICE_MARKETER: 'office_marketer' as const,
  /**
   * Сотрудник ОКО
   */
  EMPLOYEE_OKO: 'employee_oko' as const,
  /**
   * Сотрудник ОУ
   */
  EMPLOYEE_OU: 'employee_ou' as const,

  /**
   * Менеджер мотивации
   */
  MOTIVATION_RESPONSIBLE: 'motivation_responsible' as const,

  WITHDRAWAL_OF_APPROVAL: 'withdrawal_of_approval' as const,

  /**
   * Согласование соглашений
   */
  AGREEMENT_APPROVING: 'agreement_approving' as const,

  /**
   * Управлеческая отчётность
   */
  MANAGEMENT_REPORTING: 'management_reporting' as const,
  MRC_CONTROL: 'mrc_control' as const,

  /**
   * Администратор склада
   */
  WAREHOUSE_ADMIN: 'warehouse_admin' as const,

  ALL_EMPLOYEE_GROUPS: [
    'admin',
    'developer',
    'tester',
    'company_chief',
    'company_marketer',
    'division_chief',
    'division_marketer',
    'office_marketer',
    'office_chief',
    'seller_chief',
    'seller',
    'motivation_responsible',
    'customer_editor',
    'call_center_operator',
  ],
};

export const AppUserRoleCodes = {
  MY: '~my',
  OFFICE_COMPANY: '~officeCompany',
  MY_OFFICE: '~myOffice',
  MY_DIRECT_SUBDIVISION: '~myDirectSubdivision',
};
export const AppUserOfficeCodes = {
  MKMCENTER: 'mkm-center',
};
