import { RootStore } from '../StoreManager';
import { ApiStore, AxiosCallContext, getCallContext } from '../Global/ApiStore';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import {
  Bill,
  BillState,
  BillsResponse,
  BillsStatesResponse,
  BillShipmentsState,
  BillsShipmentStatesResponse,
  PaymentStatesResponse,
  PaymentState,
  CustomerAsFilter,
  ClientsForFilterResponse,
  Employee,
  BillsSourcesResponse,
  BillSource,
} from '../../api/marketx';
import { formatDateSwagger } from '@mx-ui/helpers';
import { setClear } from 'src/utils/mobx';
import { RouterStore } from '../RouterStore';
import { quickDateRanges, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
import { dateEnd, dateStart, defaultQuickDate } from './utils/defaultQuickDate';
import { AutocompleteValue } from '../../components/Clients/ClientDocuments/Hooks/useClientDocumentsSelect';
import { TotalStoreEntity, totalStoreGenerate } from 'src/components/Documents/DocumentTotalCount';
import { BillItemInvoicingStore } from 'src/components/Bills/BillInvoicing/BillItemInvoicingStore';
import { MyApprovalsAskingStore } from '../Deals/MyApprovalsAsking';
import { AppDealDistributor } from '../../slices/AppDeal';
import { DealListRequest } from '../DealListStore';

const defaultPageSize = 12;
const defaultCustomerFilterCount = 100;

export class BillListRequest {
  customerCode?: string;
  query?: string;
  queryCustomer?: string;
  enrichCustomers?: boolean;
  hasShipments?: boolean;
  dealCode?: string;

  employeeSetCode?: string; // Фильтр по реализатору

  officeCode?: string;
  officeCodes?: string[];
  clientCode?: string;
  quickRange?: string;
  dateFrom?: Date;
  status?: string[];
  paymentStateCodes?: string[];
  shipmentStateCodes?: string[];
  sourceCodes?: string[];
  customerCodes?: string[];
  dateTo?: Date;

  page?: number;
  count?: number;
  customerFilterPage?: number;
  customerFilterCount?: number;
}
export class BillListStore {
  apiStore?: ApiStore;
  routerStore: RouterStore;
  distributorsStore: MyApprovalsAskingStore;
  public ignoreBeforeDate?: Date;
  public isLoading = false;
  public isFilterCustomerLoading = true;
  quickRange: RangeVariant = defaultQuickDate;
  requestInit: BillListRequest = {};
  public isLoaded = false;
  public isMoreLoading = false;
  public routerControlEnabled = false;

  request: BillListRequest = {
    count: defaultPageSize,
    query: '',
    dateFrom: dateStart,
    dateTo: dateEnd,
  };

  public hasMore = false;

  public items = new Array<Bill>();
  public billsStatuses: BillState[] = [];
  public billsSources: BillSource[] = [];
  public documentsCustomersFilterList: CustomerAsFilter[] = [];
  public billShipmentsState: BillShipmentsState[] = [];
  public billPaymentStates: PaymentState[] = [];
  public filterBranchOffices: AutocompleteValue[] = [];
  public initialBranchOffice: AutocompleteValue = null;
  isInitialLoading = true;
  totalStoreEntity: TotalStoreEntity = null;
  public invoicingStore: BillItemInvoicingStore;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.routerStore = rootStore.getRouter();
    this.invoicingStore = new BillItemInvoicingStore();
    this.loadMore = this.loadMore.bind(this);
    this.loadMoreForClient = this.loadMoreForClient.bind(this);

    makeAutoObservable(this, {
      apiStore: false,
    });
  }
  employeeSetChange(currentRole: string, emp?: Employee): void {
    if (!emp) {
      this.mergeRequest({ employeeSetCode: currentRole });
      return;
    }
    let newVal = '';
    if (!this.distributorsStore.employees?.length) {
      return;
    }
    for (let i = 0; i < this.distributorsStore.employees?.length; i++) {
      if (this.distributorsStore.employees[i]?.code === emp?.code || this.distributorsStore.employees[i]?.employee?.code === emp?.code) {
        newVal = this.distributorsStore.employees[i]?.code;
        break;
      }
    }
    if (!newVal) {
      newVal = `~emp~2~${emp?.code}`;
    }
    runInAction(() => {
      const oldVal = this.request.employeeSetCode || null;
      if (newVal !== oldVal) {
        this.employeeSetMergeRequest({ employeeSetCode: newVal }, emp);
      }
    });
  }
  loadMyApprovalsAskingStore(rootStore: RootStore, initEmployee?: string): void {
    this.distributorsStore = new MyApprovalsAskingStore(rootStore);
    this.distributorsStore.loadMyList(initEmployee);
  }
  employeeSetMergeRequest(req: DealListRequest, emp: AppDealDistributor): void {
    this.distributorsStore.loadMyList(emp.employeeCode);
    this.mergeRequest(req);
  }
  mergeRequest(req: BillListRequest): void {
    set(this.request, req);
    this.request.page = undefined;
    this.isLoaded = false;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.actualizeRouter(toJS(this.request));
    this.refresh();
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }
  actualizeRouter(req: BillListRequest): void {
    const reqInit = toJS(this.requestInit);
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.query) {
      params.set('query', req.query);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }
    if (req.employeeSetCode && req.employeeSetCode !== reqInit.employeeSetCode) {
      params.set('employeeSet', req.employeeSetCode);
    }
    if (req.status) {
      params.set('status', `${req.status.join(',')}`);
    }
    if (req.customerCodes) {
      params.set('customerCodes', `${req.customerCodes.join(',')}`);
    }
    if (req.paymentStateCodes) {
      params.set('paymentStateCodes', `${req.paymentStateCodes.join(',')}`);
    }
    if (req.shipmentStateCodes) {
      params.set('shipmentStateCodes', `${req.shipmentStateCodes.join(',')}`);
    }
    if (req.sourceCodes) {
      params.set('sourceCodes', `${req.sourceCodes.join(',')}`);
    }
    if (req.hasShipments) {
      params.set('hasShipments', `${req.hasShipments}`);
    }
    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/bills';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }
  refresh(): void {
    this.loadList(this.request);
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  setRequestInit(req: BillListRequest): void {
    setClear(this.requestInit, req);
  }
  loadList(r: BillListRequest): void {
    this.ignoreBeforeDate = new Date();
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    this.apiStore
      .apiClientBill()
      .bills({
        employeeSet: req.employeeSetCode,
        // branchOfficeCodes: [req.officeCode],
        branchOfficeCodes: req.officeCodes,
        enrichCustomers: req.enrichCustomers,
        enrichActionLogs: true,
        query: req.query || undefined,
        hasShipments: req.hasShipments || undefined,
        dealCode: req.dealCode,
        customerCode: req.clientCode,
        customerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        page: req.page || undefined,
        count: req.count,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        stateCodes: req.status?.length ? req.status : undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        shipmentStateCodes: req.shipmentStateCodes?.length ? req.shipmentStateCodes : undefined,
        sourceCodes: req.sourceCodes?.length ? req.sourceCodes : undefined,
      })
      .then((res): void => {
        this.loadListResponse(getCallContext(res), req, res.data);
      });
  }
  loadListForeDeal(r: BillListRequest): void {
    this.ignoreBeforeDate = new Date();
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    this.apiStore
      .apiClientBill()
      .bills({
        employeeSet: req.employeeSetCode,
        branchOfficeCodes: req.officeCode ? [req.officeCode] : undefined,
        enrichCustomers: req.enrichCustomers,
        enrichActionLogs: true,
        hasShipments: req.hasShipments || undefined,
        query: req.query || undefined,
        dealCode: req.dealCode,
        customerCode: req.clientCode,
        page: req.page || undefined,
        count: req.count,
        dateFrom: undefined,
        dateTo: undefined,
      })
      .then((res): void => {
        this.loadListResponse(getCallContext(res), req, res.data);
      });
  }

  loadCustomersForFilter(queryCustomer?: string): void {
    if (queryCustomer) {
      this.request.queryCustomer = queryCustomer;
    } else {
      this.request.queryCustomer = undefined;
    }
    if (this.routerControlEnabled) {
      this.loadFilterCustomerCodes();
    }
  }

  loadFilterCustomerCodes(): void {
    runInAction(() => {
      this.isFilterCustomerLoading = true;
    });
    const req = Object.assign({}, this.request);
    req.customerFilterPage = req.customerFilterPage > 1 ? req.customerFilterPage : undefined;
    req.customerFilterCount = req.customerFilterCount || defaultCustomerFilterCount;
    this.apiStore
      .apiClientCustomer()
      .clientsForFilter({
        documentType: 'bill',
        requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        employeeSet: req.employeeSetCode || undefined,
        hasShipments: req.hasShipments || undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        billShipmentStateCodes: req.shipmentStateCodes?.length ? req.shipmentStateCodes : undefined,
        billSourceCodes: req.sourceCodes?.length ? req.sourceCodes : undefined,
        billStateCodes: req.status?.length ? req.status : undefined,
        queryDocuments: req.query || undefined,
        page: req.customerFilterPage || undefined,
        count: req.customerFilterCount,
        queryCustomers: req.queryCustomer || undefined,
      })
      .then(res => {
        this.setFilterCustomerResult(res.data);
      })
      .catch(e => {
        console.warn('loadFilterCustomerCodes req', e);
        runInAction(() => {
          this.isFilterCustomerLoading = false;
        });
      });
  }

  setFilterCustomerResult(statuses: ClientsForFilterResponse): void {
    if (statuses.customers?.length) {
      const prevCheckedCustomers = [];
      this.documentsCustomersFilterList.forEach(prevI => {
        if (this.request.customerCodes.includes(prevI.code) && !statuses.customers.find(i => i.code === prevI.code)) {
          prevCheckedCustomers.push(prevI);
        }
      });
      if (this.request?.customerCodes?.length) {
        this.documentsCustomersFilterList = [...prevCheckedCustomers, ...statuses.customers];
      } else {
        this.documentsCustomersFilterList = [...statuses.customers];
      }
    }
    runInAction(() => {
      this.isFilterCustomerLoading = false;
    });
  }

  // загрузка справочника статусов сделок
  loadBillsStatuses(): void {
    this.apiStore
      .apiClientBill()
      .billsStates()
      .then(res => {
        this.setBillsStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadBillStatuses', e);
      });
  }
  loadBillsSources(): void {
    this.apiStore
      .apiClientBill()
      .billsSources()
      .then(res => {
        this.setBillsSourcesResult(res.data);
      })
      .catch(e => {
        console.warn('loadBillsSourcesК', e);
      });
  }
  setBillsStatusesResult(data: BillsStatesResponse): void {
    this.billsStatuses = data.states || [];
  }
  setBillsSourcesResult(data: BillsSourcesResponse): void {
    this.billsSources = data.sources || [];
  }
  loadBillShipmentStates(): void {
    this.apiStore
      .apiClientBill()
      .billsShipmentStates()
      .then(res => {
        this.setBillShipmentStates(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  loadBillPaymentStates(): void {
    this.apiStore
      .apiClientPayment()
      .paymentStates()
      .then(res => {
        this.setBillPaymentStates(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  setBillShipmentStates(data: BillsShipmentStatesResponse): void {
    this.billShipmentsState = data.billShipmentsStates || [];
  }

  setBillPaymentStates(data: PaymentStatesResponse): void {
    this.billPaymentStates = data.paymentStates || [];
  }
  resetItems(): void {
    runInAction(() => {
      this.items = [];
    });
  }
  reconcileBill(documentNumber: string, position?: Record<string, string>): Promise<void> {
    let req = {};
    if (position) {
      req = {
        full: false,
        productCode: position.productCode,
        warehouseCode: position.warehouseCode,
      };
    } else {
      req = {
        full: true,
      };
    }
    return this.apiStore
      .apiClientBill()
      .billsItemReconcile(documentNumber, req)
      .then(() => {
        this.refresh();
      })
      .catch(err => {
        console.warn('positionReserveRequest', err);
      });
  }
  loadListResponse(ctx: AxiosCallContext, req: BillListRequest, res: BillsResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      console.log('ignore irrelevant consignee list response');
      return;
    }
    this.ignoreBeforeDate = ctx.startTime;
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...res.bills);
    } else {
      this.items = res.bills;
    }
    this.totalStoreEntity = totalStoreGenerate(res.billsTotalCount, res.billsTotalWeight, res.billsTotalCost);
    this.isLoading = false;
    this.isLoaded = true;
    this.hasMore = res.bills.length >= req.count;

    this.loadCustomersForFilter();
  }

  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.request.page = (this.request.page || 1) + 1;
    this.loadList(this.request);
  }

  // ↓ методы для загрузки счетов со страницы клиента (документы)
  setInitialOffice(code: string, name: string): void {
    this.initialBranchOffice = { code, name };
  }
  loadMoreForClient(officesItems: AutocompleteValue[]): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    runInAction(() => {
      this.isMoreLoading = true;
      this.request.officeCodes = officesItems.some(i => i.code === 'selectAll')
        ? this.filterBranchOffices.filter(i => i.code !== 'selectAll').map(i => i.code)
        : officesItems.map(i => i.code);
      this.request.page = (this.request.page || 1) + 1;
    });
    this.loadFromClientDocuments(this.request);
  }
  mergeRequestToClient(req: BillListRequest): void {
    set(this.request, req);
    this.request.page = undefined;
    this.isMoreLoading = false;
    this.loadFromClientDocuments(this.request);
  }
  loadFromClientDocuments(r: BillListRequest): void {
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    this.apiStore
      .apiClientBill()
      .bills({
        branchOfficeCodes: this.isInitialLoading && !req.officeCodes?.length ? undefined : req.officeCodes,
        customerCode: req.clientCode,
        enrichActionLogs: true,
        page: req.page || undefined,
        hasShipments: req.hasShipments || undefined,
        count: req.count,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        stateCodes: req.status?.length ? req.status : undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        shipmentStateCodes: req.shipmentStateCodes?.length ? req.shipmentStateCodes : undefined,
        sourceCodes: req.sourceCodes?.length ? req.sourceCodes : undefined,
      })
      .then((res): void => {
        this.loadForClientResult(req, res.data);
      });
  }
  loadForClientResult(req: BillListRequest, res: BillsResponse): void {
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...res.bills);
      if (!this.filterBranchOffices.length) {
        this.filterBranchOffices = res.billsBranchOffices as AutocompleteValue[];
      }
    } else {
      this.items = res.bills;
      this.filterBranchOffices = res.billsBranchOffices as AutocompleteValue[];
    }
    if (!this.filterBranchOffices.some(item => item.code === this.initialBranchOffice.code)) {
      this.filterBranchOffices.push(this.initialBranchOffice);
    }
    if (this.filterBranchOffices.length > 1 && !this.filterBranchOffices.some(item => item.code === 'selectAll')) {
      this.filterBranchOffices = [{ code: 'selectAll', name: 'Все филиалы' }, ...this.filterBranchOffices];
    }
    this.totalStoreEntity = totalStoreGenerate(res.billsTotalCount, res.billsTotalWeight, res.billsTotalCost);
    this.isInitialLoading = false;
    this.isLoading = false;
    this.isLoaded = true;
    this.hasMore = res.bills.length >= req.count;
  }
}
