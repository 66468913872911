import { ApiStore } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import { makeAutoObservable } from 'mobx';
import {
  CatalogProductsAlternativesNomenclature,
  CatalogProductsAlternativesResponse,
  CatalogProductsAlternativesSegmentationGroup,
  DealsItemManufacturersResponse,
  Product,
} from '../../api/marketx';
import { AppDeal, AppDealPosition } from '../../slices/AppDeal';
import { AxiosResponse } from 'axios';

/**
 * Загруженные данные по позиции
 */
interface PositionManufacturers {
  canViewPurchasePrice?: boolean | null;
  positionCode: string;
  productCode: string;
  nomenclature: CatalogProductsAlternativesNomenclature;
  segmentationGroup: CatalogProductsAlternativesSegmentationGroup;
}

/**
 * Стор с альтернативными производителями характеристик для сделки.
 * Загружает альтернативы ко всем позициям сделки разом.
 */
export class PositionsManufacturersListStore {
  apiStore: ApiStore;

  loaded = false;
  loading = false;
  loadedEpoch = 0;

  /**
   * Задержка для защиты от повторных запросов (мс)
   */
  requestDuplicationProtectionDelayMs = 100;

  lastRequestHash = '';
  lastRequestTime: Date = null;

  positionsManufacturersMap = new Map<string, PositionManufacturers>();

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  getPositionManufacturers(deal: AppDeal, position: AppDealPosition): PositionManufacturers | undefined {
    const pm = this.positionsManufacturersMap.get(position.code);
    if (!pm || pm.productCode !== position.productCode) {
      // Данные неактуальные или отсутствуют, надо перезагрузить.
      this.loadForDeal(deal);
    }
    return pm;
  }

  getPositionManufacturersForProduct(product: Product): PositionManufacturers | undefined {
    return this.positionsManufacturersMap.get(product.code);
  }

  loadForProduct(productCode: string, selectedWhCode: string): void {
    this.loading = true;
    if (!productCode) {
      this.lastRequestTime = new Date();
      this.setLoadResult({});
    }
    this.positionsManufacturersMap.set(productCode, <PositionManufacturers>{
      productCode: productCode,
    });

    const requestTime = new Date();
    this.lastRequestTime = requestTime;

    this.apiStore
      .apiClientCatalog()
      .catalogProductsAlternatives({ positionCodes: [productCode], stockRequired: true, warehouseCode: selectedWhCode })
      .then((res: AxiosResponse<DealsItemManufacturersResponse>) => {
        if (requestTime < this.lastRequestTime) {
          // Уже был отправлен другой запрос
          return;
        }
        this.setLoadResult(res.data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  loadForDeal(deal: AppDeal): void {
    if (!deal?.positions?.length) {
      this.lastRequestTime = new Date();
      this.setLoadResult({});
    }

    const positionCodes = new Array<string>();
    // Расчет хэша, чтобы не запрашивать одно и то же
    const requestHashSrc = [deal.code, deal.warehouseCode];
    // Мапа, чтобы удалить ненужные данные из positionsManufacturersMap.
    const dealsPositionsMap = new Map<string, AppDealPosition>();
    deal.positions.forEach(p => {
      requestHashSrc.push(p.code, p.productCode);
      dealsPositionsMap.set(p.code, p);
      positionCodes.push(p.code);
      const pm = this.positionsManufacturersMap.get(p.code);
      if (pm) {
        pm.productCode = p.productCode;
      } else {
        this.positionsManufacturersMap.set(p.code, <PositionManufacturers>{
          positionCode: p.code,
          productCode: p.productCode,
        });
      }
    });
    const requestHash = JSON.stringify(requestHashSrc);

    if (this.lastRequestHash !== requestHash) {
      // Какие-то различия есть, возможно нужно удалить записи
      // по несуществующим позициям.
      this.positionsManufacturersMap.forEach(pm => {
        if (!dealsPositionsMap.has(pm.positionCode)) {
          this.positionsManufacturersMap.delete(pm.positionCode);
        }
      });
    } else {
      // Товары те же самые.
      const requestDeltaTime = new Date().getTime() - (this.lastRequestTime?.getTime() || 0);
      if (requestDeltaTime < this.requestDuplicationProtectionDelayMs) {
        // Повторный запрос тех же данных.
        // Происходит часто, т.к. каждая позиция сделки запрашивает данные.
        return;
      }
    }

    const requestTime = new Date();
    this.lastRequestTime = requestTime;
    this.loading = true;

    this.apiStore
      .apiClientCatalog()
      .catalogProductsAlternatives({ dealCode: deal.code, positionCodes, stockRequired: true })
      .then((res: AxiosResponse<DealsItemManufacturersResponse>) => {
        if (requestTime < this.lastRequestTime) {
          // Уже был отправлен другой запрос
          return;
        }
        this.setLoadResult(res.data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  reloadForDealPosition(dealCode: string, position: AppDealPosition): void {
    const requestTime = new Date();
    this.lastRequestTime = requestTime;

    this.apiStore
      .apiClientCatalog()
      .catalogProductsAlternatives({ dealCode, positionCodes: [position.code], stockRequired: true })
      .then((res: AxiosResponse<DealsItemManufacturersResponse>) => {
        if (requestTime < this.lastRequestTime) {
          // Уже был отправлен другой запрос
          return;
        }
        this.setLoadResult(res.data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  setLoadResult(res: CatalogProductsAlternativesResponse): void {
    res.positions?.forEach(pos => {
      const mp = this.positionsManufacturersMap.get(pos.positionCode);
      if (mp) {
        mp.canViewPurchasePrice = res.canViewPurchasePrice;
        mp.nomenclature = pos.nomenclature;
        mp.segmentationGroup = pos.segmentationGroup;
      }
    });
    this.loaded = true;
    this.loading = false;
    this.loadedEpoch++;
  }
}
