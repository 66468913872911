import React, { CSSProperties, FC, JSX } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AgreementListStore } from '../../store/Clients/AgreementListStore';
import { ContractCostAgreement } from '../../api/marketx';
import { AgreementListItem } from './AgreementListItem';
import { AgreementTableView } from './AgreementTableView';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface AgreementListSkeletonProps {
  viewType: string;
  isSimpleDoc?: boolean;
}
export const SalesListSkeleton: FC<AgreementListSkeletonProps> = ({ viewType = 'list', isSimpleDoc = false }): JSX.Element => {
  const mockArr = viewType === 'list' ? [1, 2, 3, 4, 5, 6, 7, 8] : viewType === 'list-shortcut' ? [1, 2, 3, 4] : [];
  const arrForSkeletonList = isSimpleDoc ? [1] : [1, 2, 3];
  return (
    <>
      {arrForSkeletonList.map(i => (
        <Paper key={i} sx={{ height: '100%', padding: 1, position: 'relative', mb: '16px' }}>
          <Grid container>
            <Grid item md={5} xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Skeleton width={'80%'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton width={'30%'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton width={'90%'} />
                  </Grid>
                </Grid>
                {mockArr.map(i => (
                  <Grid container key={i}>
                    <Grid item xs={6}>
                      <Skeleton width={'90%'} />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton width={'40%'} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item md={7} xs={12}>
              {mockArr.map(i => (
                <Grid container key={i}>
                  <Grid item xs={6}>
                    <Skeleton width={'90%'} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton width={'60%'} />
                  </Grid>
                </Grid>
              ))}
              {viewType === 'table-view' &&
                [1, 2].map(i => (
                  <Grid container key={i}>
                    <Grid item xs={6}>
                      <Skeleton width={'90%'} />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton width={'60%'} />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            {viewType === 'list' && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Skeleton width={'50%'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'} spacing={2}>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map(i => (
                        <Grid item width={'100px'} key={i}>
                          <Skeleton width={'100%'} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      ))}
    </>
  );
};

interface AgreementListProps {
  agreements: AgreementListStore;
  viewType?: string;
}

// Список соглашений для подтверждения
export const AgreementStackList: FC<{ items: ContractCostAgreement[]; viewType?: string; agreementsStore?: AgreementListStore }> = observer(
  ({ items = [], viewType, agreementsStore }): JSX.Element => {
    if (!items?.length) {
      return <Box minHeight={300}>Нет данных</Box>;
    }
    return (
      <Stack width="100%">
        {viewType === 'table-view'
          ? items.map((agreement: ContractCostAgreement) => (
              <AgreementTableView key={agreement.code} agreement={agreement} agreementsStore={agreementsStore} />
            ))
          : items.map((agreement: ContractCostAgreement) => (
              <AgreementListItem
                key={agreement.code}
                agreement={agreement}
                isFullSize={viewType === 'list'}
                agreementsStore={agreementsStore}
              />
            ))}
      </Stack>
    );
  }
);

const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;

export const AgreementList: FC<AgreementListProps> = observer(({ agreements, viewType = 'list' }): JSX.Element => {
  if (!agreements.isLoaded) {
    return <SalesListSkeleton viewType={viewType} />;
  }

  if (!agreements.items?.length) {
    return <Box minHeight={300}>Нет данных</Box>;
  }

  return (
    <div>
      <InfiniteScroll
        dataLength={agreements.items.length}
        next={agreements.loadMore}
        hasMore={agreements.hasMore}
        style={listStyle}
        loader={listSkeleton}
      >
        <AgreementStackList items={agreements.items} viewType={viewType} agreementsStore={agreements} />
      </InfiniteScroll>
    </div>
  );
});
