import React, { CSSProperties } from 'react';

import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { VariationPlacement } from '@popperjs/core/lib/enums';
import { Modifier } from '@popperjs/core/lib/popper-lite';

// import BarChartIcon from '@mui/icons-material/BarChart';

interface CustomPopperOnClose {
  (): void;
}

interface CustomPopperProps {
  isOpen: boolean;
  anchorEl: any;
  onClose: CustomPopperOnClose;
  style?: CSSProperties;
  disablePortal?: boolean;
  modifiers?: Partial<Modifier<any, any>>[];
  sx?: CSSProperties;
  children: JSX.Element;
  placement?: VariationPlacement;
}

export const CustomPopper = (props: CustomPopperProps): JSX.Element => {
  const { isOpen, anchorEl, onClose, style, children, sx, placement = 'bottom', disablePortal = false, modifiers } = props;

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      modifiers={modifiers}
      placement={placement}
      style={{ zIndex: 1200 }} // header has 1300
      transition
      disablePortal={disablePortal}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper elevation={3} style={{ minWidth: '350px', ...style, ...sx }}>
            <ClickAwayListener onClickAway={onClose}>
              <Box p={1.5}>{children}</Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
