import React, { FC, useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import { observer } from 'mobx-react-lite';
import { AppBarColor } from '../DashboardLayout/TopBar/AppbarColor';
import { useAuthStore } from '../../store/MobxStoreProvider';
import { FeedbackButton } from '../DashboardLayout/FeedbackButton';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GlobalStyles from '@mui/material/GlobalStyles';
import Typed from '@mx-ui/ui/Typed';
import { Link } from '@mx-ui/ui';
// import Snowfall from 'react-snowfall';

const PREFIX = 'Landing';

const classes = {
  toolbarLink: `${PREFIX}-toolbarLink`,
  brandName: `${PREFIX}-brandName`,
  appBarColor: `${PREFIX}-appBarColor`,
  '@global': `${PREFIX}-undefined`,
  heroContent: `${PREFIX}-heroContent`,
  heroSubHeader: `${PREFIX}-heroSubHeader`,
  heroHeader: `${PREFIX}-heroHeader`,
  heroText: `${PREFIX}-heroText`,
  toolbar: `${PREFIX}-toolbar`,
  heroButtons: `${PREFIX}-heroButtons`,
  ctaButton: `${PREFIX}-ctaButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .MuiButton-root`]: {
    textTransform: 'none',
  },
  [`& .${classes.toolbarLink}`]: {
    color: '#fff',
    marginLeft: 12,
    fontWeight: 600,
  },
  [`& .${classes.toolbar}`]: {
    paddingRight: 0,
    minHeight: '54px',
    lineHeight: 1,
    fontSize: '0px',
    paddingLeft: '0px',
  },
  [`& .${classes.appBarColor}`]: {
    borderRadius: 'inherit',
    boxShadow: 'none',
    border: 'none',
  },
  [`& .${classes.heroContent}`]: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },

  [`& .${classes.heroSubHeader}`]: {
    color: '#fff',
    fontSize: '3rem',
    fontWeight: 700,

    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '2rem',
    },
  },

  [`& .${classes.heroHeader}`]: {
    color: '#fff',
    // textShadow: '2px 2px 4px #333',
    fontSize: '7.8rem', // 64
    fontWeight: 700,

    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '5rem',
    },
  },

  [`& .${classes.heroText}`]: {
    color: '#eee',
    fontSize: '1.7rem',
    marginTop: theme.spacing(3),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1.3rem',
    },
  },

  [`& .${classes.heroButtons}`]: {
    marginTop: theme.spacing(4),
    color: '#fff',
    '& a': {
      color: '#fff',
    },
  },

  [`& .${classes.ctaButton}`]: {
    backgroundColor: '#fc0',
    color: '#273377',
    '&:hover': {
      backgroundColor: '#ffd54f',
      color: '#000',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '80%',
    },
  },
}));
const typedOptions = { strings: ['умная', 'выгодная', 'прозрачная'], typeSpeed: 160, loop: true };

interface LandingProps {
  // адрес для редиректа, если он происходит
  redirectUri?: string;
}

type LandingState = 'dummy' | 'initializing' | 'loading' | 'guest' | 'noAccess' | 'employee' | 'appError';

export const Landing: FC<LandingProps> = observer(({ redirectUri }): JSX.Element => {
  const auth = useAuthStore();
  const handleClickLogin = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      auth.login(`${window?.location?.pathname || ''}${window?.location?.search || ''}`);
    },
    [auth]
  );

  const handleClickLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const [state, setState] = useState<LandingState>('dummy');

  useEffect((): void => {
    if (!auth.isInitialized) {
      // keycloak еще не связался с сервером
      setState('initializing');
      return;
    }
    if (!auth.isAuthenticated) {
      // Связь с keycloak установлена, пользователь там не авторизован
      setState('guest');
      return;
    }
    if (!auth.isLoaded) {
      // Загружается профиль с бэкенда
      setState('loading');
      return;
    }
    if (auth.isAppError) {
      // Не удалось связаться с бэкендом
      setState('appError');
      return;
    }
    if (auth.isGuest()) {
      // Бэкенд говорит, что текущий пользователь - гость
      setState('noAccess');
      return;
    }
    if (!auth.hasFrontOfficeAccess()) {
      setState('noAccess');
      return;
    }
    setState('employee');
  }, [auth, auth.isLoaded, auth.isInitialized, auth.profile]);

  return (
    <Root>
      <Head>
        <title>MarketX.pro - умная сделка</title>
      </Head>
      <AppBarColor className={classes.appBarColor}>
        <Toolbar className={classes.toolbar}>
          <Link href="/" noTypography>
            <img src="/android-chrome-192x192.png" width={54} height={54} alt={''} />
          </Link>
          <Link className={classes.toolbarLink} href="/" variant="h6" color="inherit" sx={{ flexWrap: 'nowrap' }}>
            MarketX
          </Link>
          {/* <Typography className={classes.brandName} variant="h4" color="inherit" noWrap> */}
          {/* </Typography> */}
        </Toolbar>
      </AppBarColor>
      <main>
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: '#505050',
              // marginRight: '-17px !important',
              overflow: 'hidden',
              // backgroundImage: 'url(/images/landing-bg.png)',
              // backgroundSize: '100% 100%',
              // backgroundRepeat: 'no-repeat',
              // backgroundAttachment: 'fixed',
            },
          }}
        />
        {/* <Snowfall /> новогодние приколюхи */}
        <div style={{ position: 'absolute', top: '9px', right: '20px', zIndex: 1900 }}>
          <FeedbackButton style={{ color: '#fff' }} />
        </div>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography className={classes.heroSubHeader} component="h1" variant="h1" align="center" color="textPrimary" gutterBottom>
              {' '}
              <span className={classes.heroHeader}>MarketX</span>
              <br />
              <Typed color={'#fc0'} options={typedOptions} /> сделка
            </Typography>
            <Typography className={classes.heroText} variant="h4" align="center" color="text.secondary" paragraph>
              Продавайте больше и&nbsp;на&nbsp;более выгодных&nbsp;условиях.
              <br />
              Используйте свое время эффективно.
            </Typography>

            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  {state === 'dummy' && (
                    <>
                      <p>Загрузка приложения...</p>
                    </>
                  )}
                  {state === 'initializing' && (
                    <>
                      <p>Загрузка сессии...</p>
                    </>
                  )}
                  {state === 'loading' && (
                    <>
                      <p>Загрузка профиля...</p>
                    </>
                  )}
                  {state === 'appError' && (
                    <>
                      <p style={{ color: '#fff' }}>Не удалось связаться с сервером приложений.</p>
                      <p style={{ color: '#fff' }}>
                        Должно быть это временная проблема,
                        <br />
                        попробуйте через пару минут{' '}
                        <a href={window.location.href} style={{ color: '#fff' }}>
                          обновить страницу
                        </a>
                        .
                      </p>
                    </>
                  )}
                  {state === 'guest' && (
                    <>
                      <Button onClick={handleClickLogin} className={classes.ctaButton} variant="contained" color="primary">
                        Войти в систему
                      </Button>
                    </>
                  )}
                  {state === 'noAccess' && (
                    <>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs />
                        <Grid item xs={8}>
                          <p>
                            Авторизация прошла успешно, однако для&nbsp;текущего пользователя не&nbsp;определены права&nbsp;доступа
                            {auth.profile?.email ? ' (' + auth.profile.email + ').' : '.'}
                          </p>
                          <p>Пожалуйста, свяжитесь с&nbsp;руководителем и&nbsp;поддержкой для&nbsp;решения.</p>
                          <p style={{ justifyContent: 'center' }}>
                            <Button onClick={handleClickLogout} className={classes.ctaButton} variant="contained" color="primary">
                              Выйти из&nbsp;системы
                            </Button>
                          </p>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </>
                  )}
                  {state === 'employee' && (
                    <>
                      <p>
                        <a href={redirectUri}>Переход на&nbsp;домашнюю страницу...</a>
                      </p>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </main>
    </Root>
  );
});
