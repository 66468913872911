import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { BillItemStore } from '../../store/Documents/BillItemStore';
import { RemovalPositionReserve } from './RemovalPositionReserve';
import { BillPosition } from '../../api/marketx';

interface BillReserveButtonsProps {
  billStore: BillItemStore;
  disabled?: boolean;
  additionalCondition?: boolean;
  onReserved?: () => void;
  onUnReserved?: () => void;
  position?: BillPosition;
}

export const BillReserveButtons: FC<BillReserveButtonsProps> = observer(
  ({ billStore, disabled, onReserved, onUnReserved, additionalCondition = true, position }): JSX.Element => {
    return (
      <>
        {(billStore.item?.state?.code === 'partially_reserved' ||
          billStore.item?.state?.code === 'reserve_withdrawn' ||
          billStore.item?.state?.code === 'reserve_unconfirmed' ||
          billStore.item?.state?.code === 'reserve_confirmed') &&
          billStore.item?.hasUpdateAccess &&
          !billStore.item.hasSubordinateDoc && (
            <Grid item>
              {billStore.item?.canWithdrawSoftReserve && additionalCondition ? (
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <Button startIcon={<LockOpenIcon />} color="error" onClick={onUnReserved} disabled={billStore.isEditing}>
                      Снять резерв
                    </Button>
                  </Grid>
                  {position && (
                    <Grid item>
                      <RemovalPositionReserve billStore={billStore} position={position} />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Button startIcon={<LockClockIcon />} onClick={onReserved} disabled={disabled || billStore.isEditing}>
                  Резервировать
                </Button>
              )}
            </Grid>
          )}
      </>
    );
  }
);
