/* eslint-disable complexity */
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Chip, { ChipProps } from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Skeleton from '@mui/material/Skeleton';
import SvgIcon from '@mui/material/SvgIcon';
import Collapse from '@mui/material/Collapse';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomCard from 'src/views/deals/components/CustomCard';
import { AppDealPosition, AppDealPositionUpdates } from '../../../slices/AppDeal';
import { formatNumberByUnit, formatPriceCur, inputValueToFloatPositiveString, toFloat } from '@mx-ui/helpers';
import { DealItemStore } from '../../../store/DealItemStore';
import { ContractCostPosition, DealPositionMrcUserGroup, DealPositionUnitCostTypeEnum } from '../../../api/marketx';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import { AppUserGroupCodes } from 'src/types/AppUserGroup';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ValueStoreInputTypes } from 'src/store/ValueStore';
import { SyncedTextField } from 'src/components/SyncedInputs/SyncedTextField';
import {
  ColorsStockLinearProgress,
  getQuantityPercentAndStock,
  StockLinearProgress,
} from 'src/components/Agreements/AgreementPositionItem/StockLinearProgress';
import { HandShakeIcon } from 'src/components/ui/icons/HandShakeIcon';
import { renderPriceCur } from '../../../utils/renderFormat';
import CalculateIcon from '@mui/icons-material/Calculate';
import ProductConverter from '../../../components/Product/ProductConverter/ProductConverter';
import { multicoloredPopoverBg } from '../../clients/lib';
import { ColorChangedPropertyDeal } from '../DealContainer';
import LightTooltip from 'src/components/ToolTip/LightTooltip';
import { AllowedColors } from '@mx-ui/ui';
import { Divider } from '@mui/material';
import { blue } from '@mui/material/colors';

export const ChipTitleStyled = styled(Chip)(({ theme }) => {
  return {
    marginRight: '8px',
    backgroundColor: '#808185',
    color: theme.palette.text.light,
    fontWeight: 700,
    display: 'inline',
  };
});

export const ChipHandleDnD = observer(
  ({
    dealStore,
    position,
    dragHandleProps,
    ...rest
  }: {
    dealStore: DealItemStore;
    position: AppDealPosition;
    dragHandleProps?: DraggableProvidedDragHandleProps;
  } & ChipProps): ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const firstIndex = 1;
    const lastIndex = 10000;

    const open = Boolean(anchorEl);
    const handleClick = (event): void => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
      setAnchorEl(null);
    };
    const handleMove = (newPositionForPositionOfDeal: number): void => {
      dealStore.movePositionOfDeal(position.code, newPositionForPositionOfDeal, position.lineNumber);
      handleClose();
    };

    return (
      <span>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => handleMove(firstIndex)}>Переместить на самый вверх</MenuItem>
          <MenuItem onClick={() => handleMove(lastIndex)}>Переместить в самый низ</MenuItem>
        </Menu>
        <ChipTitleStyled
          onClick={dragHandleProps ? handleClick : null}
          {...dragHandleProps}
          {...rest}
          {...(position.changesLog ? { sx: { backgroundColor: '#AFDEEF', color: '#465960' } } : {})}
        />
      </span>
    );
  }
);
type ColorForIcon = 'green' | 'yellow' | 'red' | 'grey';

export const getColorForIconByMRC = (mrcUserGroups: DealPositionMrcUserGroup[] = [], current: DealPositionMrcUserGroup): ColorForIcon => {
  if (current && current.isMRCFine) {
    return 'green';
  }
  if (mrcUserGroups?.filter(i => i.isMRCFine).length > 0) {
    return 'yellow';
  } else {
    return 'red';
  }
};
const InfoIconStyle = {
  width: '21px',
  height: '21px',
} as SxProps<Theme>;

/**
 * Иконка с символом (i)
 * @param color
 * @param props
 */
export const InfoIconDeal: FC<{ color: ColorForIcon | string } & BoxProps> = ({ color, ...props }): ReactElement => {
  const colorSymbolI = color !== 'yellow' ? 'white' : 'black';
  return (
    <Box position="relative" display="flex" alignItems="center" {...props}>
      <SvgIcon htmlColor={color} fontSize="small" sx={InfoIconStyle}>
        {/* прототип иконки InfoIcon */}
        <path d="M0 0h24v24H0z" fill="none" />
        {/* магический круг который встает позади и дает цвет символу внутри */}
        <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="8" fill={colorSymbolI} />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
      </SvgIcon>
    </Box>
  );
};

/**
 * Иконка со знаком stop (-)
 * @param color
 */
export const StopCircleIcon: FC<{ color: ColorForIcon }> = ({ color }): ReactElement => {
  const colorSymbolI = color !== 'yellow' ? 'white' : 'black';
  return (
    <Box position="relative">
      <SvgIcon htmlColor={color} fontSize="small" sx={InfoIconStyle}>
        {/* прототип иконки RemoveCircleIcon */}
        <path d="M0 0h24v24H0z" fill="none" />
        {/* магический круг который встает позади и дает цвет символу внутри */}
        <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="8" fill={colorSymbolI} />{' '}
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
      </SvgIcon>
    </Box>
  );
};
const style = { minHeight: '36px', alignItems: 'center' };
export const LoadingSkeleton = (): ReactElement => {
  return (
    <CustomCard md={3} xs={12} style={{ paddingTop: '8px', borderRadius: '15px 0px 0px 15px ' }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Skeleton variant={'text'} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={0} sx={{ marginTop: '12px' }}>
            <Grid item xs={9}>
              <Grid container spacing={0}>
                <Grid item xs={5}>
                  <Skeleton variant={'text'} />
                </Grid>
                <Grid item xs={7} sx={{ paddingRight: '6px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>

                <Grid item xs={5} sx={{ paddingTop: '6px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>
                <Grid item xs={7} sx={{ paddingRight: '8px', paddingTop: '6px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>

                <Grid item xs={5} sx={{ paddingTop: '10px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>
                <Grid item xs={7} sx={{ paddingRight: '4px', paddingTop: '6px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>

                <Grid item xs={5} sx={{ paddingTop: '8px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>
                <Grid item xs={7} sx={{ paddingRight: '4px', paddingTop: '6px' }}>
                  <Skeleton variant={'text'} width={'50%'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="rectangular" width={'100%'} height={65} />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant={'text'} />
              <Skeleton variant={'text'} />
              <Skeleton variant="rectangular" width={'100%'} height={26} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

interface DealPositionPricesProps {
  dealStore: DealItemStore;
  isViewOnly: boolean; // только для чтения
  position?: AppDealPosition; // не передается для скелетона
}
export interface FindQuantityFromList {
  saleQuantity: number;
  baseQuantity: number;
  colorForStock: AllowedColors;
  titleForStock: string;
  inStock: number;
  inStockForSale?: number;
}
export const findQuantityFromList = (positionList: AppDealPosition, currentReserve?: number): FindQuantityFromList => {
  const amount = toFloat(positionList?.amount);
  const baseQuantity = toFloat(positionList?.baseQuantity);
  let inStock = toFloat(positionList?.product?.inStock);
  let inStockForSale = toFloat(positionList?.product?.inStockForSale);

  let unitShort = positionList?.baseUnit || 'т';
  let unitCode = positionList?.baseUnitCode || 'TNE';

  if (amount && baseQuantity > 0 && amount !== baseQuantity) {
    inStock *= amount / baseQuantity;
    inStockForSale *= amount / baseQuantity;
    unitShort = positionList?.unit || unitShort;
    unitCode = positionList?.unitCode || unitCode;
  }
  let colorForStock: AllowedColors = 'green';
  if (amount - (currentReserve || 0) > inStock) {
    colorForStock = 'red';
  } else if (amount - (currentReserve || 0) > inStockForSale) {
    colorForStock = 'yellow';
  }

  const titleForStock = `есть ${formatNumberByUnit(inStockForSale, unitCode, unitShort)} из ${formatNumberByUnit(
    inStock,
    unitCode,
    unitShort
  )}`;

  return {
    saleQuantity: amount,
    baseQuantity,
    colorForStock,
    titleForStock,
    inStock,
  };
};
export const DealPositionPrices: FC<DealPositionPricesProps> = observer(({ dealStore, isViewOnly, position }): ReactElement => {
  // управление tooltip
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipName, setTooltipName] = useState('');
  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string): void => {
    event.stopPropagation();
    setAnchorEl(event.target);
    setTooltipName(type);
  };
  const handleTooltipClose = (): void => {
    setAnchorEl(null);
    setTooltipName('');
  };

  const bareUnitCostStore = dealStore.getValueStoreByPos(position, 'bareUnitCost', ValueStoreInputTypes.String);
  const amountStore = dealStore.getValueStoreByPos(position, 'amount', ValueStoreInputTypes.String);
  const tenderUnitCostStore = dealStore.getValueStoreByPos(position, 'tenderUnitCost', ValueStoreInputTypes.String);

  const profile = useAuthStore().profile;

  const handlePositionUnitChange = useCallback(
    (event): void => {
      const value = event.target.value;
      dealStore.updatePosition(position, { unitCode: value }).catch(r => console.warn('handlePositionUnitChange', r));
    },
    [dealStore, position]
  );

  const { saleQuantity, colorForStock, titleForStock, inStock } = findQuantityFromList(position);

  const handlePriceListPriceClick = useCallback(() => {
    if (tooltipName === 'toChange') {
      const newPrice = Math.round(position.recommendedUnitCost * 100 || 0) / 100;
      const newPriceStr = newPrice.toString();
      if (position.unitCostType === DealPositionUnitCostTypeEnum.Tender) {
        tenderUnitCostStore.handleInputChange(newPriceStr);
      } else {
        bareUnitCostStore.handleInputChange(newPriceStr);
      }
      handleTooltipClose();
    }
  }, [position, tooltipName]);

  /*
   Disable - для input изменения цены, срабатывает при смене типа цены (с услугами или без)
   */
  const isPriceInputDisabled = dealStore.updatesQueue.some(upd => {
    return Object.keys(upd.updates?.byCode || {}).some(() => upd.updates?.byCode?.[position.code]?.unitCostType);
  });

  // Установка тендерной цены как основной
  const handleUseTenderCostClick = useCallback((): void => {
    const changes = { unitCostType: DealPositionUnitCostTypeEnum.Tender } as AppDealPositionUpdates;
    if (position.unitCostType === DealPositionUnitCostTypeEnum.Bare && position.fullUnitCost) {
      changes.tenderUnitCost = position.fullUnitCost;
    }
    dealStore.updatePosition(position, changes).catch(r => console.warn('handleUseTenderCostClick', r));

    handleTooltipClose();
  }, [dealStore, position]);

  // Установка голой цены как основной
  const handleUseBareCostClick = useCallback((): void => {
    const changes = { unitCostType: DealPositionUnitCostTypeEnum.Bare } as AppDealPositionUpdates;
    if (position.unitCostType === DealPositionUnitCostTypeEnum.Tender && position.calculatedBareUnitCost) {
      changes.bareUnitCost = position.calculatedBareUnitCost;
    }
    dealStore.updatePosition(position, changes).catch(r => console.warn('handleUseBareCostClick', r));

    handleTooltipClose();
  }, [dealStore, position]);

  // Цена меньше МРЦ с полномочиями
  let mrcWithPowers = parseFloat(position?.minRetailUnitCostWithPowers);
  const mrcGroupCode = dealStore.deal?.approval?.currentAccessGroup || AppUserGroupCodes.SELLER;
  const mrcGroup = position?.mrcUserGroups.find(g => g.groupCode === mrcGroupCode);
  if (mrcGroup) {
    mrcWithPowers = mrcGroup.mrcUnitCost;
  }

  const hideCostAndAmount = useMemo(() => !!position.product?.groups?.includes('deal_fixed'), [position.product?.groups]);

  const incorrectCost: boolean = (position?.calculatedBareUnitCost || 0) < mrcWithPowers;
  const unit = position?.unit || 'т';
  const currency = position?.currency || '₽';

  const posInAgreement = !!position.agreementPositionCode;
  let agreementPosition: ContractCostPosition;
  if (posInAgreement) {
    const [agreementPositionVal] = dealStore.findAgreementByPositionCode(position.agreementPositionCode);
    agreementPosition = agreementPositionVal;
  }

  const propsStockLinearProgress = useMemo(() => {
    let reservationValue = 0;
    if (Number(position.amount) > 0 && !dealStore.isApproved()) {
      reservationValue = Number(position.amount);
    }
    if (agreementPosition?.isStocksControlDisabled) {
      return getQuantityPercentAndStock(agreementPosition?.quantity, 0);
    }
    return getQuantityPercentAndStock(agreementPosition?.quantity, reservationValue + (agreementPosition?.reservation?.usedQuantity ?? 0));
  }, [
    agreementPosition?.quantity,
    agreementPosition?.isStocksControlDisabled,
    agreementPosition?.reservation?.usedQuantity,
    position.amount,
  ]);
  const isBareCostInput = position?.unitCostType !== DealPositionUnitCostTypeEnum.Tender && !posInAgreement;
  const isTenderCostInput = !isBareCostInput && !posInAgreement;

  const currentMRCValue = useMemo(() => {
    const values = position?.mrcUserGroups || [];
    const value = values.find(i => i.groupCode === dealStore.deal.approval.currentAccessGroup);
    if (!!value) {
      return value;
    } else {
      return values.filter(i => i.groupCode === AppUserGroupCodes.SELLER)[0];
    }
  }, [position, dealStore, dealStore.deal?.approval]);

  const colorForIconMRC = getColorForIconByMRC(position?.mrcUserGroups, currentMRCValue);
  const mrcUserGroupsSortGroupNames = ['seller', 'seller_chief', 'office_chief', 'division_chief'];
  const mrcUserGroups = position?.mrcUserGroups
    ? position.mrcUserGroups
        .slice()
        .sort((a, b) => mrcUserGroupsSortGroupNames.indexOf(a.groupCode) - mrcUserGroupsSortGroupNames.indexOf(b.groupCode))
    : [];

  // Флаг "с учетом наценки филиала"
  const groupsSegmentHint = useMemo((): Map<string, number> => {
    const res = new Map<string, number>();

    if (!position.sgMrcMarkupAmount || position.sgMrcMarkupAmount <= 0) {
      return res;
    }

    mrcUserGroups.map((group: DealPositionMrcUserGroup): void => {
      if (group.mrcUnitCost > 0 && group.groupCode && group.sgMarkupAmount > 0) {
        res.set(group.groupCode, group.sgMarkupAmount);
      }
    });

    return res;
  }, [position, position.sgMrcMarkupAmount, dealStore]);

  const showTooltipToChange = !isViewOnly && !posInAgreement && !hideCostAndAmount;
  // const isManufacturerMarkupExceeded =
  //   position.manufacturerMarkupLimitUnitCost > 0 && position.manufacturerMarkupLimitUnitCost < (position?.fullUnitCost || 0);

  const showTooltipErrorPriceList = useMemo(
    () => position.calculatedBareUnitCost < position.recommendedUnitCost,
    [position.calculatedBareUnitCost, position.recommendedUnitCost]
  );

  const [anchorConverterEl, setConverterEl] = useState(null);
  const handleAddCalculateClick = useCallback(
    event => {
      if (!anchorConverterEl) {
        setConverterEl(event.currentTarget);
      } else {
        setConverterEl(null);
      }
    },
    [anchorConverterEl]
  );
  const openConvertPopover = Boolean(anchorConverterEl);
  const handleConvertClose = useCallback((): void => {
    setConverterEl(null);
  }, [setConverterEl]);

  const [initialTonneVal, setInitialTonneVal] = useState('');
  useEffect(() => {
    if (position.amount !== '') {
      if (position?.unitCode === 'TNE') {
        setInitialTonneVal(position.amount);
      } else {
        const currentUnit = position?.units.filter(unit => unit.code === position?.unitCode)[0];
        setInitialTonneVal(`${+position.amount * currentUnit?.weight}`);
      }
    }
  }, [position.amount, position?.unitCode]);

  const pricesSpaces = useMemo(() => {
    return position.product.prices.slice(1);
  }, [position]);

  const handleSaveAmountFromConverter = useCallback(
    (amount: string, unitCode: string): void => {
      dealStore
        .updatePosition(position, { unitCode, amount: inputValueToFloatPositiveString(amount) })

        .catch(r => {
          console.warn('handlePositionUnitChange', r);
        });
      setConverterEl(null);
    },
    [dealStore, position]
  );
  const isLeadPosition = position.product.typeCode === 'lead';
  const isClosed = dealStore.isPositionClosed(position);

  if (!dealStore.isLoaded) {
    return <LoadingSkeleton />;
  }

  return (
    <Grid container item xs={12} sx={{ alignContent: 'flex-start', height: '100%' }}>
      <Grid container item xs={12} sx={style}>
        <Grid container item xs={6}>
          <Grid item xs={7}>
            <Typography color="text.secondary" variant="caption" fontWeight={500}>
              Количество
            </Typography>
          </Grid>
          <Grid item container xs={5}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center">
                <InfoIconDeal
                  color={colorForStock}
                  onMouseEnter={event => handleTooltipOpen(event, 'amount')}
                  onMouseLeave={() => handleTooltipClose()}
                />
              </Box>
              <Popper placement="top" open={tooltipName === 'amount'} anchorEl={anchorEl}>
                <Paper
                  sx={{
                    padding: 0.5,
                    ...multicoloredPopoverBg(colorForStock),
                  }}
                >
                  <Box p={0.6}>
                    <Typography variant="caption" color="inherit" fontWeight="500">
                      {titleForStock}
                    </Typography>
                  </Box>
                </Paper>
              </Popper>
            </Grid>
            <Grid item xs={6}>
              {posInAgreement && (
                <Grid container>
                  <Grid item onMouseEnter={event => handleTooltipOpen(event, 'balance')} onMouseLeave={() => handleTooltipClose()}>
                    <Box display="flex" justifyContent="center">
                      <HandShakeIcon
                        htmlColor={ColorsStockLinearProgress[propsStockLinearProgress?.color || ColorsStockLinearProgress.red]}
                      />
                    </Box>
                    <Popper placement="top" open={tooltipName === 'balance'} anchorEl={anchorEl}>
                      <Paper>
                        <Box p={0.6}>
                          <Typography variant="body2" color="textPrimary" component="div">
                            Остаток
                          </Typography>
                          <Box display="flex" width={150}>
                            <Box width="100%" alignSelf={'center'}>
                              <StockLinearProgress {...propsStockLinearProgress} unit={position.baseUnit} />
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Popper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item container xs={12} flexWrap={'nowrap'}>
            <LightTooltip
              placement="right"
              title={
                position.changesLog?.oldQuantity ? (
                  <Box p={0.6}>
                    <Typography variant="subtitle2" component="span" fontWeight={500}>
                      Старое значение: {position.changesLog?.oldQuantity}&nbsp;{unit}
                    </Typography>
                  </Box>
                ) : undefined
              }
              arrow
            >
              <Box display={'flex'} alignSelf="center">
                <Box display={'flex'} alignSelf="center" maxWidth={100}>
                  {!hideCostAndAmount ? (
                    <SyncedTextField
                      color="text.secondary"
                      value={amountStore}
                      sx={position.changesLog?.quantity ? { '& .MuiInputBase-root': { backgroundColor: ColorChangedPropertyDeal } } : {}}
                      sxTypographyProps={position.changesLog?.quantity ? { backgroundColor: ColorChangedPropertyDeal } : {}}
                      incorrectValue={saleQuantity > inStock}
                      isViewOnly={isViewOnly}
                    />
                  ) : (
                    <Typography color="text.secondary" variant="caption" component="span" fontWeight={500}>
                      {position.amount}
                    </Typography>
                  )}
                </Box>
                <Box ml={'4px'}>
                  <FormControl size="small" sx={{ height: '100%' }}>
                    <Select
                      disabled={isViewOnly || position.isService}
                      sx={{
                        height: '100%',
                        lineHeight: '20px',
                        // '.MuiOutlinedInput-notchedOutline': {
                        //   border: '1px solid #ccc!important',
                        // },
                        '.MuiSelect-select': {
                          paddingRight: '28px!important',
                          padding: '2px 6px 2px 6px',
                          // padding: '2.5px 8px',
                          fontSize: '14px',
                          // ...(isViewOnly || position.isService ? { background: '#f8f8f8' } : {}),
                        },

                        '.MuiSelect-icon': { fontSize: '18px' },
                      }}
                      value={position?.unitCode}
                      // MenuProps={{ disableScrollLock: true, variant: 'menu', disablePortal: true }}
                      label=""
                      onChange={handlePositionUnitChange}
                    >
                      {(position?.units || []).map(u => (
                        <MenuItem value={u.code} key={u.code}>
                          {u.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </LightTooltip>
            {position.units?.filter(unit => unit.code !== 'TNE').length > 0 && !isLeadPosition && (
              <Box ml={'4px'}>
                <IconButton
                  title="Калькулятор/конвертор"
                  color="inherit"
                  size="small"
                  aria-label="unit converter"
                  onClick={handleAddCalculateClick}
                  // sx={{ padding: '8px' }}
                >
                  <CalculateIcon fontSize="medium" sx={{ color: 'secondary.light' }} />
                </IconButton>
                <Popper open={openConvertPopover} style={{ zIndex: 1300 }} anchorEl={anchorConverterEl} placement={'bottom'} transition>
                  {({ TransitionProps }) => (
                    <>
                      <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={handleConvertClose}>
                        <Grow {...TransitionProps}>
                          <Paper elevation={8} sx={{ display: 'flex' }}>
                            <FormControl
                              variant="standard"
                              sx={{ width: '30ch', display: 'flex', flexWrap: 'wrap', margin: theme => theme.spacing(1) }}
                            >
                              <Grid container flexDirection="column">
                                {position.units.map((unit, index) => (
                                  <ProductConverter
                                    key={index}
                                    unit={unit}
                                    initialTonneVal={initialTonneVal}
                                    pricesSpaces={pricesSpaces}
                                    isForSave={!isViewOnly}
                                    handleSaveAmountFromConverter={handleSaveAmountFromConverter}
                                  />
                                ))}
                              </Grid>
                            </FormControl>
                          </Paper>
                        </Grow>
                      </ClickAwayListener>
                    </>
                  )}
                </Popper>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={style}>
        <Grid item xs={6} alignSelf={'center'}>
          <Grid container>
            <Grid item xs={7}>
              <Typography color="text.secondary" variant="caption" fontWeight={500}>
                Цена товара
              </Typography>
            </Grid>
            <Grid item container xs={5}>
              <Grid item container xs={6} sx={{ justifyContent: 'center' }}>
                {currentMRCValue ? (
                  <Grid item container xs={6} sx={{ justifyContent: 'center' }}>
                    <Grid item>
                      <Box display="flex" justifyContent="center">
                        <InfoIconDeal
                          color={colorForIconMRC}
                          onMouseEnter={event => handleTooltipOpen(event, 'currentMrc')}
                          onMouseLeave={() => handleTooltipClose()}
                        />
                      </Box>
                      <Popper placement="top" open={tooltipName === 'currentMrc'} anchorEl={anchorEl}>
                        <Paper sx={{ p: 0.6 }}>
                          <Box>
                            {mrcUserGroups.map(group => (
                              <Box
                                display={'flex'}
                                justifyContent="space-between"
                                p={'2px'}
                                mb={0.4}
                                borderRadius="3px"
                                color={group.isMRCFine ? undefined : 'red'}
                                key={group.groupCode}
                              >
                                <Typography
                                  variant="caption"
                                  fontWeight={currentMRCValue.groupCode === group.groupCode ? 500 : 400}
                                  color="inherit"
                                >
                                  {group.groupShort}
                                </Typography>
                                &nbsp;
                                <Typography
                                  variant="caption"
                                  marginLeft="1"
                                  fontWeight={currentMRCValue.groupCode === group.groupCode ? 500 : 400}
                                  color="inherit"
                                >
                                  {formatPriceCur(group.mrcUnitCost, `${currency}/${unit}`)}
                                  {groupsSegmentHint.has(group.groupCode) && <>*</>}
                                </Typography>
                              </Box>
                            ))}
                            {groupsSegmentHint.size > 0 && (
                              <Box>
                                <Typography variant="caption" color="inherit">
                                  * с учетом наценки филиала {renderPriceCur(groupsSegmentHint.values().next().value)}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          {(dealStore.deal.customer.segmentCode || dealStore.deal.customer.occupationTitle) && (
                            <>
                              <Divider />
                              <Grid container>
                                {dealStore.deal.customer.segmentCode && (
                                  <>
                                    <Grid item>
                                      <Typography variant="caption" color="inherit">
                                        Сегмент
                                      </Typography>
                                    </Grid>
                                    <Grid item ml={1}>
                                      <Typography variant="caption" color="inherit">
                                        {dealStore.deal.customer.segmentCode}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                                {dealStore.deal.customer.occupationTitle && (
                                  <Grid item xs={12}>
                                    <Typography variant="caption" color="inherit">
                                      {dealStore.deal.customer.occupationTitle}
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          )}
                        </Paper>
                      </Popper>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    onMouseEnter={event => handleTooltipOpen(event, 'withoutCurrentMrc')}
                    onMouseLeave={() => handleTooltipClose()}
                  >
                    <Box display="flex" justifyContent="center">
                      <StopCircleIcon color="red" />
                    </Box>
                    <Popper placement="top" open={tooltipName === 'withoutCurrentMrc'} anchorEl={anchorEl}>
                      <Paper>
                        <Box p={0.6}>
                          <Typography variant="caption" color="inherit">
                            Не настроены полномочия МРЦ
                          </Typography>
                        </Box>
                      </Paper>
                    </Popper>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6}>
                {posInAgreement && <HandShakeIcon titleAccess="Позиция находится в соглашении" />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          {isBareCostInput ? (
            <>
              {!hideCostAndAmount ? (
                <LightTooltip
                  placement="right"
                  title={
                    position.changesLog?.oldUnitCost ? (
                      <Box p={0.6}>
                        <Typography variant="subtitle2" component="span" fontWeight={500}>
                          Старое значение: {position.changesLog?.oldUnitCost}&nbsp;{currency}/{unit}
                        </Typography>
                      </Box>
                    ) : undefined
                  }
                  arrow
                >
                  <Box maxWidth={100} alignSelf="center">
                    <SyncedTextField
                      value={bareUnitCostStore}
                      incorrectValue={incorrectCost}
                      sxTypographyProps={position.changesLog?.unitCost ? { backgroundColor: ColorChangedPropertyDeal } : {}}
                      sx={position.changesLog?.unitCost ? { '& .MuiInputBase-root': { backgroundColor: ColorChangedPropertyDeal } } : {}}
                      isViewOnly={isViewOnly || isPriceInputDisabled || posInAgreement}
                    />
                  </Box>
                </LightTooltip>
              ) : (
                <Typography color="text.secondary" alignSelf="center" variant="caption" component="span" fontWeight={500}>
                  {position.bareUnitCost}
                </Typography>
              )}
              <Typography
                variant="caption"
                alignSelf="center"
                fontWeight={500}
                component="span"
                color={incorrectCost ? '#C74952' : 'text.secondary'}
              >
                &nbsp;{currency}/{unit}
              </Typography>
            </>
          ) : (
            <Grid container>
              <Grid item onMouseEnter={event => handleTooltipOpen(event, 'setPrice')} onMouseLeave={() => handleTooltipClose()}>
                <LightTooltip
                  placement="right"
                  title={
                    position.changesLog?.oldUnitCost ? (
                      <Box p={0.6}>
                        <Typography variant="subtitle2" component="span" fontWeight={500}>
                          Старое значение: {position.changesLog?.oldUnitCost}&nbsp;{currency}/{unit}
                        </Typography>
                      </Box>
                    ) : undefined
                  }
                  arrow
                >
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    fontWeight={500}
                    component="span"
                    sx={position.changesLog?.unitCost ? { backgroundColor: ColorChangedPropertyDeal } : {}}
                  >
                    {formatPriceCur(position?.calculatedBareUnitCost || 0, '')}
                    <span>{`\u00A0${currency}/${unit}`}</span>
                  </Typography>
                </LightTooltip>
                <Popper placement="top" open={tooltipName === 'setPrice'} anchorEl={anchorEl}>
                  <Paper>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleUseBareCostClick} p={1}>
                      <Typography sx={{ cursor: 'pointer' }} variant="subtitle2" fontWeight={500} component="span">
                        Задать цену товара
                        <br />
                        без учета услуг
                      </Typography>
                    </Box>
                  </Paper>
                </Popper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={style}>
        <Grid item container xs={6}>
          <Grid item xs={7}>
            <Typography color="text.secondary" variant="caption" fontWeight={500} whiteSpace={'nowrap'}>
              Цена с услугами
            </Typography>
          </Grid>
          <Grid item container xs={5}>
            <Grid item container xs={6} sx={{ justifyContent: 'center' }}>
              <Grid item>
                <Box display="flex" justifyContent="center">
                  <InfoIconDeal
                    color={'gray'}
                    onMouseEnter={event => handleTooltipOpen(event, 'vatRate')}
                    onMouseLeave={() => handleTooltipClose()}
                  />
                </Box>
                <Popper placement="top" open={tooltipName === 'vatRate'} anchorEl={anchorEl}>
                  <Paper>
                    <Box p={0.6}>
                      <Box display={'flex'} justifyContent="space-between" p={'2px'} mb={0.4} borderRadius="3px">
                        <Typography variant="caption" fontWeight={400} color="inherit">
                          Цена без НДС
                        </Typography>
                        <Box px={1}></Box>
                        <Typography variant="caption" marginLeft="2" fontWeight={400} color="inherit">
                          {formatPriceCur(position.fullUnitCostWoVat, `${currency}/${unit}`)}
                        </Typography>
                      </Box>
                      <Box display={'flex'} justifyContent="space-between" p={'2px'} mb={0.4} borderRadius="3px">
                        <Typography variant="caption" fontWeight={400} color="inherit">
                          НДС, %
                        </Typography>
                        <Box px={1}></Box>

                        <Typography variant="caption" marginLeft={2} fontWeight={400} color="inherit">
                          {position.vatRateValue ?? 0}&nbsp;%
                          {/* {formatPriceCur(group.mrcUnitCost, `${currency}/${unit}`)} */}
                        </Typography>
                      </Box>
                      <Box display={'flex'} justifyContent="space-between" p={'2px'} mb={0.4} borderRadius="3px">
                        <Typography variant="caption" fontWeight={400} color="inherit">
                          НДС
                        </Typography>
                        <Box px={1}></Box>

                        <Typography variant="caption" marginLeft={2} fontWeight={400} color="inherit">
                          {formatPriceCur(position.vatUnitCost, `${currency}/${unit}`)}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Popper>
              </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          {isTenderCostInput ? (
            <>
              <Box maxWidth={100} alignSelf="center">
                <SyncedTextField
                  value={tenderUnitCostStore}
                  color="#19B28D"
                  incorrectValue={incorrectCost}
                  isViewOnly={isViewOnly || isPriceInputDisabled || posInAgreement || hideCostAndAmount}
                />
              </Box>

              <Typography
                color="text.secondary"
                variant="caption"
                fontWeight={500}
                alignSelf="center"
                component="span"
                sx={{ color: incorrectCost ? '#C74952' : 'text.secondary' }}
              >
                &nbsp;{currency}/{unit}
              </Typography>
            </>
          ) : showTooltipToChange ? (
            <Grid container>
              <Grid item onMouseEnter={event => handleTooltipOpen(event, 'setPrice')} onMouseLeave={() => handleTooltipClose()}>
                <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                  {formatPriceCur(position?.fullUnitCost || 0, `${currency}/${unit}`)}
                </Typography>
                <Popper placement="bottom" open={tooltipName === 'setPrice'} anchorEl={anchorEl}>
                  <Paper>
                    {profile?.features?.canSetTenderCost ? (
                      <Box sx={{ cursor: 'pointer' }} onClick={handleUseTenderCostClick} p={1}>
                        <Typography sx={{ cursor: 'pointer' }} variant="subtitle2" fontWeight={500} component="span">
                          Задать цену с&nbsp;услугами
                          <br />
                          вместо цены товара
                        </Typography>
                      </Box>
                    ) : (
                      <Box p={1}>
                        <Typography variant="subtitle2" fontWeight={500} component="span">
                          Недостаточно прав
                          <br />
                          для задания цены с&nbsp;услугами
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Popper>
              </Grid>
            </Grid>
          ) : (
            <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
              {formatPriceCur(position?.fullUnitCost || 0, `${currency}/${unit}`)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Collapse
        in={!isClosed}
        timeout={{
          enter: 350,
          exit: 350,
        }}
        sx={{
          width: '100%',
          '.MuiCollapse-wrapperInner': { display: 'flex', flexWrap: 'wrap' },
        }}
        unmountOnExit
      >
        <Grid container flexDirection={'column'} height={'100%'} flexWrap={'nowrap'}>
          <Grid item>
            <Grid container>
              <Grid container item xs={12} sx={style}>
                <Grid item container xs={6}>
                  <Grid item xs={7}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      Прайслист
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {showTooltipErrorPriceList && (
                      <Grid item container xs={6} sx={{ justifyContent: 'center' }}>
                        <Grid item>
                          <Box display="flex" justifyContent="center">
                            <InfoIconDeal
                              color={'red'}
                              onMouseEnter={event => handleTooltipOpen(event, 'priceList')}
                              onMouseLeave={() => handleTooltipClose()}
                            />
                          </Box>
                          <Popper placement="top" open={tooltipName === 'priceList'} anchorEl={anchorEl}>
                            <Paper>
                              <Box p={0.6}>
                                <Typography variant="caption" color="inherit">
                                  Цена товара меньше цены прайслиста
                                </Typography>
                              </Box>
                            </Paper>
                          </Popper>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid item container xs={6}>
                    {/* <Grid item xs={6} sx={{ paddingRight: '8px', paddingTop: '6px', position: 'relative' }}> */}
                    {showTooltipToChange ? (
                      <Grid item onMouseEnter={event => handleTooltipOpen(event, 'toChange')} onMouseLeave={() => handleTooltipClose()}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                          sx={{
                            color: theme =>
                              Number(position.recommendedUnitCost || 0)
                                ? theme.palette.mode === 'dark'
                                  ? blue[500]
                                  : blue[700]
                                : '#C74952',
                          }}
                        >
                          {renderPriceCur(position.recommendedUnitCost, `${currency}/${unit}`)}
                        </Typography>
                        <Popper placement="top" open={tooltipName === 'toChange'} anchorEl={anchorEl}>
                          <Paper>
                            <Box sx={{ cursor: 'pointer' }} onClick={handlePriceListPriceClick} p={1}>
                              <Typography sx={{ cursor: 'pointer' }} variant="subtitle2" fontWeight={500} component="span">
                                Использовать
                              </Typography>
                            </Box>
                          </Paper>
                        </Popper>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                          sx={{
                            color: Number(position.recommendedUnitCost || 0)
                              ? theme => (theme.palette.mode === 'dark' ? blue[500] : blue[700])
                              : '#C74952',
                          }}
                        >
                          {renderPriceCur(position.recommendedUnitCost, `${currency}/${unit}`)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} sx={style}>
                <Grid item container xs={6}>
                  <Grid item xs={7}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      МРЦ
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={Number(position.minRetailUnitCost || 0) ? 'text.secondary' : '#C74952'}
                    variant="caption"
                    fontWeight={500}
                    component="span"
                  >
                    {renderPriceCur(position.minRetailUnitCost, `${currency}/${unit}`)}
                  </Typography>
                </Grid>
              </Grid>

              {position.competitorAvgUnitCost > 0 && (
                <Grid container item xs={12} sx={style}>
                  <Grid container item xs={6}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      Цена рынка
                    </Typography>
                  </Grid>
                  <Grid container item xs={6}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                      {renderPriceCur(position.competitorAvgUnitCost, `${currency}/${unit}`)}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {position.custodyAvgUnitCost > 0 && (
                <Grid container item xs={12} sx={style}>
                  <Grid container item xs={6}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      Цена ОХ
                    </Typography>
                  </Grid>
                  <Grid container item xs={6}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                      {renderPriceCur(position.custodyAvgUnitCost, `${currency}/${unit}`)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
});
