import { DealConsigneeListStore } from '../../../store/DealConsigneeListStore';
import { FC, ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import { normalizeCompanyTitle } from '../../clients/lib';
import { Consignee } from 'src/api/marketx';
import { CustomSelectNew, ICustomSelect } from '../components/CustomSelectNew';

interface DealConsigneeSelectProps {
  isViewOnly?: boolean;
  currentCode?: string;

  consignees: DealConsigneeListStore;
  onChange: (consigneeCode: string | null) => void;
}

export const DealConsigneeSelect: FC<DealConsigneeSelectProps> = observer(
  ({ isViewOnly, consignees, currentCode, onChange }): ReactElement => {
    const values = useMemo(() => {
      let values: ICustomSelect[] = consignees.items?.map((c: Consignee) => {
        return {
          value: c.code,
          text: `${normalizeCompanyTitle(c.title)}${c.mailingAddress ? ` (${c.mailingAddress})` : ''}`,
          disabled: !!c.isDeleted,
        };
      });
      if (!values || !values.length) {
        values = [{ text: '\u00A0', value: '' }];
      }
      return values;
    }, [consignees, consignees.loadedEpoch]);
    return (
      <CustomSelectNew label={'Грузополучатель'} options={values} isViewOnly={isViewOnly} defaultValue={currentCode} onChange={onChange} />
    );
  }
);
