import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import TextField from '@mui/material/TextField';

interface TextFieldInfoProps {
  value?: string;
  label?: string;
  fullWidth?: boolean;
}

export const TextFieldInfo: FC<TextFieldInfoProps> = observer(
  ({ value = '', label = '', fullWidth = false }): JSX.Element => (
    <TextField
      label={label}
      size={'small'}
      disabled={true}
      value={value}
      fullWidth={fullWidth}
      inputProps={{
        style: { padding: '4px 14px', fontSize: '14px' },
      }}
      sx={{
        '.MuiFormLabel-root[data-shrink="false"]': {
          fontSize: '14px',
          top: '-4px',
        },
      }}
    />
  )
);
