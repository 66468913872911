import { AppAddressUpdates } from '../../../slices/AppDeal';
import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { AddressGeocodingDecodeResponse, AddressGeocodingSearchResponse, AddressSearchVariant } from '../../../api/marketx';
import { AxiosResponse } from 'axios';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { AnyObject } from '@pbe/react-yandex-maps/typings/util/typing';

interface AddressEditingOnChangeCallback {
  (updates: AppAddressUpdates): void;
}

type AddressEditingProps = {
  isViewOnly?: boolean;
  onChange: AddressEditingOnChangeCallback;
  // начальная позиция на карте
  startLatitude?: number;
  startLongitude?: number;
};

type Coordinates = {
  latitude: number;
  longitude: number;
};

type AddressEditingInnerState = {
  initialized: boolean;
  // считаем запущенные поиск, ответы по итерациям младше текущей игнорируются.
  searchIteration: number;
  // точка, куда нужно поставить центр карты (после автоперемещения метки)
  nextCenterLatitude: number;
  nextCenterLongitude: number;
};

export const AddressEditing = ({ isViewOnly, onChange, startLatitude, startLongitude }: AddressEditingProps): JSX.Element => {
  const [updates] = useState({
    latitude: startLatitude || 55.75,
    longitude: startLongitude || 37.57,
  } as AppAddressUpdates);
  const [searchString, setSearchString] = useState('');
  const [innerState] = useState({} as AddressEditingInnerState);
  const [street, setStreet] = useState('');
  const [cityName, setCityName] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [flatNumber, setFlatNumber] = useState('');
  const [placemarkCoordinates, setPlacemarkCoordinates] = useState({
    latitude: updates.latitude,
    longitude: updates.longitude,
  } as Coordinates);
  const apiStore = useRootStore().getApiStore();

  const handleSearchStringChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    setSearchString(e.target.value);
  };

  const applySearchResult = (addresses?: AddressSearchVariant[]): void => {
    if (!(addresses?.length > 0)) {
      console.warn('empty geo search result');
      return;
    }
    const addr = addresses[0];
    innerState.nextCenterLatitude = addr?.latitude || placemarkCoordinates.latitude;
    innerState.nextCenterLongitude = addr?.longitude || placemarkCoordinates.longitude;
    setCityName(addr?.cityName || '');
    setStreet(addr?.street || '');
    setHouseNumber(addr?.houseNumber || '');
    setPlacemarkCoordinates({
      latitude: addr?.latitude || placemarkCoordinates.latitude,
      longitude: addr?.longitude || placemarkCoordinates.longitude,
    } as Coordinates);

    updates.cityName = addr.cityName;
    updates.street = addr?.street || '';
    updates.houseNumber = addr.houseNumber || '';
    updates.postcode = addr.postcode || '';
    updates.latitude = addr?.latitude || placemarkCoordinates.latitude;
    updates.longitude = addr?.longitude || placemarkCoordinates.longitude;

    onChange(updates);
  };

  const handleSearchStringKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key !== 'Enter') {
      return;
    }
    console.log('search string enter', searchString);
    if (!searchString) {
      return;
    }
    const currentSearchIteration = (innerState?.searchIteration || 0) + 1;
    innerState.searchIteration = currentSearchIteration;
    apiStore
      .apiClientAddress()
      .addressGeocodingSearch(searchString)
      .then((res: AxiosResponse<AddressGeocodingSearchResponse>): void => {
        if (innerState.searchIteration !== currentSearchIteration) {
          console.log('not actual si', innerState.searchIteration, currentSearchIteration);
          return;
        }
        applySearchResult(res?.data?.addresses);
      });
  };

  const handleStreetChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const value = e.target.value;
    updates.street = value;
    setStreet(value);
    onChange(updates);
  };

  const handleHouseNumberChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const value = e.target.value;
    updates.houseNumber = value;
    setHouseNumber(value);
    onChange(updates);
  };

  const handleChangeFlat: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const value = e.target.value;
    updates.flatNumber = value;
    setFlatNumber(value);
    onChange(updates);
  };

  const handlePlacemarkDragend = (e: AnyObject): void => {
    const coords = e?.get('target')?.geometry?.getCoordinates();
    const currentSearchIteration = (innerState?.searchIteration || 0) + 1;
    innerState.searchIteration = currentSearchIteration;
    const coordsStr = coords[0] + ',' + coords[1];
    apiStore
      .apiClientAddress()
      .addressGeocodingDecode(coordsStr)
      .then((res: AxiosResponse<AddressGeocodingDecodeResponse>): void => {
        if (innerState.searchIteration !== currentSearchIteration) {
          console.log('not actual si', innerState.searchIteration, currentSearchIteration);
          return;
        }
        applySearchResult(res?.data?.addresses);
      });
  };

  const mapState = { zoom: 11, center: [] };
  if (innerState.nextCenterLatitude && innerState.nextCenterLongitude) {
    mapState.center = [innerState.nextCenterLatitude, innerState.nextCenterLongitude];
    innerState.nextCenterLatitude = 0;
    innerState.nextCenterLongitude = 0;
  } else {
    mapState.center = [placemarkCoordinates.latitude, placemarkCoordinates.longitude];
  }
  innerState.initialized = true;

  return (
    <>
      <Grid container style={{ width: '100%' }}>
        <Grid container style={{ width: '100%' }}>
          <TextField
            value={searchString}
            onChange={handleSearchStringChange}
            onKeyDown={handleSearchStringKeyDown}
            disabled={isViewOnly}
            sx={{ width: 500 }}
            inputProps={{
              style: {
                padding: '4px 0 4px 4px',
                fontWeight: 700,
                fontSize: 14,
              },
            }}
          />
        </Grid>
        <Grid container style={{ width: '100%', marginTop: '10px', minHeight: '200px' }}>
          <YMaps>
            {/* <YMaps style={{ width: '100%', height: '200px' }}> */}
            <Map state={mapState} defaultState={mapState} width="100%" height="200px">
              <Placemark
                geometry={[placemarkCoordinates.latitude, placemarkCoordinates.longitude]}
                options={{ draggable: true }}
                onDragEnd={handlePlacemarkDragend}
              />
            </Map>
          </YMaps>
        </Grid>
        <Grid container style={{ width: '100%', marginTop: '10px' }}>
          <Grid item md={2} xs={12}>
            <Typography variant="body2" color="text.secondary">
              Город
            </Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <TextField
              value={cityName}
              sx={{ width: '100%' }}
              disabled
              inputProps={{
                style: {
                  padding: '2px 6px 1px 6px',
                  fontSize: 14,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ width: '100%', marginTop: '10px' }}>
          <Grid item md={2} xs={12}>
            <Typography variant="body2" color="text.secondary">
              Улица
            </Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <TextField
              value={street}
              onChange={handleStreetChange}
              sx={{ width: '100%' }}
              inputProps={{
                style: {
                  padding: '4px 0 4px 4px',
                  fontWeight: 700,
                  fontSize: 14,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ width: '100%', marginTop: '10px' }}>
          <Grid item md={2} xs={12}>
            <Typography variant="body2" color="text.secondary">
              Дом/здание
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              value={houseNumber}
              onChange={handleHouseNumberChange}
              sx={{ width: '100%' }}
              inputProps={{
                style: {
                  padding: '4px 0 4px 4px',
                  fontWeight: 700,
                  fontSize: 14,
                },
              }}
            />
          </Grid>

          <Grid item md={1} xs={12} />

          <Grid item md={3} xs={12}>
            <Typography variant="body2" color="text.secondary">
              Квартира/офис
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              value={flatNumber}
              onChange={handleChangeFlat}
              sx={{ width: '100%' }}
              inputProps={{
                style: {
                  padding: '4px 0 4px 4px',
                  fontWeight: 700,
                  fontSize: 14,
                },
              }}
            />
          </Grid>

          <Grid item md={2} xs={12}>
            &nbsp;
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
