import { NextRouter, useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { ApplicationErrorEvent } from '../store/ErrorStore';
import { observer } from 'mobx-react';
import { useRootStore } from '../store/MobxStoreProvider';
import { Landing } from 'src/components/Landing/Landing';
import { SessionContextValue, useSession } from 'next-auth/react';

const publicRoutes = {
  '/': true,
  '/app/tools/whmap': true,
};

const isProtectedRoute = (router: NextRouter): boolean => !publicRoutes[router.route];

interface AuthorizationGuardProps {
  children: React.ReactNode;
  err?: any;
}

// Управляет доступом к настоящим страницам или заглушке-лэндинг и выполняет редиректы после авторизации.
const AuthorizationGuard: FC<AuthorizationGuardProps> = observer(({ children }): JSX.Element => {
  const session: SessionContextValue = useSession();

  const router = useRouter();
  const rootStore = useRootStore();
  const authStore = rootStore.getAuth();

  rootStore.getError().setErrorHandler((e: ApplicationErrorEvent): void => {
    rootStore.getSnackbar().showError(`${e.message}`);
  });
  useEffect(() => {
    session.update();
  }, []);
  useEffect(() => {
    rootStore.getAuth().setSession(session);
  }, [rootStore, session?.data?.accessToken, session?.status, session?.data?.error]);

  const [showLanding, setShowLanding] = useState(
    !authStore.isInitialized || !authStore.isAuthenticated || !authStore.isLoaded || authStore.isAppError
  );
  useEffect(() => {
    setShowLanding(!authStore.isInitialized || !authStore.isAuthenticated || !authStore.isLoaded || authStore.isAppError);
  }, [authStore, authStore.isInitialized, authStore.isAuthenticated, authStore.isLoaded, authStore.isAppError]);
  if (showLanding) {
    return <Landing />;
  }

  const hasAccess = authStore.hasFrontOfficeAccess();

  if (router.route === '/' && hasAccess) {
    const redirectUri = authStore.profile?.features?.homePage ?? '/app/home';
    router.push(redirectUri).catch(r => {
      console.warn('router.push failed', r);
      rootStore.getError().pushAppError(new Error('Не удалось выполнить редирект ' + redirectUri));
    });
    return <Landing redirectUri={redirectUri} />;
  }

  const showChildren =
    hasAccess || // авторизованный сотрудник с доступом
    !isProtectedRoute(router); // общедоступная страница
  if (showChildren) {
    return <>{children}</>;
  }

  router.push('/').catch(rootStore.getError().catchWarn('Не удалось выполнить редирект на главную страницу'));
  return <Landing redirectUri="/" />;
});

export default AuthorizationGuard;
