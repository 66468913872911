import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { formatNumber3 } from '@mx-ui/helpers';
import { renderPriceCur } from 'src/utils/renderFormat';

export type DocumentTypeList =
  | 'agreements'
  | 'deals'
  | 'bills'
  | 'shipments'
  | 'returns'
  | 'payments'
  | 'approvals'
  | 'debtRelief'
  | 'batches'
  | 'freeze'
  | 'clients'
  | 'salesInvoice'
  | 'contracts';

export interface TotalStoreEntity {
  total: number;
  weight: number;
  cost: number;
  reservedWeight?: number;
  reservedCost?: number;
}
export const totalStoreGenerate = (
  total: number,
  weight: number,
  cost: number,
  reservedWeight?: number,
  reservedCost?: number
): TotalStoreEntity => {
  return {
    total,
    weight,
    cost,
    reservedWeight,
    reservedCost,
  };
};
interface DocumentTotalCountProps {
  documentType: DocumentTypeList;
  store: TotalStoreEntity;
  isLoading?: boolean;
}

const documentTypeListDictionary = {
  agreements: 'соглашений',
  deals: 'заявок',
  bills: 'счетов',
  shipments: 'отгрузок',
  returns: 'возвратов',
  payments: 'оплат',
  approvals: 'согласований',
  debtRelief: 'списаний долга',
  contracts: 'договоров',
  freeze: 'фризов',
  clients: 'клиентов',
};

export const DocumentTotalCount: FC<DocumentTotalCountProps> = observer(({ documentType, store, isLoading = true }): JSX.Element => {
  if (!store && !store?.cost && !store?.total && !store?.weight) {
    return null;
  }
  return (
    <Box display={'flex'} flexDirection={'column'} minWidth={'170px'}>
      {store.total && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Найдено ${documentTypeListDictionary[documentType]}: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            {isLoading ? (
              <Skeleton sx={{ maxWidth: '100%' }} />
            ) : (
              <Typography variant="caption" color="text.secondary">
                {store.total}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {store.cost && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Сумма: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            {isLoading ? (
              <Skeleton sx={{ maxWidth: '100%' }} />
            ) : (
              <Typography variant="caption" color="text.secondary">
                {renderPriceCur(store.cost)}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {store.weight && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Количество: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            {isLoading ? (
              <Skeleton sx={{ maxWidth: '100%' }} />
            ) : (
              <Typography variant="caption" color="text.secondary">
                {`${formatNumber3(store.weight / 1000)} т`}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}{' '}
      {store.reservedCost && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Сумма заморожено: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            {isLoading ? (
              <Skeleton sx={{ maxWidth: '100%' }} />
            ) : (
              <Typography variant="caption" color="text.secondary">
                {renderPriceCur(store.reservedCost)}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}{' '}
      {store.reservedWeight && (
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="caption" color="text.secondary">
              {`Количество заморожено: `}
            </Typography>
          </Grid>
          <Grid item minWidth={'50px'} textAlign={'right'}>
            {isLoading ? (
              <Skeleton sx={{ maxWidth: '100%' }} />
            ) : (
              <Typography variant="caption" color="text.secondary">
                {`${formatNumber3(store.reservedWeight / 1000)} т`}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
});
