import React, { FC, useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { observer } from 'mobx-react';
import { DealItemStore } from '../../store/DealItemStore';
import { DialogUpload } from 'src/components/UploadFile/DialogUpload';
import { SettingsMenu } from './components/SettingMenu';
import { FileUpload } from 'src/components/UploadFile/types';
import { ApproveResolutionControl } from 'src/components/Deals/ApproveResolutionControl';
import { IconApproval } from 'src/components/Deals/IconApprovalDeal';
import { IconWarningForApproval } from 'src/components/Deals/IconWarningForApproval';
import { ThreadLink } from 'src/components/Bills/ThreadLink';
import { ApproveHistoryWithUpdateLogsDropDown } from '../../components/Deals/ApproveResolutionControl/ApproveHistoryWithUpdateLogsDropDown';
import CallIcon from '@mui/icons-material/Call';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { LabelNonResident } from '../clients/components/CompanyExtInfo';
import { CircularProgress, Paper } from '@mui/material';
import { MxLabel, Link } from '@mx-ui/ui';

interface DealHeaderProps {
  dealStore: DealItemStore;
}

export const ClientTitleBreadcrumb: FC<{
  sxGrid?: any;
  customer: { code?: string; nonResident?: boolean; shortTitle?: string; title?: string };
}> = observer(({ customer, sxGrid }) => {
  const rootStore = useRootStore();
  const callStore = rootStore.getCallStore();
  const itClientisCalling = callStore.callContact?.customerCode === customer?.code && callStore.showCallDialog;
  return (
    <Link href={`/app/clients/${customer?.code}`} shallow noTypography>
      <Grid title={` Клиент ${customer?.shortTitle || customer?.title}`} sx={sxGrid}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(1fr, 1fr)',
            position: 'relative',
          }}
        >
          {customer?.nonResident ? <LabelNonResident sx={{ position: 'absolute' }} /> : null}

          <Typography
            variant="body1"
            color="textPrimary"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}
          >
            Клиент {customer?.shortTitle || customer?.title}
            {itClientisCalling && (
              <Box fontSize={'14px'} p={'2px'} pb={0} ml={'4px'} display={'inline'}>
                <CallIcon color="success" fontSize="inherit" />
              </Box>
            )}
          </Typography>
        </Box>
      </Grid>
    </Link>
  );
});
export const DealHeader: FC<DealHeaderProps> = observer(({ dealStore }): JSX.Element => {
  const documents = dealStore.deal?.documents
    ?.filter(i => i.positionCode === undefined)
    .map(i => ({ ...i, name: i.filename, size: i.filesize ?? 0, type: i.mime })) as FileUpload[];

  const handleUploadFiles = useCallback(
    (files: File[], typeCode: string): void => {
      dealStore.uploadDocuments(files, '', typeCode);
    },
    [dealStore]
  );
  const handleDeleteFile = useCallback(
    (codes: string[]): void => {
      dealStore.deleteDocuments(codes);
    },
    [dealStore]
  );

  const handleCreateBillFromDeal = (): void => {
    dealStore.createBillFromDeal();
  };
  return (
    <Grid
      container
      justifyContent="flex-end"
      mb={1}
      pt={dealStore.deal?.customer?.nonResident ? 1.5 : undefined}
      alignItems="center"
      width="100%"
    >
      <Grid item xs={12} mb={1}>
        <Grid container alignItems={'center'}>
          <Grid item>
            <Breadcrumbs sx={{ cursor: 'default', display: 'inline-flex' }}>
              <Link href="/app/deals" variant="body1" color="textPrimary">
                Заявки
              </Link>
              {dealStore.isLoaded && <ClientTitleBreadcrumb customer={dealStore.deal?.customer} />}
              {dealStore.isLoaded && (
                <Grid
                  position={'relative'}
                  sx={{
                    ['@media (max-width:500px)']: {
                      width: '97%',
                    },
                  }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Заявка #{dealStore.dealCode} {dealStore.deal?.createdAt && `от ${formatDateSwaggerZ(dealStore.deal?.createdAt)}`}
                  </Typography>
                  <Box position={'absolute'} top={'-2px'} right={'-30px'}>
                    <ThreadLink link={`/app/thread/deals/${encodeURIComponent(dealStore.dealCode)}`} />
                  </Box>
                </Grid>
              )}

              {!dealStore.isLoaded && <Skeleton variant="text" width={'300px'} />}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>

      <Grid item position={'relative'} xs={12} mb={1}>
        <Paper sx={{ p: 1 }}>
          <Grid container alignItems="center" justifyContent={'space-between'} spacing={1}>
            {!dealStore.isLoaded ? (
              <Grid item ml={'auto'}>
                <Skeleton variant="text" width={'320px'} />
              </Grid>
            ) : (
              <>
                <Grid item>{dealStore.isDeleted && <MxLabel color={'error'}>Заявка удалена</MxLabel>}</Grid>
                <Grid item ml={'auto'}>
                  <Grid container alignItems="center" justifyContent={'flex-end'} spacing={1}>
                    {(dealStore.isSaving || dealStore.isLoading) && (
                      <Grid item ml={5}>
                        <CircularProgress size={15} />
                      </Grid>
                    )}
                    {dealStore.deal.billCreateFromDealEnabled && (
                      <Grid item mr={1}>
                        <Button
                          variant={'outlined'}
                          size={'small'}
                          color={'info'}
                          onClick={handleCreateBillFromDeal}
                          disabled={dealStore.isLoading}
                        >
                          Сформировать счет
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <IconApproval approvalStore={dealStore.approvalStore} />
                    </Grid>
                    <Grid item>
                      <ApproveResolutionControl sizeButton="medium" approvalStore={dealStore.approvalStore} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container flexWrap="nowrap" alignItems="center">
                    <Grid item>
                      <ApproveHistoryWithUpdateLogsDropDown approvalStore={dealStore.approvalHistoryStore} immediatelyOnStart />
                    </Grid>
                    {(!!dealStore.deal?.submitNotices?.length || !!dealStore.deal?.approval?.currentBlockReasons?.length) && (
                      <Grid item>
                        <IconWarningForApproval
                          submitNotices={dealStore.deal?.submitNotices}
                          currentBlockReasons={dealStore.deal?.approval?.currentBlockReasons}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <DialogUpload
                        disabled={dealStore.isViewOnly()}
                        uploadFiles={handleUploadFiles}
                        deleteFiles={handleDeleteFile}
                        uploadedFiles={documents}
                      />
                    </Grid>
                    <Grid item>
                      <SettingsMenu dealStore={dealStore} />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
});
