import { AddressInfo, StorageItem as AddressStorageItem } from '../../../store/DealAddressStore';
import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelectNew } from '../components/CustomSelectNew';

interface DealAddressSelectProps {
  isViewOnly?: boolean;
  currentCode?: string;

  addresses: AddressStorageItem;
  onChange: (AddressCode: string | null) => void;
}

export const DealAddressSelect: FC<DealAddressSelectProps> = observer(({ isViewOnly, currentCode, addresses, onChange }): JSX.Element => {
  const values = useMemo(() => {
    const values = addresses.items.map((c: AddressInfo) => {
      return { value: c.code, text: c.title };
    });
    values.unshift({ text: '', value: '' });
    return values;
  }, [addresses, addresses.loadedEpoch]);

  return (
    <div>
      <CustomSelectNew label={'Адрес'} options={values} isViewOnly={isViewOnly} defaultValue={currentCode} onChange={onChange} />
    </div>
  );
});
