import { ApiStore } from './Global/ApiStore';
import { RootStore } from './StoreManager';
import { CatalogProductReservesV2Response, ProductItemReserve } from '../api/marketx';
import { AuthStore } from './AuthStore';
import { makeAutoObservable, runInAction } from 'mobx';

export class ReservesStore {
  apiStore?: ApiStore;
  authStore: AuthStore;
  page = 1;
  totalCount;
  countPerPage = 15;
  reserves: ProductItemReserve[] = [];
  nomenclatureCodes = '';
  isLoading = false;
  productCode: string;
  branchOfficeCode: string;
  hasMore = true;
  isForSoftReserve = false;
  warehouseCodes: Array<string> = [];

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();

    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  setCatalogProductReservesResult(data: CatalogProductReservesV2Response): void {
    if (data.reserves) {
      this.totalCount = data.reservesTotalCount;
      this.reserves.push(...data.reserves);
      this.hasMore = this.reserves.length < data.reservesTotalCount;
    }
    runInAction(() => {
      this.isLoading = false;
    });
  }

  loadMoreReserve(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.page += 1;
    if (this.nomenclatureCodes) {
      this.loadCatalogNomenclatureReserves(this.nomenclatureCodes.split(','), true);
    }
    if (this.productCode) {
      this.loadCatalogProductReserves(this.productCode, undefined, true);
    }
  }
  loadCatalogNomenclatureReserves(nomenclatureCodes: string[], isLoadMore = false, branchOfficeCode?: string): void {
    const nomenclatureCodesJoined = nomenclatureCodes.join(',');
    if (this.nomenclatureCodes === nomenclatureCodesJoined && !isLoadMore) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
    });
    if (this.nomenclatureCodes !== nomenclatureCodesJoined) {
      this.page = 1;
      this.nomenclatureCodes = nomenclatureCodesJoined;
      this.totalCount = undefined;
      this.reserves = [];
      this.productCode = '';
    }
    const branchOfficeCodeAuth = this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
    this.apiStore
      .apiClientCatalog()
      .catalogProductReservesV2({
        branchOfficeCodes: [branchOfficeCode || branchOfficeCodeAuth],
        nomenclatureCodes: this.nomenclatureCodes.split(','),
        findHardReserves: true,
        findSoftReserves: true,
        page: this.page,
        count: this.countPerPage,
        warehouseCodes: this.warehouseCodes.length ? this.warehouseCodes : undefined,
      })
      .then(res => {
        this.setCatalogProductReservesResult(res.data);
      })
      .catch(err => {
        console.warn('loadCatalogProductReservesRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  setWarehouseCodes(codes: Array<string>): void {
    this.warehouseCodes = codes;
  }

  loadCatalogProductReserves(
    productCode: string,
    branchOfficeCode?: string,
    isLoadMore = false,
    isForSoftReserve = false,
    warehouseCode?: string,
    autoReserveEnabled?: boolean
  ): void {
    if (this.productCode === productCode && !isLoadMore) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });
    this.isForSoftReserve = isForSoftReserve;
    if (branchOfficeCode) {
      this.branchOfficeCode = branchOfficeCode;
    }
    if (warehouseCode) {
      this.warehouseCodes = [warehouseCode];
    }
    if (this.productCode !== productCode) {
      this.page = 1;
      this.productCode = productCode;
      this.totalCount = undefined;
      this.reserves = [];
      this.nomenclatureCodes = '';
    }
    const branchOfficeCodeAuth =
      this.branchOfficeCode ||
      branchOfficeCode ||
      this.authStore.profile?.chosenBranchOfficeCode ||
      this.authStore.profile?.branchOfficeCode;
    this.apiStore
      .apiClientCatalog()
      .catalogProductReservesV2({
        productCodes: [productCode],
        branchOfficeCodes: [branchOfficeCode || branchOfficeCodeAuth],
        findHardReserves: this.isForSoftReserve ? undefined : true,
        findSoftReserves: true,
        page: this.page,
        count: this.countPerPage,
        sortByRank: this.isForSoftReserve ? true : undefined,
        warehouseCodes: this.warehouseCodes.length ? this.warehouseCodes : undefined,
        autoReserveEnabled: autoReserveEnabled ? true : undefined,
      })
      .then(res => {
        this.setCatalogProductReservesResult(res.data);
      })
      .catch(err => {
        console.warn('loadCatalogProductReservesRequest', err);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }
}
