import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import { observer } from 'mobx-react-lite';
import { ProductPacksStore } from 'src/store/ProductPacksStore';
import { ProductPackTableDetails, ProductPackTableDetailsMobile } from './ProductPackTableDetails';
import { ProductItemPack } from 'src/api/marketx';
import { toJS } from 'mobx';
import { DealItemStore } from 'src/store/DealItemStore';
import { AppDealPosition } from 'src/slices/AppDeal';
import { ProductsNormalType } from '../../../slices/AppCatalog';
import { CertificateBatchModal } from '../../../components/Shipments/CertificateBatchModal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';

interface ProductPackTableProps {
  product: ProductsNormalType;
  packsStore: ProductPacksStore;
  dealStore?: DealItemStore;
  dealPosition?: AppDealPosition;
  stickyHeader?: boolean;
  color?: string;
  infiniteScrollId?: string;
}
export type ItemPack = ProductItemPack & {
  inStockTne: number;
  inStockAltQuantity?: number;
  inStockAltUnitShort?: string;
  items: ProductItemPack[];
};
type ItemsPack = Record<string, ItemPack>;
const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;
export const ProductPackTable: FC<ProductPackTableProps> = observer(
  ({ packsStore, dealStore, dealPosition, stickyHeader = true, product, infiniteScrollId = '' }): JSX.Element => {
    const matches = useMediaQuery('(min-width:840px)');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [certificateParams, setCertificateParams] = useState({ code: '', shipmentCode: '', number: '' });
    const handleModalClose = (): void => {
      setIsModalOpen(false);
    };
    const handleModalOpen = (code: string, shipmentCode: string, number: string): void => {
      setCertificateParams({ code, shipmentCode, number });
      setIsModalOpen(true);
    };
    const items: ItemsPack = useMemo(
      () =>
        toJS(packsStore.packs).reduce<ItemsPack>((acc, pack) => {
          if (!acc[pack.batchNumber]) {
            acc[pack.batchNumber] = { ...pack, inStock: 0, inStockTne: 0, weight: pack.batchWeight, items: [] };
          }
          const inStockToTneRequired =
            pack.inStockUnitCode &&
            pack.inStockUnitCode == product.multiplicityUnitCode &&
            product.multiplicity &&
            product.multiplicity !== 1;
          const inStockToTneFactor = inStockToTneRequired ? product.multiplicity : 1;
          acc[pack.batchNumber].inStock += pack.inStock;
          acc[pack.batchNumber].inStockTne += pack.inStock * inStockToTneFactor;
          acc[pack.batchNumber].items.push(pack);

          return acc;
        }, {}),
      [packsStore.packs, packsStore.packs.length]
    );
    useEffect(() => {
      if (Object.keys(items)?.length < packsStore.packsCount && packsStore.hasMore) {
        packsStore.loadMore();
      }
    }, [packsStore, Object.keys(items)?.length, packsStore.hasMore]);
    if (!Object.keys(items)?.length) {
      return (
        <Box p={2}>
          <Typography variant="body2">Нет данных</Typography>
        </Box>
      );
    }
    return (
      <>
        <InfiniteScroll
          dataLength={Object.keys(items).length}
          next={() => packsStore.loadMore()}
          hasMore={packsStore.hasMore}
          style={listStyle}
          loader={listSkeleton}
          scrollableTarget={infiniteScrollId}
        >
          {matches ? (
            <Table
              stickyHeader={stickyHeader}
              sx={{ '& .MuiTableCell-root': { backgroundColor: 'background.paper' } }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Склад</TableCell>
                  <TableCell>Партия</TableCell>
                  <TableCell align="right">Вес</TableCell>
                  <TableCell align="right">Остаток</TableCell>
                  <TableCell title="Срок хранения" align="center">
                    ТОДХ
                  </TableCell>
                  <TableCell align="right" sx={{ maxWidth: 100 }}>
                    Дата поступления
                  </TableCell>
                  <TableCell align="right">Признак</TableCell>
                  <TableCell align="right">Сертификат</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!packsStore.isLoaded && (
                  <TableRow>
                    {Array.from(new Array(8)).map((value, index) => (
                      <TableCell key={index}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )) ||
                  Object.keys(items)?.map(batchNumber => {
                    return (
                      <ProductPackTableDetails
                        key={batchNumber}
                        batch={items[batchNumber]}
                        dealStore={dealStore}
                        dealPosition={dealPosition}
                        product={product}
                        handleModalOpen={handleModalOpen}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            Object.keys(items)?.map(batchNumber => {
              return (
                <ProductPackTableDetailsMobile
                  key={batchNumber}
                  batch={items[batchNumber]}
                  dealStore={dealStore}
                  dealPosition={dealPosition}
                  product={product}
                  handleModalOpen={handleModalOpen}
                />
              );
            })
          )}
        </InfiniteScroll>
        <CertificateBatchModal
          batchCode={certificateParams.code}
          batchNumber={certificateParams.number}
          shipmentCode={certificateParams.shipmentCode}
          isModalOpen={isModalOpen}
          closeModal={handleModalClose}
        />
      </>
    );
  }
);
