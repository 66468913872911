import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DealItemStore, UpdateQueueItem } from '../../../store/DealItemStore';
import { ChipTitleStyled } from './DealPositionPrices';
import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import { useDebounce } from 'src/utils/hooks/useDebounce';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CatalogProductsFastSearchResponse, ProductForFastSearch } from 'src/api/marketx';
import { pluralRus } from '@mx-ui/helpers';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { ProductPeculiaritiesIndicators } from 'src/views/catalog/Listing/ProductPeculiaritiesIndicators';
import { ProductExtensionsStore } from 'src/store/Catalog/ProductExtensionsStore';
import { Link } from '@mx-ui/ui';

interface DealPositionAddedManuallyContainerProps {
  dealStore: DealItemStore;
  lineNumber: number;
}
interface PositionChangeTitleFormProps {
  showLabel?: boolean;
  withLead?: boolean;
  isShowExtension?: boolean;
  paramStore?: Record<string, any>;
  filterExistsPos: (option: any) => boolean;
  fastSearhInCatalog: (str: string) => Promise<CatalogProductsFastSearchResponse>;
  onSubmit?: (value: any) => Promise<any>;
  defaultValue?: Record<string, string>;
}
const filter = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: any) => option.code + ' ' + option.title,
});
interface RenderOptionAutoComplete {
  option: Record<string, any>;
  paramStore: Record<string, any>;
  isShowExtension: boolean;
  productExtensionsStore: ProductExtensionsStore;
}

const RenderOptionAutoComplete: FC<RenderOptionAutoComplete> = observer(
  ({ option, paramStore, productExtensionsStore, isShowExtension }) => {
    const opt = option as ProductForFastSearch;
    let productExtension = null;
    if (isShowExtension) {
      productExtension = useMemo(() => {
        return productExtensionsStore.getExtension(opt.code, paramStore.warehouseCode, paramStore.branchOfficeCode);
      }, [opt.code, paramStore]);
    }

    return (
      <Box display="flex" flexDirection={'column'} width={'100%'}>
        <Box display="flex">
          <Box mx={0.5}>
            <Typography component="span" style={{ fontSize: '11px' }} noWrap>
              {`${opt.code || ''} `}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">{opt.title}</Typography>
          </Box>
        </Box>
        {isShowExtension && (
          <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '3px' }}>
            <ProductPeculiaritiesIndicators extension={productExtension} />
          </Box>
        )}
      </Box>
    );
  }
);

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, total, params, ...other } = props as any;

  return (
    <ul {...other} ref={ref}>
      {(total === 0 || total === null || total === undefined) && (
        <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'center'} p={1}>
          <Typography variant="body1" fontSize="14px" color="primary" fontWeight={600} sx={{ textTransform: 'uppercase' }}>
            Совпадений не найдено
          </Typography>
        </Box>
      )}
      {children}
      {total > 30 && (
        <li style={{ width: '100%' }}>
          <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'center'} p={1}>
            <Link href={`/app/catalog/goods?${params}`} sx={{ fontSize: 14, fontWeight: 600, textTransform: 'uppercase' }}>
              {'Показать еще'} {total} {pluralRus(total, ['совпадение', 'совпадения', 'совпадений'])}
            </Link>
          </Box>
        </li>
      )}
    </ul>
  );
}) as any;

export const PositionChangeTitleForm: FC<PositionChangeTitleFormProps> = observer(
  ({
    showLabel = true,
    onSubmit,
    filterExistsPos,
    paramStore,
    fastSearhInCatalog,
    isShowExtension = true,
    withLead = true,
  }): JSX.Element => {
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState<ProductForFastSearch[]>([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleSubmit = async (): Promise<void> => {
      setIsDisabled(true);
      onSubmit(value).finally(() => {
        setValue(null);
        setOptions(null);
      });
    };

    useEffect(() => {
      if (value !== null && value !== undefined) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, [value]);

    const [textFieldValue, setTextFieldValue] = useState('');
    const rootStore = useRootStore();
    const productExtensionsStore = rootStore.getProductExtensionsStore();
    const propsList = useMemo(() => {
      const params = new URLSearchParams({
        query: textFieldValue,
        ...paramStore,
      });
      return {
        total,
        params: params.toString(),
      };
    }, [paramStore, total, textFieldValue]);
    const handleChangeValue = (_e, v: any): void => {
      if (v === null) {
        setOptions(null);
      }
      setValue(v);
    };

    const textFieldValChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setTextFieldValue(e.target.value);
      setIsLoading(true);
      if (e.target.value == '') {
        setOptions(null);
      }
      debounceFilterSearch(e.target.value);
    };

    const debounceFilterSearch = useDebounce(searchStr => {
      fastSearhInCatalog(searchStr)
        .then(({ products, total }) => {
          setIsLoading(false);
          setOptions(products || []);
          setTotal(total);
        })
        .catch(e => {
          setIsLoading(false);
          console.log(e);
        });
    }, 500);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box
        sx={{
          display: 'flex',
          width: 'inherit',
          ...(showLabel && {
            '& .MuiTextField-root': { my: 1 },
          }),
        }}
      >
        <Autocomplete
          options={options || []}
          value={value}
          onChange={handleChangeValue}
          ListboxComponent={ListboxComponent}
          getOptionDisabled={filterExistsPos}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          noOptionsText={isLoading ? 'Идет поиск по товарам...' : 'Товары не найдены'}
          ListboxProps={propsList as any}
          componentsProps={
            matches
              ? {
                  paper: {
                    sx: {
                      width: 'calc(100vw - 80px)',
                    },
                  },
                }
              : {}
          }
          getOptionLabel={opt => {
            const option = opt as any;
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue && !isLoading) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
          filterOptions={(options: any, params) => {
            const filtered = filter(options, params);
            if (filtered.length === 0 && isLoading === false && params.inputValue && withLead) {
              filtered.push({
                inputValue: params.inputValue,
                title: `Добавить новый товар "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          renderOption={(props, option) => {
            const opt = option as ProductForFastSearch;
            return (
              <li {...props} key={opt.code || opt.title}>
                <RenderOptionAutoComplete
                  option={opt}
                  paramStore={paramStore}
                  isShowExtension={isShowExtension}
                  productExtensionsStore={productExtensionsStore}
                />
              </li>
            );
          }}
          sx={{
            width: '100%',
            minWidth: '200px',
            maxWidth: '600px',
            ...(!showLabel && {
              '& .MuiInputBase-input': {
                padding: '3.5px 14px',
              },
            }),
          }}
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              hiddenLabel={matches}
              onChange={textFieldValChange}
              value={textFieldValue}
              size="small"
              label={!matches && !showLabel ? undefined : 'Введите артикул или наименование товара'}
            />
          )}
        />

        {/* <Controller
          name={'title'}
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <TextField
              hiddenLabel={matches}
              size="small"
              label={!matches && !showLabel ? undefined : 'Наименование'}
              //   helperText={errors.title?.type === 'required' && 'Поле обязательно для заполнения'}
              error={!!errors.title}
              sx={{
                width: '100%',
                minWidth: '200px',
                maxWidth: '600px',
                pl: 1,
                ...(!showLabel && {
                  '& .MuiInputBase-input': {
                    padding: '3.5px 14px',
                  },
                }),
              }}
              {...field}
            />
          )}
        /> */}
        <Button
          onClick={handleSubmit}
          sx={{ ...(showLabel ? { m: 1 } : { ml: 1 }) }}
          disabled={isDisabled}
          size={'small'}
          variant={'contained'}
        >
          {matches ? 'Ок' : 'Добавить'}
        </Button>
        {/* <Button
          sx={{ ...(showLabel ? { m: 1 } : { ml: 1 }) }}
          size={'small'}
          onClick={handleClose}
          disabled={isSubmitting}
          variant={'outlined'}
        >
          Отмена
        </Button> */}
      </Box>
    );
  }
);
// позиция добавления новой позиции (лид позиции)
export const DealPositionAddedManuallyContainer: FC<DealPositionAddedManuallyContainerProps> = observer(
  ({ dealStore, lineNumber = 0 }): JSX.Element => {
    const onSubmit = useCallback(
      async (value: any): Promise<void | UpdateQueueItem> => {
        if (value?.inputValue) {
          // создание лида
          return dealStore
            .updateDeal({ leadPositions: [{ title: value.inputValue }] })
            .catch(r => console.warn('handleManualyAddedPosition', r));
        } else if (value.code) {
          const [pos, agreementCode] = dealStore.findAgreementByPositionCode(value.code, 'productCode');
          if (pos) {
            return dealStore
              .addPositionsFromAgreement(
                [
                  {
                    frontCode: dealStore.dealCode,
                    agreementPositionCode: pos.code,
                    productCode: value.code,
                    amount: 1,
                    warehouseCode: dealStore.deal.warehouseCode,
                    bareUnitCost: pos.baseMinRetailUnitCost,
                    unitCode: value.unitCode,
                    comment: pos.comment,
                  },
                ] as any,
                agreementCode,
                dealStore.deal.customerCode
              )
              .catch(r => console.warn('handleManualyAddedPosition addPositionsFromAgreement', r));
          } else {
            // добавление товара
            return dealStore
              .addPosition(value, 1, value.unitPrice, undefined, value.unitCode, dealStore.deal.warehouseCode)
              .catch(r => console.warn('handleManualyAddedPosition addPosition', r));
          }
        }
      },
      [dealStore]
    );
    const filterExistsPos = useCallback(
      (option: any): boolean => {
        return Boolean(dealStore.deal.positions.filter(i => i.productCode === option.code).length);
      },
      [dealStore.deal.positions]
    );
    const paramStore = useMemo(
      () => ({ warehouseCode: dealStore.deal.warehouseCode, branchOfficeCode: dealStore.deal.branchOfficeCode, deal: dealStore.dealCode }),
      [dealStore, dealStore.deal]
    );
    const fastSearhInCatalog = useCallback(searchStr => dealStore.fastSearhInCatalog(searchStr), [dealStore]);
    return (
      <Grid item container component={Paper} pr={1} mb={1}>
        <Box pl={1} alignSelf="center">
          <ChipTitleStyled label={`#${lineNumber}`} />
        </Box>
        <Box sx={{ width: 'calc(100% - 64px)' }}>
          <PositionChangeTitleForm
            onSubmit={onSubmit}
            fastSearhInCatalog={fastSearhInCatalog}
            paramStore={paramStore}
            filterExistsPos={filterExistsPos}
          />
        </Box>
      </Grid>
    );
  }
);
