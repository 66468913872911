import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import { AppDealPosition, AppDealUpdates } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
import { DealPositionServicesItem } from './DealPositionServicesItem';
import { Button, Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { StyledTextField, SyncedTextField } from '../../../components/SyncedInputs/SyncedTextField';
import { ValueStoreInputTypes } from '../../../store/ValueStore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { ServiceManualItem } from './ServiceManualItem';
import { toJS } from 'mobx';

interface ServicesDropDownProps {
  dealStore: DealItemStore;
  dealPosition: AppDealPosition;
  isViewOnly?: boolean;
  enabledServices: any;
}

const ServicesDropDown: FC<ServicesDropDownProps> = observer(({ dealStore, dealPosition, isViewOnly, enabledServices }): JSX.Element => {
  const manualServicesCostStore = dealStore.getValueStoreByPos(dealPosition, 'manualServicesCost', ValueStoreInputTypes.PositiveFloat);
  const [useManualServicesCost, setUseManualServicesCost] = useState(!!dealPosition?.useManualServicesCost);
  const handleUseManualServicesCostOnChange = useCallback(() => {
    const newValue = !useManualServicesCost;
    setUseManualServicesCost(newValue);
    dealStore
      .updatePosition(dealPosition, { useManualServicesCost: newValue })
      .catch(r => console.warn('handleUseManualServicesCostOnChange', r));
  }, [dealStore, useManualServicesCost, setUseManualServicesCost, dealPosition]);

  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = React.useRef();
  const handleClose = useCallback((): void => {
    setIsOpen(false);
    buttonRef.current = null;
  }, [setIsOpen]);

  const handleShow = (event): void => {
    buttonRef.current = event.currentTarget;
    setIsOpen(!isOpen);
  };

  const handleServiceCheckboxChange = useCallback(
    (event: ChangeEvent<any>): void => {
      event.stopPropagation();
      let change: AppDealUpdates;
      if (!(event.target instanceof HTMLInputElement)) {
        console.warn('handleServiceCheckboxChange not a checkbox');
        return;
      }
      const cb = event.target as HTMLInputElement;
      const checked = !!cb.checked;
      switch (cb.name) {
        case 'distributeDelivery':
        case 'distributeCredit':
          change = {
            [cb.name]: checked,
          };
          dealStore.updatePosition(dealPosition, change).catch(r => console.warn('handleServiceCheckboxChange', r));
          return;
      }

      dealPosition.services?.map(s => {
        if (s.code === cb.name) {
          s.enabled = checked;
        }
      });
      dealStore.updatePositionServices(dealPosition.code, cb.name, checked).catch(r => {
        console.warn('dealStore.updatePositionServices', r);
      });
    },
    [dealStore, dealPosition]
  );
  console.log('dealPosition?.services', toJS(dealPosition?.services));
  return (
    <>
      <Button
        endIcon={<ArrowDropDownIcon />}
        onClick={handleShow}
        sx={{ width: '100%', color: 'text.secondary', border: 'solid 1px #b9b7b7', fontWeight: !enabledServices?.length ? 600 : 500 }}
        ref={buttonRef}
      >
        {isViewOnly && !enabledServices?.length ? (
          `Услуги не выбраны`
        ) : (
          <span>{`Выбрано услуг ${enabledServices.length} из ${dealPosition.services?.length || 0}`}</span>
        )}
      </Button>

      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {(dealPosition.services?.length || 0) && (
          <Box width="100%" px={1} pt={1}>
            <FormControl component="fieldset" onChange={handleServiceCheckboxChange}>
              <FormLabel style={{ marginBottom: '8px' }} component="legend">
                Доступные услуги
              </FormLabel>
              <FormGroup>
                {dealPosition.services?.map(service => (
                  <DealPositionServicesItem service={service} isViewOnly={isViewOnly} key={service.code} dealStore={dealStore} />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        )}
        {dealPosition?.services?.some(s => s.code === 'certification') ? (
          <ServiceManualItem
            dealStore={dealStore}
            dealPosition={dealPosition}
            name={'Сертификация'}
            field={'certification'}
            isViewOnly={isViewOnly}
          />
        ) : null}
        {dealPosition?.services?.some(s => s.code === 'manual_packing') ? (
          <ServiceManualItem
            dealStore={dealStore}
            dealPosition={dealPosition}
            name={'Упаковка товара'}
            field={'manual_packing'}
            isViewOnly={isViewOnly}
          />
        ) : null}
        {dealPosition?.services?.some(s => s.code === 'debt') ? (
          <ServiceManualItem dealStore={dealStore} dealPosition={dealPosition} name={'Долг'} field={'debt'} isViewOnly={isViewOnly} />
        ) : null}

        <Grid container alignItems={'center'} px={1} justifyContent={'space-between'}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isViewOnly}
                  checked={useManualServicesCost}
                  name={`useManualServicesCost-${dealPosition.code}`}
                  onChange={handleUseManualServicesCostOnChange}
                />
              }
              label={
                <Typography
                  component={'span'}
                  variant={'body1'}
                  style={{ cursor: isViewOnly ? 'not-allowed' : 'pointer', color: isViewOnly && 'rgba(0, 0, 0, 0.38)' }}
                >
                  Стоимость услуг
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'} flexWrap={'nowrap'} maxWidth={100}>
              {isViewOnly ? (
                <>
                  <Grid item>
                    <StyledTextField value={manualServicesCostStore.asString()} disabled />
                  </Grid>
                  <Grid item ml={1}>
                    <Typography component={'span'} variant={'body2'} color={'text.secondary'}>
                      ₽
                    </Typography>
                  </Grid>
                </>
              ) : (
                <SyncedTextField value={manualServicesCostStore} suffix={'₽'} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
});

export { ServicesDropDown };
