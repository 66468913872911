import React, { CSSProperties, FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import { observer } from 'mobx-react-lite';
import { ProductPacksStore } from 'src/store/ProductPacksStore';
import { DealItemStore } from 'src/store/DealItemStore';
import { AppDealPosition } from 'src/slices/AppDeal';
import { Product } from '../../../api/marketx';
import { PackByProductTableDetails, PackByProductTableDetailsMobile } from './PackByProductTableDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
interface PackByProductTableProps {
  packsStore: ProductPacksStore;
  product: Product;
  dealStore?: DealItemStore;
  dealPosition?: AppDealPosition;
  color?: string;
  className?: string;
  infiniteScrollId?: string;
}
const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;
const PackByProductTable: FC<PackByProductTableProps> = observer(
  ({ packsStore, product, color: backgroundColor = '#fff', dealStore, dealPosition, className, infiniteScrollId = '' }): JSX.Element => {
    const matches = useMediaQuery('(min-width:840px)');
    if (!packsStore.packs?.length) {
      return (
        <Box p={2}>
          <Typography variant="body2">Нет данных</Typography>
        </Box>
      );
    }
    return (
      <>
        <InfiniteScroll
          dataLength={packsStore.packs.length}
          next={() => packsStore.loadMore()}
          hasMore={packsStore.hasMore}
          style={listStyle}
          loader={listSkeleton}
          scrollableTarget={infiniteScrollId}
        >
          {matches ? (
            <Table
              stickyHeader
              size={'small'}
              className={className}
              aria-label="enhanced table"
              sx={{ '& .MuiTableCell-root': { backgroundColor: 'background.paper' } }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Склад</TableCell>
                  <TableCell>Партия</TableCell>
                  <TableCell align="right">Код</TableCell>
                  <TableCell align="right">Кассета</TableCell>
                  <TableCell align="right">Вес</TableCell>
                  <TableCell align="right">Остаток</TableCell>
                  <TableCell align="right" sx={{ maxWidth: 100, backgroundColor }}>
                    Дата поступления
                  </TableCell>
                  <TableCell align="right">Признак</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!packsStore.isLoaded && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )) ||
                  packsStore.packs?.map((item, index) => {
                    return (
                      <PackByProductTableDetails
                        key={index}
                        pack={item}
                        product={product}
                        dealStore={dealStore}
                        dealPosition={dealPosition}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            packsStore.packs?.map((item, index) => {
              return (
                <PackByProductTableDetailsMobile
                  key={index}
                  pack={item}
                  product={product}
                  dealStore={dealStore}
                  dealPosition={dealPosition}
                />
              );
            })
          )}
        </InfiniteScroll>
      </>
    );
  }
);
export { PackByProductTable };
