import { FC, useCallback } from 'react';
// import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { MsgType } from '../../../store/Global/WebSocketStore';
import { MsgType as MsgTypeCollie } from '../../../store/Global/WebSocketCollieStore';
import { useWebSocketCollieHandler, useWebSocketHandler } from 'src/hooks/useWebSocketHandler';
import { Link } from '@mx-ui/ui';

// import { UserNotificationsItem } from 'src/api/marketx';

const WsNotifies: FC = observer((): JSX.Element => {
  const rootStore = useRootStore();
  const sbStore = rootStore.getSnackbar();
  const callStore = rootStore.getCallStore();
  // console.log('wscall WsNotifies', callStore.data);
  const handler = useCallback(
    msg => {
      const dealCode = msg.data?.dealCode;
      const agreementCode = msg.data?.agreementCode;
      // let message: UserNotificationsItem;

      // обработка сокета по заявкам
      if (dealCode) {
        switch (msg.msgType) {
          case MsgType.WEB_SOCKET_DEAL_APPROVAL_ASK:
            sbStore.showInfo(
              <span>
                Запрошено согласование для заявки № <Link href={'/app/deals/' + dealCode}>{dealCode}</Link>
              </span>
            );

            break;

          case MsgType.WEB_SOCKET_DEAL_APPROVAL_ACCEPTED:
            sbStore.showSuccess(
              <span>
                Согласована заявка № <Link href={'/app/deals/' + dealCode}>{dealCode}</Link>
              </span>
            );

            break;

          case MsgType.WEB_SOCKET_DEAL_APPROVAL_DECLINED:
            sbStore.showError(
              <span>
                Отклонена заявка № <Link href={'/app/deals/' + dealCode}>{dealCode}</Link>
              </span>
            );

            break;
          case MsgType.WEB_SOCKET_DEAL_APPROVAL_ASK_WITHDRAW:
            sbStore.showInfo(
              <span>
                Запрос на согласование заявки № <Link href={'/app/deals/' + dealCode}>{dealCode}</Link>
                отозван
              </span>
            );

            break;
        }
        // обработка сокета по соглашениям
      } else if (agreementCode) {
        switch (msg.msgType) {
          case MsgType.WEB_SOCKET_AGREEMENT_APPROVAL_ASK:
            sbStore.showInfo(
              <span>
                Запрошено согласование для соглашения № <Link href={'/app/agreements/' + agreementCode}>{agreementCode}</Link>
              </span>
            );

            break;

          case MsgType.WEB_SOCKET_AGREEMENT_APPROVAL_ACCEPTED:
            sbStore.showSuccess(
              <span>
                Согласовано соглашение № <Link href={'/app/agreements/' + agreementCode}>{agreementCode}</Link>
              </span>
            );

            break;

          case MsgType.WEB_SOCKET_AGREEMENT_APPROVAL_DECLINED:
            sbStore.showError(
              <span>
                Отклонено соглашение № <Link href={'/app/agreements/' + agreementCode}>{agreementCode}</Link>
              </span>
            );

            break;
          case MsgType.WEB_SOCKET_AGREEMENT_APPROVAL_ASK_WITHDRAW:
            sbStore.showInfo(
              <span>
                Запрос на согласование соглашения № <Link href={'/app/agreements/' + agreementCode}>{agreementCode}</Link> отозван
              </span>
            );

            break;
        }
      } else {
        console.warn('Необработанное событие сокета msg.data=', msg.data);
        return;
      }

      // if (message && typeof message === 'object' && Object.keys(message).length !== 0) {
      //   notificationsStore.addItem(message);
      // }
    },
    [sbStore]
  );
  const handlerCallWs = useCallback(
    msg => {
      // console.log('wscall msg', msg);
      switch (msg.msgType) {
        case MsgTypeCollie.WEB_SOCKET_CALL_UPDATED:
          callStore.setData(msg.data);

          break;
      }
    },
    [callStore]
  );
  useWebSocketHandler(handler);
  useWebSocketCollieHandler(handlerCallWs);

  return <></>;
});

export default WsNotifies;
