import React, { FC, JSX, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Skeleton from '@mui/material/Skeleton';
import { toJS } from 'mobx';
import { formatNumberByUnit, formatPrice } from '@mx-ui/helpers';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  CatalogProductsAlternativesNomenclature,
  CatalogProductsAlternativesPositionProduct,
  CatalogProductsAlternativesSegmentationGroup,
  Product,
} from '../../../api/marketx';
import { ProductIndicatorsByValue } from '../../catalog/Listing/ProductPeculiaritiesIndicators';
import { DealAlternativeProductBatchesItem } from './DealAlternativeProducts';
import { PositionsManufacturersListStore } from '../../../store/Deals/PositionsManufacturersListStore';
import { CustomIconTabs } from '../../../components/Deals/CustomIconTabs/CustomIconTabs';
import { AlternativeProductsTableForCatalogMobile } from './AlternativeProductsTableForCatalogMobile';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { multicoloredPopoverBg } from '../../clients/lib';
import { Link } from '@mx-ui/ui';
interface AlternativeProductsTableForCatalogProps {
  product: Product;
  manufacturersStore: PositionsManufacturersListStore;
  isProductCard?: boolean;
  onSearch?: (value: string) => void;
}

export const alternativeStockText = (
  inStockForSale: number,
  inStock: number,
  stockUnitCode: string,
  stockUnitShortName: string
): string => {
  return `есть ${formatNumberByUnit(inStockForSale, stockUnitCode, stockUnitShortName)} из ${formatNumberByUnit(
    inStock,
    stockUnitCode,
    stockUnitShortName
  )}`;
};

export const alternativeInTransitText = (
  inTransitQuantity: number,
  distributedQuantity: number,
  stockUnitCode: string,
  stockUnitShortName: string
): string => {
  return `в пути ${formatNumberByUnit(inTransitQuantity, stockUnitCode, stockUnitShortName)} из ${formatNumberByUnit(
    distributedQuantity,
    stockUnitCode,
    stockUnitShortName
  )}`;
};

export const AlternativeProductsTableForCatalog: FC<AlternativeProductsTableForCatalogProps> = observer(
  ({ manufacturersStore, product, isProductCard = false, onSearch }): JSX.Element => {
    const matchesMd = useMediaQuery('(min-width:950px)');
    const [selectedAdditionalTab, setSelectedAdditionalTab] = useState(0);

    const { canViewPurchasePrice, nomenclature, segmentationGroup } = useMemo(() => {
      const mp = toJS(manufacturersStore.getPositionManufacturersForProduct(product));
      if (manufacturersStore.loading || !manufacturersStore.loaded) {
        return { canViewPurchasePrice: false, nomenclature: null, segmentationGroup: null };
      }
      return {
        canViewPurchasePrice: !!mp.canViewPurchasePrice,
        nomenclature: { ...mp.nomenclature, products: mp.nomenclature?.products },
        segmentationGroup: { ...mp.segmentationGroup, products: mp.segmentationGroup?.products },
      };
    }, [manufacturersStore, manufacturersStore.loadedEpoch, product]);
    const innerTabsValue = useMemo(() => {
      return [
        {
          label: ``,
          title: 'Группа номенклатуры',
          tabPanelIndex: 0,
          disabled: false,
          icon: (
            <Grid container alignItems={'center'} px={0.7}>
              <Grid item height={'18px'}>
                <WorkspacesRoundedIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item ml={1}>
                <Typography component={'span'} sx={{ fontSize: '10px', cursor: 'pointer' }}>
                  Номенклатура
                </Typography>
              </Grid>
            </Grid>
          ),
          badgeOn: false,
        },
        {
          label: ``,
          title: 'Группа сегментации',
          tabPanelIndex: 1,
          disabled: false,
          icon: (
            <Grid container alignItems={'center'} px={0.7}>
              <Grid item height={'18px'}>
                <WorkspacesRoundedIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item ml={1}>
                <Typography component={'span'} sx={{ fontSize: '10px', cursor: 'pointer' }}>
                  Сегментация
                </Typography>
              </Grid>
            </Grid>
          ),
          badgeOn: false,
        },
      ];
    }, []);
    const handleTabChange = useCallback(
      (event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedAdditionalTab(newValue);
      },
      [setSelectedAdditionalTab]
    );

    return matchesMd ? (
      <Grid item sx={{ maxWidth: '100%', height: '100%' }}>
        <Grid container maxWidth={'100%'} sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 100%)', MozPaddingBottom: '15px' }}>
          <Grid item height={'37px'} pl={1} py={0.5}>
            <CustomIconTabs
              handleToggleButtonChange={handleTabChange}
              toggleButtonValue={selectedAdditionalTab}
              tabsValue={innerTabsValue}
              padding="0 2px"
              sx={{ minHeight: '34px' }}
            />
          </Grid>
          <Grid item minWidth={'800px'}>
            {selectedAdditionalTab === 0 && (
              <AlternativeProductTable
                canViewPurchasePrice={canViewPurchasePrice}
                alternativeObj={nomenclature}
                manufacturersStore={manufacturersStore}
                position={product}
                isProductCard={isProductCard}
                onSearch={onSearch}
              />
            )}
            {selectedAdditionalTab === 1 && (
              <Grid container>
                <AlternativeProductTable
                  canViewPurchasePrice={canViewPurchasePrice}
                  alternativeObj={segmentationGroup}
                  manufacturersStore={manufacturersStore}
                  position={product}
                  isProductCard={isProductCard}
                  onSearch={onSearch}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} height={'37px'} pl={2} py={0.5} ml={-2}>
            <CustomIconTabs
              handleToggleButtonChange={handleTabChange}
              toggleButtonValue={selectedAdditionalTab}
              tabsValue={innerTabsValue}
              padding="0 2px"
              sx={{ minHeight: '34px' }}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedAdditionalTab === 0 && (
              <AlternativeProductsTableForCatalogMobile
                canViewPurchasePrice={canViewPurchasePrice}
                alternativeObj={nomenclature}
                manufacturersStore={manufacturersStore}
                position={product}
                onSearch={onSearch}
              />
            )}
            {selectedAdditionalTab === 1 && (
              <AlternativeProductsTableForCatalogMobile
                canViewPurchasePrice={canViewPurchasePrice}
                alternativeObj={segmentationGroup}
                manufacturersStore={manufacturersStore}
                position={product}
                onSearch={onSearch}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

interface AlternativeProductTableProps {
  manufacturersStore: PositionsManufacturersListStore;
  alternativeObj: CatalogProductsAlternativesSegmentationGroup | CatalogProductsAlternativesNomenclature;
  canViewPurchasePrice: boolean;
  position: Product;
  isProductCard?: boolean;
  onSearch?: (value: string) => void;
}
const AlternativeProductTable: FC<AlternativeProductTableProps> = observer(
  ({ alternativeObj, canViewPurchasePrice, manufacturersStore, position, isProductCard, onSearch }): JSX.Element => {
    const colorForStock = alternativeObj?.inStockForSale > 0 ? 'green' : 'red';
    return (
      <Grid container>
        <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} p={1}>
          {(onSearch && <Grid item flex={'0 0 35px'}></Grid>) || null}
          <Grid item flex={'0 0 300px'}></Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              Индикаторы
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              Цена закупки
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              МРЦ
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              Срок хранения
            </Typography>
          </Grid>
        </Grid>
        {(!manufacturersStore.loaded || manufacturersStore.loading) && (
          <>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
            <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'}>
              <Skeleton width={'100%'} height={'60px'} />
            </Grid>
          </>
        )}
        {manufacturersStore.loaded && !alternativeObj?.products?.length && (
          <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
            Альтернативных товаров нет
          </Typography>
        )}
        {manufacturersStore.loaded && alternativeObj?.products?.length && (
          <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} p={1}>
            {(onSearch && <Grid item flex={'0 0 35px'}></Grid>) || null}
            <Grid item flex={'0 0 300px'}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="span" variant="body2" fontWeight={500}>
                    {/* @ts-ignore*/}
                    {alternativeObj?.name || alternativeObj?.title || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item>
                      <Box
                        display="flex"
                        sx={{
                          borderRadius: 1,
                          mr: 0.5,
                          height: '20px',
                          ...multicoloredPopoverBg(colorForStock),
                        }}
                      >
                        <Typography variant="caption" color="inherit" fontWeight="500">
                          {alternativeStockText(
                            alternativeObj?.inStockForSale,
                            alternativeObj?.inStock,
                            alternativeObj?.stockUnitCode,
                            alternativeObj?.stockUnitShortName
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    {((alternativeObj?.inTransitQuantity || alternativeObj?.distributedQuantity) && (
                      <Grid item>
                        <Box
                          display="flex"
                          sx={{
                            borderRadius: 1,
                            mr: 0.5,
                            height: '20px',
                            backgroundColor: '#D6D6D6',
                            border: '1px solid #242424',
                          }}
                        >
                          <Typography variant="caption" sx={{ color: '#333' }} fontWeight="500">
                            {alternativeInTransitText(
                              alternativeObj?.inTransitQuantity,
                              alternativeObj?.distributedQuantity,
                              alternativeObj?.stockUnitCode,
                              alternativeObj?.stockUnitShortName
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    )) ||
                      null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flex={'1 1 110px'} textAlign={'right'}></Grid>
            <Grid item flex={'1 1 110px'} textAlign={'right'}>
              {!!canViewPurchasePrice ? (
                <Typography component="span" variant="body2" fontWeight={500}>
                  {`${formatPrice(alternativeObj?.purchasePrice)} ₽`}
                </Typography>
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </Grid>
            <Grid item flex={'1 0 110px'} textAlign={'right'}>
              <Typography component="span" variant="body2" fontWeight={500}>
                {`${formatPrice(alternativeObj.minRetailUnitCost)} ₽`}
              </Typography>
            </Grid>
            <Grid item flex={'1 0 110px'} textAlign={'right'}>
              <Typography component="span" variant="body2" fontWeight={500}>
                {alternativeObj.oldShelfLife || ''}
              </Typography>
            </Grid>
          </Grid>
        )}
        {manufacturersStore.loaded &&
          alternativeObj?.products?.length &&
          alternativeObj?.products.map((product, index) => (
            <React.Fragment key={product?.productCode || index}>
              <AlternativeProductItem
                product={product}
                position={position}
                canViewPurchasePrice={canViewPurchasePrice}
                isProductCard={isProductCard}
                onSearch={onSearch}
              />
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  }
);

interface AlternativeProductItemProps {
  product: CatalogProductsAlternativesPositionProduct;
  position: Product;
  canViewPurchasePrice: boolean;
  isProductCard?: boolean;
  onSearch?: (value: string) => void;
}

const AlternativeProductItem: FC<AlternativeProductItemProps> = observer(
  ({ product, position, canViewPurchasePrice, isProductCard, onSearch }): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const handleToggleRowClick = useCallback((): void => {
      setOpen(open => !open);
    }, [setOpen]);
    const isCurrent = product.productCode === position.code;

    const cursorType = product?.batches?.length ? 'pointer' : '';
    const handleProductSearch = (value: string): void => {
      if (onSearch) {
        onSearch(value);
      }
    };
    const colorForStock = product?.inStockForSale > 0 ? 'green' : 'red';
    return (
      <>
        <Grid
          item
          xs={12}
          container
          alignItems={'center'}
          flexWrap={'nowrap'}
          sx={{ background: theme => (isCurrent ? (theme.palette.mode === 'dark' ? '#090909' : '#EDEEF5') : 'inherit') }}
          p={1}
        >
          {(onSearch && (
            <Grid item flex={'0 0 35px'}>
              <IconButton size={'small'} title={'Поиск по товару'} onClick={() => handleProductSearch(product.productTitle)}>
                <ManageSearchRoundedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Grid>
          )) ||
            null}
          <Grid item flex={'0 0 300px'}>
            <Grid container alignItems={'center'}>
              <Grid item>
                {isProductCard && product.productCode === position.code ? (
                  <Typography component="span" variant="caption">
                    {`${product?.productCode || ''} ${product.productTitle}`}
                  </Typography>
                ) : (
                  <Link
                    href={`/app/product/${encodeURIComponent(product?.productCode)}`}
                    color="inherit"
                    variant="caption"
                    target={'_blank'}
                    title={'Перейти в карточку товара'}
                  >
                    {`${product?.productCode || ''} ${product.productTitle}`}
                  </Link>
                )}
              </Grid>
              <Grid item>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Box
                      display="flex"
                      sx={{
                        borderRadius: 1,
                        mr: 0.5,
                        height: '20px',
                        ...multicoloredPopoverBg(colorForStock),
                      }}
                    >
                      <Typography variant="caption" color="inherit" fontWeight="500">
                        {alternativeStockText(
                          product?.inStockForSale,
                          product?.inStock,
                          product?.stockUnitCode,
                          product?.stockUnitShortName
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  {((product?.inTransitQuantity || product?.distributedQuantity) && (
                    <Grid item>
                      <Box
                        display="flex"
                        sx={{
                          borderRadius: 1,
                          mr: 0.5,
                          height: '20px',
                          backgroundColor: '#D6D6D6',
                          border: '1px solid #242424',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: '#333' }} fontWeight="500">
                          {alternativeInTransitText(
                            product?.inTransitQuantity,
                            product?.distributedQuantity,
                            product?.stockUnitCode,
                            product?.stockUnitShortName
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  )) ||
                    null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <ProductIndicatorsByValue
              isNew={product?.batches && !!product.isNew}
              isOld={product?.batches && !!product.isOld}
              hasCustody={!!product.hasCustody}
              hasSelfPurchase={!!product.hasSelfPurchase}
              hasTodh={!!product.hasTodhDiscount}
              hasIlliquid={!!product.hasIlliquid}
            />
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            {!!canViewPurchasePrice ? (
              <Typography component="span" variant="caption" sx={{ cursor: cursorType }}>
                {`${formatPrice(product?.purchasePrice)} ₽`}
              </Typography>
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Typography component="span" variant="caption" sx={{ cursor: cursorType }}>
              {`${formatPrice(product?.minRetailUnitCost)} ₽`}
            </Typography>
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Typography component="span" variant="caption" sx={{ cursor: cursorType }}>
              {`${product?.oldShelfLife || '-'}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} pt={1}>
          <Collapse in={open} unmountOnExit timeout="auto" sx={{ width: '100%' }}>
            <Grid container>
              {product.batches?.map((batch, index) => (
                <React.Fragment key={batch.code}>
                  <DealAlternativeProductBatchesItem batch={batch} forDeal={!!onSearch} canViewPurchasePrice={canViewPurchasePrice} />
                  {index !== product.batches?.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </>
    );
  }
);
