import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import { Grid, IconButton } from '@mui/material';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { formatNumberByUnit } from '@mx-ui/helpers';
import { MxLinearProgress } from '@mx-ui/ui';
import { multicoloredPopoverBg } from '../../clients/lib';
import { mapReserveWidgetForBillOrDeal } from '../../../slices/AppDeal/lib';
import { BillPosition } from '../../../api/marketx';
import { BillPositionTabsVariants } from '../../../components/Bills/BillPositionItem';
import { BillItemStore } from '../../../store/Documents/BillItemStore';

interface ReserveWidgetProps {
  position: AppDealPosition | BillPosition;
  setSelectedAdditionalTab: React.Dispatch<React.SetStateAction<number>> | React.Dispatch<React.SetStateAction<BillPositionTabsVariants>>;
  documentState: string;
  selectedTabNumber: number;
  isDeal?: boolean;
  billStore?: BillItemStore;
}

export const ReserveWidget: FC<ReserveWidgetProps> = observer(
  ({ documentState, position, setSelectedAdditionalTab, selectedTabNumber, isDeal = false, billStore }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tooltipName, setTooltipName] = useState('');
    const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string): void => {
      event.stopPropagation();
      setAnchorEl(event.target);
      setTooltipName(type);
    };
    const handleTooltipClose = (): void => {
      setAnchorEl(null);
      setTooltipName('');
    };
    const {
      titleForStock,
      colorForStock,
      baseColor,
      baseQuantity,
      baseUnit,
      infoText,
      isBalanceShown,
      hasExpectationReserve,
      reserveProgress,
      reserveTotal,
      shipmentQuantity,
      currentReserve,
      dealBaseQuantity,
      inAwaitingQuantity,
      isShipmentBlockVisible,
      inStock,
      inStockForSale,
    } = mapReserveWidgetForBillOrDeal(position, documentState, isDeal);
    const showBalance = isBalanceShown || billStore?.isEditing;
    const noticesInfoText = (billStore?.notices || [])?.filter(
      i => i.typeCode === 'quantity_is_greater_than_before_and_not_enough_stock'
    )[0]?.content;
    let stockColor = billStore?.isEditing ? 'green' : '';
    if (position?.baseQuantity - currentReserve > inStock && billStore?.isEditing) {
      stockColor = 'red';
    }
    if (position?.baseQuantity - currentReserve > inStockForSale && billStore?.isEditing) {
      stockColor = 'yellow';
    }
    return (
      <Grid item border={`1px solid ${noticesInfoText ? '#FFF200' : baseColor}`} borderRadius={'15px'} p={1}>
        <Grid container>
          {/* Резерв*/}
          {(reserveTotal >= 0 && shipmentQuantity !== baseQuantity && (
            <>
              <Grid item xs={12} mb={2}>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item xs={12}>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <Grid container alignItems={'center'}>
                          <Grid item>
                            <IconButton size={'small'} onClick={() => setSelectedAdditionalTab(selectedTabNumber)}>
                              <LockPersonRoundedIcon
                                sx={{ color: theme => (theme.palette.mode === 'dark' ? '#d8d8d8b3' : 'text.secondary') }}
                              />
                            </IconButton>
                          </Grid>
                          <Grid item ml={2}>
                            <Typography color="text.secondary" variant="caption" fontWeight={500}>
                              Резерв
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {infoText && !billStore?.isEditing && (
                        <Grid item flex={'0 0 30px'} ml={2}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            onMouseEnter={event => handleTooltipOpen(event, 'info')}
                            onMouseLeave={() => handleTooltipClose()}
                          >
                            <CheckCircleIcon sx={{ cursor: 'pointer', color: baseColor, fontSize: '21px' }} />
                            <Popper placement="top" open={tooltipName === 'info' && !!infoText} anchorEl={anchorEl} sx={{ zIndex: 1200 }}>
                              <Paper
                                sx={{
                                  padding: 1,
                                }}
                              >
                                {infoText}
                              </Paper>
                            </Popper>
                          </Box>
                        </Grid>
                      )}
                      {noticesInfoText && (
                        <Grid item flex={'0 0 30px'} ml={2}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            onMouseEnter={event => handleTooltipOpen(event, 'info')}
                            onMouseLeave={() => handleTooltipClose()}
                          >
                            <CheckCircleIcon sx={{ cursor: 'pointer', color: '#FFF200', fontSize: '21px' }} />
                            <Popper placement="top" open={tooltipName === 'info' && !!infoText} anchorEl={anchorEl} sx={{ zIndex: 1200 }}>
                              <Paper
                                sx={{
                                  padding: 1,
                                }}
                              >
                                {noticesInfoText}
                              </Paper>
                            </Popper>
                          </Box>
                        </Grid>
                      )}
                      <Grid item flex={'1 1 auto'} sx={{ textAlign: 'right' }}>
                        {isDeal ? (
                          <Typography color="text.secondary" variant="body2" fontSize={'12px'} fontWeight={500}>
                            {formatNumberByUnit(currentReserve, position.baseUnitCode)} {baseUnit} из{' '}
                            {formatNumberByUnit(dealBaseQuantity, position.baseUnitCode)} {baseUnit}
                          </Typography>
                        ) : (
                          <Typography color="text.secondary" variant="body2" fontSize={'12px'} fontWeight={500}>
                            {formatNumberByUnit(currentReserve, position.baseUnitCode)} {baseUnit} из{' '}
                            {formatNumberByUnit(reserveTotal, position.baseUnitCode)} {baseUnit}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <MxLinearProgress
                      sx={{ maxWidth: '100%', height: '5px' }}
                      color="secondary"
                      variant="determinate"
                      htmlColor={baseColor}
                      value={reserveProgress}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )) ||
            null}
          {/* Отгружено*/}
          {(isShipmentBlockVisible && (
            <>
              <Grid item xs={12} mb={1}>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      Отгружено
                    </Typography>
                  </Grid>

                  <Grid item flex={'1 1 auto'} sx={{ textAlign: 'right' }}>
                    <Typography color="text.secondary" variant="body2" fontSize={'12px'} fontWeight={500}>
                      {formatNumberByUnit(shipmentQuantity, position.baseUnitCode)} {baseUnit} из{' '}
                      {formatNumberByUnit(baseQuantity, position.baseUnitCode)} {baseUnit}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mb={1}>
                <MxLinearProgress
                  sx={{ maxWidth: '100%', height: '5px', p: 0 }}
                  color="secondary"
                  variant="determinate"
                  htmlColor={'#3F48CC'}
                  value={
                    Math.round((shipmentQuantity / baseQuantity) * 100) > 100 ? 100 : Math.round((shipmentQuantity / baseQuantity) * 100)
                  }
                />
              </Grid>
            </>
          )) ||
            null}
          {/* Ожидание резерва*/}
          {(hasExpectationReserve && (
            <Grid item xs={12} mb={1}>
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography color="text.secondary" variant="caption" fontWeight={500}>
                    Ожидание резерва
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography color="text.secondary" variant="body2" fontSize={'12px'} fontWeight={500}>
                    {formatNumberByUnit(inAwaitingQuantity, position.baseUnitCode)} {baseUnit}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )) ||
            null}
          {/* Остаток*/}
          {(showBalance && (
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography color="text.secondary" variant="caption" fontWeight={500}>
                    Остаток
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    backgroundColor: multicoloredPopoverBg(stockColor ? stockColor : colorForStock),
                    border: 'none',
                    px: 0.5,
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="body2" fontSize={'12px'} fontWeight={500}>
                    {titleForStock}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )) ||
            null}
          {/* В пути*/}
          {((position.product.inTransitQuantity || position.product.distributedQuantity) && isBalanceShown && (
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography color="text.secondary" variant="caption" fontWeight={500}>
                    В пути
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body2" fontSize={'12px'} fontWeight={500}>
                    {`${formatNumberByUnit(position.product.inTransitQuantity, position.baseUnitCode)} (${formatNumberByUnit(
                      position.product.distributedQuantity,
                      position.baseUnitCode
                    )})`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )) ||
            null}
        </Grid>
      </Grid>
    );
  }
);
