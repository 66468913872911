import { makeAutoObservable, runInAction } from 'mobx';
import { v4 } from 'uuid';
import { DevPanelStore } from './DevPanelStore';
import { RootStore } from '../StoreManager';
import { EventManager, IncomeMessage, SubscribeCallback, Subscribed } from './EventManager';
import { setAxiosTabGuidHeaders } from 'src/utils/axiosInit';
import { ApiStore } from './ApiStore';

export const MsgType = {
  // для подписки на все сообщения
  WILDCARD: '*',

  // не удалось разобрать сообщение
  WEB_SOCKET_INVALID: 'ws.invalid',

  // Тип сообщения не указан
  WEB_SOCKET_UNKNOWN: 'ws.unknown',

  // соединение установлено
  WEB_SOCKET_OPEN_BACK: 'ws.open.back',

  // соединение закрыто
  WEB_SOCKET_CLOSED_BACK: 'ws.closed.back',

  // Установлено соединение с серверной частью фронтенда
  WEB_SOCKET_F1_OPEN: 'ws.f1.open',
  // Закрыто соединение с серверной частью фронтенда
  WEB_SOCKET_F1_CLOSED: 'ws.f1.closed',

  // Команда от клиента - написать сообщение
  WEB_SOCKET_CHAT_WRITE_MESSAGE: 'ws.chat.write-message',

  // Уведомление от сервера - сообщение написано.
  WEB_SOCKET_CHAT_MESSAGE_WROTE: 'ws.chat.message-wrote',

  // авторизация устройства в чате
  WEB_SOCKET_CHAT_DEVICE_AUTH: 'ws.chat.device-auth',

  // показать пользователю всплывающее сообщение об успехе
  WEB_SOCKET_SNACKBAR_SUCCESS: 'ws.snackbar.success',

  // показать пользователю всплывающее сообщение с ошибкой
  WEB_SOCKET_SNACKBAR_ERROR: 'ws.snackbar.error',

  // создан запрос на согласование
  WEB_SOCKET_DEAL_APPROVAL_ASK: 'ws.deal.approval.ask',

  // заявка согласована
  WEB_SOCKET_DEAL_APPROVAL_ACCEPTED: 'ws.deal.approval.accepted',

  // заявка отклонена
  WEB_SOCKET_DEAL_APPROVAL_DECLINED: 'ws.deal.approval.declined',

  // отозван запрос на согласование
  WEB_SOCKET_DEAL_APPROVAL_ASK_WITHDRAW: 'ws.deal.approval.withdraw',

  // Ответ на запрос обновления кредитного состояния
  WS_REST_SHOP_POST_WS_CLIENTS_CREDIT_STATE_SYNC_RESPONSE: 'ws.rest.shop.post.ws--clients--credit--state--sync.response',

  // Возможно изменилось состояние согласования по заявке
  SHOP_FRONT_DEAL_POSSIBLE_APPROVAL_CHANGED: 'shop.front.deal.possible.approval.changed',

  // Добавлена позиция в сделку
  SHOP_FRONT_DEAL_PRODUCT_ADDED: 'shop.front.deal.product.added',

  // Возможно изменилось состояние согласования по соглашению
  SHOP_FRONT_AGREEMENT_POSSIBLE_APPROVAL_CHANGED: 'shop.front.agreement.possible.approval.changed',
  // Добавлена позиция в соглашение
  SHOP_FRONT_AGREEMENT_PRODUCT_ADDED: 'shop.front.agreement.product.added',
  // Добавлена позиция во фриз
  SHOP_FRONT_FREEZE_PRODUCT_ADDED: 'shop.front.freeze.product.added',

  // создан запрос на согласование (соглашения)
  WEB_SOCKET_AGREEMENT_APPROVAL_ASK: 'ws.agreement.approval.ask',

  // заявка согласована (соглашения)
  WEB_SOCKET_AGREEMENT_APPROVAL_ACCEPTED: 'ws.agreement.approval.approved', // 'ws.agreement.approval.accepted',

  // заявка отклонена (соглашения)
  WEB_SOCKET_AGREEMENT_APPROVAL_DECLINED: 'ws.agreement.approval.declined',

  // отозван запрос на согласование соглашения
  WEB_SOCKET_AGREEMENT_APPROVAL_ASK_WITHDRAW: 'ws.agreement.approval.withdraw',

  // Добавили таску следует обновить текущие таски
  WEB_SOCKET_TASKS_REFRESH: 'ws.tasks.refresh',

  // подписаться на событие обновления сущностей (счета например)
  WEB_ENTITY_TOUCH_UPDATE_SUBSCRIPTIONS: 'ws.entity-touch.update-subscriptions',

  // Получение события об изменении сущности (счета) на фронте
  WEB_ENTITY_TOUCH_TOUCH: 'ws.entity-touch.touch',
};

interface WebConfigProvider {
  apiWsUrl?: string;
  apiWsEnabled?: boolean;
}

/**
 * Стор вебсокетов и менеджер событий.
 */
export class WebSocketStore {
  conn: WebSocket;
  devPanel: DevPanelStore;
  apiStore: ApiStore;
  eventMgr: EventManager;
  webConfig: WebConfigProvider;

  /**
   * Время в мс для переподключения после закрытия соединения
   */
  reconnectDefaultInterval = 5000;
  reconnectTimeout: NodeJS.Timeout;

  lastConnectTime?: Date;

  /**
   * Номер текущего соединения.
   * По сути количество переподключений.
   */
  lastConnectNumber = 0;
  tabGuid = null; // используется для синхронизации запросов http и websocket чтобы определить сессию (и вкладку с которой шли запросы)
  isConnected = false;

  constructor(rootStore: RootStore) {
    this.devPanel = rootStore.getDevPanel();
    this.apiStore = rootStore.getApiStore();
    this.eventMgr = rootStore.getEventManager();
    this.tabGuid = v4();
    this.webConfig = rootStore.getWebConfig();
    makeAutoObservable(this, {
      conn: false,
      devPanel: false,
      eventMgr: false,
      reconnectTimeout: false,
    });
    if (typeof window !== 'undefined') {
      this.reconnect(1000);
    }
  }

  /**
   * Устанавливает соединение, если оно еще не установлено
   */
  connect(): void {
    if (this.conn) {
      return;
    }
    if (this.webConfig.apiWsEnabled === false) {
      // Специально отключено, так задается в тестах.
      return;
    }
    if (!this.webConfig.apiWsEnabled) {
      console.log('WebSocket disabled by config');
      return;
    }
    if (typeof window === 'undefined') {
      console.log('WebSocket disabled while no window');
      this.reconnect(this.reconnectDefaultInterval);
      return;
    }
    if (!window['WebSocket']) {
      console.log('WebSocket no supported by browser');
      return;
    }
    let wsUrl = this.webConfig.apiWsUrl;
    if (!wsUrl) {
      console.error('WebSocket url not defined');
      return;
    }
    if (wsUrl.indexOf('/') === 0) {
      if (window.location.protocol === 'https:') {
        wsUrl = 'wss://' + window.location.host + wsUrl;
      } else {
        wsUrl = 'ws://' + window.location.host + wsUrl;
      }
    }

    const conn = new WebSocket(wsUrl);
    this.conn = conn;

    conn.onopen = (): void => {
      runInAction(() => {
        this.isConnected = true;
        this.lastConnectTime = new Date();
        this.lastConnectNumber++;
        this.eventMgr.processMessages(<IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_OPEN_BACK,
          data: {
            connectNumber: this.lastConnectNumber,
          },
        });
      });
      this.send(MsgType.WEB_SOCKET_CHAT_DEVICE_AUTH, {
        token: getAuthToken(),
        tabGUID: this.tabGuid,
      });
      setAxiosTabGuidHeaders(this.tabGuid, this.apiStore.axios());
    };

    conn.onclose = (ev: CloseEvent): void => {
      runInAction(() => {
        this.isConnected = false;
        this.eventMgr.processMessages(<IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_CLOSED_BACK,
          data: {
            code: ev.code,
            reason: ev.reason,
            wasClean: ev.wasClean,
            bubbles: ev.bubbles,
          },
        });
        if (this.conn === conn) {
          this.reconnect(this.reconnectDefaultInterval);
        }
      });
    };

    conn.onmessage = (evt: MessageEvent): void => {
      const messages = evt.data.split('\n');
      const parsed = new Array<IncomeMessage>();
      for (let i = 0; i < messages.length; i++) {
        const msg = <IncomeMessage>{
          msgType: MsgType.WEB_SOCKET_INVALID,
          data: messages[i],
        };
        try {
          const p = JSON.parse(messages[i]);
          msg.msgType = p.msgType || MsgType.WEB_SOCKET_UNKNOWN;
          msg.data = p.data || undefined;
        } catch (e) {
          console.warn('bad web socket income json', e, messages[i]);
        }

        parsed.push(msg);
      }
      this.eventMgr.processMessages(...parsed);
    };
  }

  reconnect(delayMs?: number): void {
    if (this.conn) {
      const conn = this.conn;
      this.conn = undefined;
      if (conn.readyState !== WebSocket.CLOSED) {
        conn.close();
      }
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
    if (delayMs > 0) {
      this.reconnectTimeout = setTimeout(() => {
        this.connect();
      }, delayMs);
    } else {
      this.connect();
    }
  }

  /**
   * Отправка сообщения на сервер
   * @param msgType
   * @param data
   */
  send(msgType: string, data: {}): void {
    if (!this.conn) {
      console.error('no connection for sending msgType', msgType);
      return;
    }
    if (this.conn.readyState !== WebSocket.OPEN) {
      console.error('not connected (' + this.conn.readyState + ') for sending msgType', msgType);
      return;
    }
    this.conn.send(
      JSON.stringify({
        msgType: msgType,
        traceId: v4(),
        data: data,
      })
    );
  }

  processMessages(...messages: IncomeMessage[]): void {
    this.eventMgr.processMessages(...messages);
  }

  subscribe(msgType: string, handler: SubscribeCallback): Subscribed {
    return this.eventMgr.subscribe(msgType, handler);
  }

  unsubscribe(s: Subscribed): void {
    this.eventMgr.unsubscribe(s);
  }

  subscribeRestApiResponse(namespace: string, method: string, url: string, handler: SubscribeCallback): Subscribed {
    let path = url.toLowerCase().replaceAll('/', '-');
    path = path.replace(/^-+/, '');
    const msgType =
      'ws.rest.' +
      encodeURIComponent(namespace) +
      '.' +
      encodeURIComponent(method).toLowerCase() +
      '.' +
      encodeURIComponent(path) +
      '.response';
    return this.eventMgr.subscribe(msgType, handler);
  }

  /**
   * Запрос аналогичный HTTP GET /api/about
   */
  restApiShopAbout(): void {
    this.send('ws.rest.shop.get.about.request', undefined);
  }

  /**
   * REST запрочес через веб-сокет
   * restApiRequest('shop', 'get', '/catalog/warehouses')
   */
  restApiRequest(namespace: string, method: string, url: string, head: {}, data: {}): void {
    let path = url.toLowerCase().replaceAll('/', '-');
    path = path.replace(/^-+/, '');
    const msgType =
      'ws.rest.' +
      encodeURIComponent(namespace) +
      '.' +
      encodeURIComponent(method).toLowerCase() +
      '.' +
      encodeURIComponent(path) +
      '.request';
    this.send(msgType, data);
  }
}

const getAuthToken = (): string => {
  if (typeof document === 'undefined' || !document.cookie) {
    return '';
  }
  // TODO: переделать когда бэкенд будет поддерживать ssoToken для авторизации на вебсокете
  return document.cookie.replace(/(?:(?:^|.*;\s*)mxdvctkn\s*=\s*([^;]*).*$)|^.*$/, '$1');
};
