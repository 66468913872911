import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApproveResolutionDialog } from './ApproveResolutionDialog';
import { ApproveResolutionList } from './ApproveResolutionList';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { Control, DealApprovalActionType, DealApprovalStateType, MenuItem, StyledBadge, ErrorForButton } from '.';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ApprovalStoreInterface, ConfirmDialogContent } from '../../../store/DealItemStore';

interface ApproveResolutionButtonProps {
  approval: ApprovalStoreInterface;
  control: Control;
  isSaving?: boolean;
  isLoading?: boolean;
  menuItems: MenuItem[];
  errors: ErrorForButton[];
  size?: ButtonProps['size'];
  onExecuteByType: (type: string, comment?: string) => void;
}
const growStyle = {
  transformOrigin: 'center top',
};
export const ApproveResolutionButton: FC<ApproveResolutionButtonProps> = observer(
  ({
    approval,
    control,
    menuItems = [],
    onExecuteByType,
    size = 'small',
    errors = [],
    isSaving = false,
    isLoading = false,
  }): JSX.Element => {
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [dialogContent, setDialogContent] = useState<ConfirmDialogContent>({
      text: '',
      header: '',
      contentHeader: '',
      isShowInput: false,
      isInputRequired: false,
      action: undefined,
    });
    const [openMenu, setOpenMenu] = React.useState<boolean>(false);
    const [isLoadingButton, setIsLoadingButton] = React.useState<boolean>(false);
    useEffect(() => {
      if (isLoadingButton && !isLoading) {
        setIsLoadingButton(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const handleClickMenuItem = (action: DealApprovalActionType): void => {
      const item = menuItems.find(item => item.action === action);
      setOpenMenu(false);
      if (item?.isShowModal) {
        setDialogContent(approval.buildContentForDialog(action, item.label));
        setOpenDialog(true);
      } else {
        onExecuteByType(action);
        setIsLoadingButton(true);
      }
    };

    const handleClick = (): void => {
      if (!!menuItems.length) {
        setOpenMenu(prevOpen => !prevOpen);
      } else {
        setDialogContent(approval.buildContentForDialog(control.action, control.label));
        setOpenDialog(true);
      }
    };

    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const handleCloseDialog = (isOk = false, action: DealApprovalStateType, newValue?: string): void => {
      setOpenDialog(false);
      if (isOk) {
        setOpenMenu(false);
        onExecuteByType(action, newValue);
        setIsLoadingButton(true);
      }
    };
    const handleCloseMenu = useCallback((): void => {
      setOpenMenu(false);
    }, [setOpenMenu]);
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openMenu);
    React.useEffect(() => {
      if (prevOpen.current === true && openMenu === false) {
        anchorRef.current?.focus();
      }
      prevOpen.current = openMenu;
    }, [openMenu]);

    return (
      <Box display="flex" alignItems="center" title={errors.map((i, index) => `${index + 1}. ${i.text}`).join('\n')}>
        <StyledBadge variant="dot" invisible={!approval.approval?.resolutionComment}>
          <LoadingButton
            ref={anchorRef}
            sx={{
              width: '100%',
              ...((isSaving || isLoading) && {
                pointerEvents: 'none',
              }),
            }} // *блокируем кнопку (стиль кнопки не меняется, но взаимодействовать с ней нельзя) когда заявка обновляется не из за кнопки
            loading={isLoadingButton}
            disabled={errors.length > 0}
            size={size}
            startIcon={control.icon}
            color={control.color}
            loadingPosition="start"
            // endIcon={<KeyboardArrowDownIcon />}
            endIcon={menuItems.length > 0 ? <KeyboardArrowDownIcon /> : undefined}
            variant={control.variant}
            onClick={handleClick}
          >
            {control.label}
          </LoadingButton>
        </StyledBadge>
        {!!menuItems.length && (
          <Popper open={openMenu} anchorEl={anchorRef.current} placement="bottom" transition>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={growStyle}>
                <Paper elevation={3}>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <ApproveResolutionList
                      handleClickMenuItem={handleClickMenuItem}
                      approval={approval}
                      control={control}
                      menuItems={menuItems}
                    />
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        <ApproveResolutionDialog
          keepMounted
          open={openDialog}
          dialogHeader={dialogContent.header}
          dialogContentText={dialogContent.text}
          dialogContentHeader={dialogContent.contentHeader}
          action={dialogContent.action}
          isShowInputComment={dialogContent.isShowInput}
          isInputCommentRequired={dialogContent.isInputRequired}
          onClose={handleCloseDialog}
          defaultCommentValue={approval.approval?.resolutionComment}
        />
      </Box>
    );
  }
);
