import React, { FC, JSX, useCallback, useState } from 'react';
import { Employee, SaleShipment } from 'src/api/marketx';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatNumber3, formatNumberByUnit, formatPriceCur, pluralRus, toFloat } from '@mx-ui/helpers';
import { formatDateSwagger, formatDateSwaggerZ, formatYmdHiLZ } from '@mx-ui/helpers';
import { MxLabel } from '@mx-ui/ui';
import { getMarginalityColorClass } from '../../views/clients/lib';
import { renderPriceCur } from 'src/utils/renderFormat';
import LightTooltip from '../ToolTip/LightTooltip';
import { ThreadLink } from '../Bills/ThreadLink';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CollapseButton } from '../../views/deals/components/CollapseButton';
import { observer } from 'mobx-react-lite';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import { CertificateBatchModal } from './CertificateBatchModal';
import { formatValueCurPayment } from '../../views/report/payment/PaymentTabPanelClients';
import { paymentStateColor } from '../Bills/BillListItem';
import { ChipTitleStyled } from '../../views/deals/DealPosition/DealPositionPrices';
import { ProductLinkBtn } from '../Product/ProductLinkBtn/ProductLinkBtn';
import { AttentionBlock } from '../Clients/AttentionBlock/AttentionBlock';
import { PositionMotivationTotal } from '../Deals/Motivation/PositionMotivationTotal';
import { LabelNonResident } from 'src/views/clients/components/CompanyExtInfo';
import { ShipmentsListStore } from '../../store/Documents/ShipmentsListStore';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuthStore } from '../../store/MobxStoreProvider';
import { Link } from '@mx-ui/ui';

interface ShipmentsListItemProps {
  shipment: SaleShipment;
  shipmentsListStore?: ShipmentsListStore;
}
// eslint-disable-next-line complexity
export const ShipmentsListItem: FC<ShipmentsListItemProps> = observer(({ shipment, shipmentsListStore }): JSX.Element => {
  const authStore = useAuthStore();
  const currentRole = authStore.getDefaultDealsEmployeeFilter();
  const matchesMd = useMediaQuery('(min-width:900px)');
  const matchesXs = useMediaQuery('(min-width:450px)');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [certificateParams, setCertificateParams] = useState({ code: '', shipmentCode: '', number: '' });
  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };
  const handleModalOpen = (code: string, shipmentCode: string, number: string): void => {
    setCertificateParams({ code, shipmentCode, number });
    setIsModalOpen(true);
  };
  const [expandAll, setExpandAll] = useState([]);
  const { distributorEmployee, warehouse, motivationEmployee } = shipment;
  const newSet = new Set();
  shipment.positions.map(position => {
    if (Boolean(position.certificateName)) {
      newSet.add(position.card);
    }
  });
  const certificates = Array.from(newSet.values()) as string[];
  const allAccordionIndexes = shipment?.positions.map((item, index) => `panel${index}`) || [];
  const handleCloseAllPositions = (): void => {
    setExpandAll(prevState => {
      if (prevState.length) {
        return [];
      }
      return allAccordionIndexes;
    });
  };
  const handleAccordionChange = (panelIndex: string): void => {
    setExpandAll(prevState => {
      const position = prevState.indexOf(panelIndex);
      if (position === -1) {
        return [...prevState, panelIndex];
      }
      return prevState.filter((item, index) => index !== position);
    });
  };

  const handleEmployeeSetChange = useCallback(
    (emp?: Employee) => {
      shipmentsListStore.employeeSetChange(currentRole, emp);
    },
    [shipmentsListStore]
  );

  const handleCustomerChange = useCallback(
    (customerCode?: string) => {
      if (shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      shipmentsListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
    },
    [shipmentsListStore]
  );

  const handleCustomerRemoveChange = useCallback(
    (customerCode?: string) => {
      if (!shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      const newCustomerCodes = shipmentsListStore.request?.customerCodes?.filter(i => i !== customerCode);
      shipmentsListStore.mergeRequest({ customerCodes: newCustomerCodes });
    },
    [shipmentsListStore]
  );

  const handleWarehouseChange = useCallback(
    (warehousesCode?: string) => {
      if (shipmentsListStore.request?.warehousesCodes?.includes(warehousesCode)) {
        return;
      }
      shipmentsListStore.mergeRequest({ warehousesCodes: warehousesCode ? [warehousesCode] : undefined });
    },
    [shipmentsListStore]
  );

  const handleWarehouseRemoveChange = useCallback(
    (warehousesCode?: string) => {
      if (!shipmentsListStore.request?.warehousesCodes?.includes(warehousesCode)) {
        return;
      }
      const newCustomerCodes = shipmentsListStore.request?.warehousesCodes?.filter(i => i !== warehousesCode);
      shipmentsListStore.mergeRequest({ warehousesCodes: newCustomerCodes });
    },
    [shipmentsListStore]
  );

  const handleChangeFilterByPaymentStatus = useCallback(
    (status?: string) => {
      if (shipmentsListStore.request?.paymentStateCodes?.includes(status)) {
        return;
      }
      shipmentsListStore.mergeRequest({ paymentStateCodes: [status] });
    },
    [shipmentsListStore]
  );

  const handleRemovePaymentState = useCallback(
    (status?: string) => {
      if (!shipmentsListStore.request?.paymentStateCodes?.includes(status)) {
        return;
      }
      const newStatusCodes = shipmentsListStore.request?.paymentStateCodes?.filter(i => i !== status);
      shipmentsListStore.mergeRequest({ paymentStateCodes: newStatusCodes });
    },
    [shipmentsListStore]
  );

  return (
    <Paper
      key={shipment.shipmentCode}
      elevation={3}
      sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)' }}
      className="withCustomScroll"
    >
      <Grid container p={1} justifyContent="space-between">
        <Grid item md={5} xs={12}>
          <Grid container justifyContent="space-between" mb={1}>
            <Grid container alignItems="center">
              <Grid item mr={1}>
                <CardTravelOutlinedIcon fontSize="medium" color="primary" />
              </Grid>
              <Grid item>
                <Link href={`/app/shipments/${encodeURIComponent(shipment.documentNumber)}`} color="primary" sx={{ fontWeight: 600 }}>
                  {`${shipment.documentNumber}${matchesXs ? ' от ' + formatDateSwagger(shipment.documentDate) : ''}`}
                </Link>
              </Grid>

              <Grid item>
                <ThreadLink color="primary" link={`/app/thread/shipments/${encodeURIComponent(shipment.documentNumber)}`} />
              </Grid>
            </Grid>
          </Grid>
          {Boolean(shipment?.recipientCustomer) && (
            <Grid container mb={2} flexDirection="column">
              <Grid item>
                <Typography variant="body2">ИНН {shipment.recipientCustomer.inn || '—'}</Typography>
              </Grid>
              <Grid
                item
                sx={{ maxWidth: matchesXs ? 'auto' : '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                container
                alignItems={'center'}
                flexWrap={'nowrap'}
              >
                {!!shipment.recipientCustomer?.nonResident && (
                  <Grid item mr={1}>
                    <LabelNonResident />
                  </Grid>
                )}
                {!!shipment.recipientCustomer?.attentions?.length && (
                  <Grid item mr={1}>
                    <AttentionBlock attentions={shipment.recipientCustomer?.attentions} />
                  </Grid>
                )}
                <Grid item>
                  <Link
                    href={`/app/clients/${encodeURIComponent(shipment.recipientCustomer.code)}`}
                    color="text.secondary"
                    sx={{ fontSize: 15, fontWeight: 600 }}
                  >
                    {shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}
                  </Link>
                </Grid>
                {shipmentsListStore && shipment.recipientCustomer.code ? (
                  shipmentsListStore.request?.customerCodes?.includes(shipment.recipientCustomer.code) ? (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerRemoveChange(shipment.recipientCustomer.code)}
                        title={`Убрать фильтрацию по ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleCustomerChange(shipment.recipientCustomer.code)}
                        title={`Найти отгрузки ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </Grid>
            </Grid>
          )}
          <Grid container mb={1} justifyContent="space-between">
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight={600} color="text.secondary">
                Создан
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight={600} color="text.secondary">
                {formatDateSwaggerZ(shipment.documentDate)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container mb={2} flexDirection="column">
            {Boolean(warehouse) && (
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Склад
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container alignItems={'center'}>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {warehouse.name}
                      </Typography>
                    </Grid>
                    {shipmentsListStore && warehouse.code ? (
                      shipmentsListStore.request?.warehousesCodes?.includes(warehouse.code) ? (
                        <Grid item ml={1}>
                          <IconButton
                            size={'small'}
                            onClick={() => handleWarehouseRemoveChange(warehouse.code)}
                            title={`Убрать фильтрацию по складу ${warehouse.name}`}
                          >
                            <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid item ml={1}>
                          <IconButton
                            size={'small'}
                            onClick={() => handleWarehouseChange(warehouse.code)}
                            title={`Найти отгрузки по складу ${warehouse.name}`}
                          >
                            <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Grid>
                      )
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container mb={1} justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Получатель
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  {shipment.recipientCustomer?.shortTitle || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mb={1} justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Отгружен
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  {shipment.shipmentDate} {shipment.shipmentTime}
                </Typography>
              </Grid>
            </Grid>

            {distributorEmployee && (
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Реализатор
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      {distributorEmployee.surname || ''} {distributorEmployee.name || ''} {distributorEmployee.patronymic || ''}
                    </Typography>
                    {shipmentsListStore ? (
                      shipmentsListStore.request?.employeeSetCode === `~emp~2~${distributorEmployee?.code}` ? (
                        <Box>
                          <IconButton
                            size={'small'}
                            onClick={() => handleEmployeeSetChange()}
                            title={`Убрать фильтрацию по ${distributorEmployee.surname || ''} ${distributorEmployee.name || ''} ${
                              distributorEmployee.patronymic || ''
                            }`}
                          >
                            <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box>
                          <IconButton
                            size={'small'}
                            onClick={() => handleEmployeeSetChange(distributorEmployee)}
                            title={`Найти отгрузки ${distributorEmployee.surname || ''} ${distributorEmployee.name || ''} ${
                              distributorEmployee.patronymic || ''
                            }`}
                          >
                            <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      )
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {Boolean(motivationEmployee) && (
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Реализатор по замещению
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    {motivationEmployee.surname || ''} {motivationEmployee.name || ''} {motivationEmployee.patronymic || ''}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {shipment.bbNumber && (
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Номер УПД
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    {shipment.bbNumber}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container mb={1} justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight={500} color="text.secondary">
                  Заявка
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {shipment.dealCode ? (
                  <Grid container alignItems="center" position="relative">
                    <Grid item position="absolute" sx={{ left: '-35px', top: '50%', transform: 'translateY(-50%)' }}>
                      <ThreadLink link={`/app/thread/deals/${encodeURIComponent(shipment.dealCode)}`} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        <Link href={`/app/deals/${shipment.dealCode}`} color="inherit" title="Перейти к заявке">
                          {shipment.dealCode}
                        </Link>
                        <span>{` от`}</span> {formatYmdHiLZ(shipment.dealCreatedAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    -
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight={500} color="text.secondary">
                  Счет №
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {shipment.billDocumentNumber ? (
                  <Grid container alignItems="center" flexWrap="nowrap" position="relative">
                    <Grid position="absolute" sx={{ left: '-35px', top: '50%', transform: 'translateY(-50%)' }}>
                      <ThreadLink link={`/app/thread/bills/${encodeURIComponent(shipment.billDocumentNumber)}`} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        <Link
                          href={`/app/bills/${encodeURIComponent(shipment.billDocumentNumber)}`}
                          color="inherit"
                          title="Перейти к счету"
                        >
                          {shipment.billDocumentNumber}
                        </Link>
                        <span>{` от`}</span> {formatYmdHiLZ(shipment.billDocumentDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    -
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={7} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container mb={2} flexDirection="column">
                <Grid container justifyContent="space-between" mb={2}>
                  <Grid item xs={6}>
                    <Typography fontWeight={700} color="textPrimary">
                      Сумма отгрузки
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={700} color="textPrimary">
                      {renderPriceCur(
                        shipment.positions?.reduce((acc, p) => acc + p.totalCost, 0),
                        ''
                      )}{' '}
                      ₽
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Общий вес
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      {formatNumber3(shipment.positions?.reduce((acc, p) => acc + p.quantityTne, 0))} т
                    </Typography>
                  </Grid>
                </Grid>
                {certificates.length > 0 && (
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Сертификаты
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {certificates.map((certificate, index) => (
                        <Box key={index.toString()} component="div" sx={{ mr: 1, display: 'inline-block' }} title={'Скачать сертификат'}>
                          <Typography variant="body2" color="text.secondary">
                            <Link
                              color="inherit"
                              title="Перейти к партии"
                              onClick={() => handleModalOpen(certificate as string, '', certificate as string)}
                              sx={{ fontSize: 14 }}
                            >
                              {certificate}
                            </Link>
                          </Typography>
                        </Box>
                      ))}
                      {certificates?.length > 1 && (
                        <Box component="div" sx={{ mr: 1, display: 'inline-block' }}>
                          <Link
                            color="text.secondary"
                            title="Перейти к партии"
                            onClick={() => handleModalOpen('', shipment.shipmentCode, '')}
                            sx={{ fontSize: 14 }}
                          >
                            Загрузить все
                          </Link>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                )}
                {shipment.paymentSummary && (
                  <>
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Статус оплаты
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems={'center'}>
                          <MxLabel color={paymentStateColor(shipment.paymentSummary?.paymentStateCode)}>
                            {shipment.paymentSummary?.paymentStateTitle}
                          </MxLabel>
                          {shipmentsListStore ? (
                            shipmentsListStore.request?.paymentStateCodes?.includes(shipment.paymentSummary?.paymentStateCode) ? (
                              <Box>
                                <IconButton
                                  size={'small'}
                                  onClick={() => handleRemovePaymentState(shipment.paymentSummary?.paymentStateCode)}
                                  title={`Убрать фильтрацию по статусу отгрузки ${shipment.paymentSummary?.paymentStateTitle || ''}`}
                                >
                                  <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box>
                                <IconButton
                                  size={'small'}
                                  onClick={() => handleChangeFilterByPaymentStatus(shipment.paymentSummary?.paymentStateCode)}
                                  title={`Найти отгрузки по статусу оплаты ${shipment.paymentSummary?.paymentStateTitle || ''}`}
                                >
                                  <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </Box>
                            )
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          ДЗ
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          {formatValueCurPayment(shipment.paymentSummary?.receivablesAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Оплачено
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          {formatValueCurPayment(shipment.paymentSummary?.paidAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Просрочено
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color={shipment?.paymentSummary?.overdueReceivablesAmount ? 'red' : 'text.secondary'}>
                          {formatValueCurPayment(shipment?.paymentSummary?.overdueReceivablesAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {shipment?.paymentSummary?.daysOverdue ? (
                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Просрочено дней
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="red">
                            {shipment?.paymentSummary?.daysOverdue}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                  </>
                )}

                {(shipment.billDelayDays > 0 || shipment.billOverdueSum > 0) && (
                  <>
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Срок отсрочки
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          {shipment.billDelayDays || 0} {pluralRus(shipment.billDelayDays || 0, ['день', 'дня', 'дней'])}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          Просрочено
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          {renderPriceCur(shipment.billOverdueSum)}
                          {shipment?.billOverdueDays > 0
                            ? ` (${shipment.billOverdueDays} ${pluralRus(shipment.billOverdueDays || 0, ['день', 'дня', 'дней'])})`
                            : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {(shipment.motivation && Object.keys(shipment?.motivation || {}).length && (
                  <Grid container mb={1} justifyContent="space-between">
                    <PositionMotivationTotal motivation={shipment.motivation} />
                  </Grid>
                )) ||
                  null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!!shipment.positions.length ? (
        matchesMd ? (
          <>
            <Grid mb={'20px'} container>
              {shipment.positions.map((position, index) => {
                return (
                  <Grid item key={index} xs={12}>
                    <Grid container px={2}>
                      <Grid item xs={12}>
                        <Grid container flexWrap={'nowrap'} alignItems={'center'} spacing={1}>
                          <Grid item>
                            <ChipTitleStyled label={`#${index + 1}`} />
                          </Grid>
                          <Grid item>
                            <Typography component="span" style={{ fontSize: '11px' }}>
                              {`${position.product.code || ''} `}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Link href={`/app/product/${encodeURIComponent(position.productCode)}`} color="inherit" sx={{ fontSize: 14 }}>
                              {position.title || ''}
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justifyContent="space-between">
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>МРЦ</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatPriceCur(position.minRetailUnitCost)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Цена</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatPriceCur(position.unitCost)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Вес</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatNumber3(position.quantityTne)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Количество</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatNumberByUnit(position.quantity, position.unitCode)} {position.unitShortName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Сумма</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatPriceCur(position.totalCost)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Услуги</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatPriceCur(position.serviceCost)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Комп., ₽</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              {position.illiquidDiscountAbs > 0 || position.todhDiscountAbs > 0 || position.selfPurchaseAward > 0 ? (
                                <LightTooltip
                                  arrow
                                  placement="bottom"
                                  TransitionComponent={Fade}
                                  title={
                                    <Box p={2}>
                                      <Typography fontSize={'12px'} color="text.secondary">
                                        <div>Партия {position.card}</div>
                                        <div>
                                          {Boolean(position.illiquidDefectCode) ? position.illiquidDefectCode : '–'}{' '}
                                          {Boolean(position.illiquidRustCode) ? position.illiquidRustCode : '–'} {position.todhText || '–'}{' '}
                                          {Boolean(position.selfPurchaseAward) ? 'СЗ' : '–'}
                                        </div>

                                        {position.illiquidDiscountPct > 0 || position.todhDiscountPct > 0 ? (
                                          <div>
                                            <span>Скидка МРЦ</span>
                                            {position.illiquidDiscountPct > 0 ? '-' + position.illiquidDiscountPct + '%' : ''}
                                            {position.todhDiscountPct > 0 ? '-' + position.todhDiscountPct + '%' : ''}
                                          </div>
                                        ) : undefined}

                                        {position.selfPurchaseAwardSum > 0 ? (
                                          <div>
                                            <span>Компенсация СЗ: {formatPriceCur(position.selfPurchaseAwardSum)}</span>
                                          </div>
                                        ) : undefined}
                                      </Typography>
                                    </Box>
                                  }
                                >
                                  <Typography fontSize={'12px'} color="text.secondary">
                                    <span>
                                      {formatPriceCur(
                                        toFloat(position.illiquidDiscountAbs) +
                                          toFloat(position.todhDiscountAbs) +
                                          toFloat(position.selfPurchaseAwardSum)
                                      )}
                                    </span>
                                  </Typography>
                                </LightTooltip>
                              ) : (
                                '-'
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Марж., ₽</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {formatPriceCur(position.totalCostMargin)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Марж., %</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                <MxLabel
                                  style={{ fontSize: '14px', display: 'inline', paddingTop: '4px' }}
                                  color={getMarginalityColorClass(position.marginalityPct)}
                                >
                                  {formatPriceCur(position?.marginalityPct || 0, '%')}
                                </MxLabel>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Партия</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap" alignItems="center">
                              {Boolean(position.card) ? (
                                <>
                                  <Grid item>
                                    <Typography variant="body2" color="text.secondary">
                                      <Link
                                        href={`/app/batches/${position.card}`}
                                        color="inherit"
                                        title="Перейти к партии"
                                        sx={{ fontSize: 12 }}
                                      >
                                        {position.card}
                                      </Link>
                                    </Typography>
                                  </Grid>
                                  <Grid item ml={0.5} title={'Скачать сертификат'}>
                                    <IconButton
                                      disabled={!Boolean(position.certificateName)}
                                      onClick={() => handleModalOpen(position.card, '', position.card)}
                                    >
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ opacity: !Boolean(position.certificateName) ? 0.4 : 1 }}
                                      >
                                        <FileOpenOutlinedIcon fontSize={'small'} sx={{ cursor: 'pointer' }} />
                                      </Typography>
                                    </IconButton>
                                  </Grid>
                                </>
                              ) : (
                                <Typography fontSize={'12px'} color="text.secondary">
                                  -
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Пачка</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {position?.packNumber || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>Кассета</Typography>
                            </Grid>
                            <Grid item container flexWrap="nowrap">
                              <Typography fontSize={'12px'} color="text.secondary">
                                {position?.cassette || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {index !== shipment.positions.length - 1 && <Divider />}
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : (
          <Grid mb={'20px'}>
            <Grid container justifyContent="flex-end" pr={2} mb={1}>
              <CollapseButton color={'#757575'} type={expandAll.length ? 'expand-all' : 'less-all'} onClick={handleCloseAllPositions} />
            </Grid>
            {shipment.positions.map((position, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={expandAll.includes(`panel${index}`)}
                  onChange={() => handleAccordionChange(`panel${index}`)}
                  sx={{
                    margin: '0px !important',
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
                    ':before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
                    <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
                      <Grid item alignItems="center">
                        <Typography component="span" style={{ fontSize: '11px' }}>
                          {`${position.product.code || ''} `}
                        </Typography>
                        <Typography component="span" fontSize={'14px'}>
                          {position.title || ''}
                        </Typography>
                        <ProductLinkBtn productCode={position.productCode} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>МРЦ</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatPriceCur(position.minRetailUnitCost)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Цена</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatPriceCur(position.unitCost)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Вес</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatNumber3(position.quantityTne)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Количество</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatNumberByUnit(position.quantity, position.unitCode)} {position.unitShortName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Сумма</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatPriceCur(position.totalCost)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Комп., ₽</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          {position.illiquidDiscountAbs > 0 || position.todhDiscountAbs > 0 ? (
                            <LightTooltip
                              arrow
                              placement="bottom"
                              TransitionComponent={Fade}
                              title={
                                <Typography fontSize={'12px'} color="text.secondary">
                                  <Box p={2}>
                                    <div>Партия {position.card}</div>
                                    <div>
                                      {Boolean(position.illiquidDefectCode) ? position.illiquidDefectCode : '–'}{' '}
                                      {Boolean(position.illiquidRustCode) ? position.illiquidRustCode : '–'} {position.todhText || '–'}
                                    </div>
                                    <div>
                                      <span>Скидка МРЦ</span>
                                      {position.illiquidDiscountPct > 0 ? '-' + position.illiquidDiscountPct + '%' : ''}
                                      {position.todhDiscountPct > 0 ? '-' + position.todhDiscountPct + '%' : ''}
                                    </div>
                                  </Box>
                                </Typography>
                              }
                            >
                              <Typography fontSize={'12px'} color="text.secondary">
                                <span>{formatPriceCur(toFloat(position.illiquidDiscountAbs) + toFloat(position.todhDiscountAbs))}</span>
                              </Typography>
                            </LightTooltip>
                          ) : (
                            '-'
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Марж., ₽</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatPriceCur(position.totalCostMargin)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Марж., %</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container flexWrap="nowrap">
                          <Typography fontSize={'12px'} color="text.secondary">
                            <MxLabel
                              style={{ fontSize: '14px', display: 'inline', paddingTop: '4px' }}
                              color={getMarginalityColorClass(position.marginalityPct)}
                            >
                              {formatPriceCur(position?.marginalityPct || 0, '%')}
                            </MxLabel>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid container my={0.5} alignItems={'center'} justifyContent="space-between">
                      <Grid item>
                        <Typography fontSize={'12px'}>Партия</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems={'center'} flexWrap="nowrap">
                          {Boolean(position.card) ? (
                            <>
                              <Grid item>
                                <Typography variant="body2" color="text.secondary">
                                  <Link href={`/app/batches/${position.card}`} color="inherit" title="Перейти к партии">
                                    {position.card}K
                                  </Link>
                                </Typography>
                              </Grid>
                              <Grid item ml={0.5} title={'Скачать сертификат'}>
                                <IconButton
                                  disabled={!Boolean(position.certificateName)}
                                  onClick={() => handleModalOpen(position.card, '', position.card)}
                                  sx={{ opacity: !Boolean(position.certificateName) ? 0.4 : 1 }}
                                >
                                  <Typography variant="body2" color="text.secondary">
                                    <FileOpenOutlinedIcon fontSize={'small'} sx={{ cursor: 'pointer' }} />
                                  </Typography>
                                </IconButton>
                              </Grid>
                            </>
                          ) : (
                            <Typography fontSize={'12px'} color="text.secondary">
                              -
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        )
      ) : (
        <Grid item xs={12} container>
          <Grid item xs={12} mb={1}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body2'}>Без позиций</Typography>
          </Grid>
        </Grid>
      )}
      <CertificateBatchModal
        batchCode={certificateParams.code}
        batchNumber={certificateParams.number}
        shipmentCode={certificateParams.shipmentCode}
        isModalOpen={isModalOpen}
        closeModal={handleModalClose}
      />
    </Paper>
  );
});
