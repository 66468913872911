import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { formatNumber3, formatNumberByUnit } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from 'src/slices/AppDeal';
import { DealItemStore } from 'src/store/DealItemStore';
import { Product, ProductItemPack } from 'src/api/marketx';
import LightTooltip from '../../../components/ToolTip/LightTooltip';
import ProductIndicators, { BatchT } from '../../../components/Product/ProductIndicators';
import { red } from '@mui/material/colors';
import { calculateBatchIndicators } from '../../../store/BatchesListStore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { Link } from '@mx-ui/ui';

interface PackByProductTableDetailsProps {
  dealStore?: DealItemStore;
  dealPosition: AppDealPosition;
  pack: ProductItemPack;
  product: Product;
}

export const PackByProductTableDetails: FC<PackByProductTableDetailsProps> = observer(
  ({ dealStore, dealPosition, pack, product }): ReactElement => {
    const [open, setOpen] = useState<boolean>(false);
    const tableCellRef = useRef();

    const handleToggleRowClick = useCallback((): void => {
      setOpen(open => !open);
    }, [setOpen]);
    const [added, setAdded] = useState<boolean>(false);
    const handleAddToCommentClick = useCallback(
      (pack: ProductItemPack): void => {
        if (dealStore) {
          dealStore.addPackToPositionComment(dealPosition, pack).then(() => {
            setAdded(true);
          });
        }
      },
      [dealStore, dealPosition]
    );
    const isShowButton = dealStore && !dealStore.isViewOnly() && !pack.batchDoNotSell;

    const inStockToTneRequired =
      pack.inStockUnitCode && pack.inStockUnitCode == product.multiplicityUnitCode && product.multiplicity && product.multiplicity !== 1;
    const inStockToTneFactor = inStockToTneRequired ? product.multiplicity : 1;
    // Есть единицы измерения, отличные от тонн.
    const hasQuantityUnit = pack.quantity > 0 && pack.quantity !== pack.weight;

    let indicatorsCosts = calculateBatchIndicators(null, pack);
    if (dealPosition) {
      indicatorsCosts = calculateBatchIndicators(dealPosition, pack);
    } else if (product) {
      indicatorsCosts = calculateBatchIndicators(product, pack);
    }

    const indicatorsBatch = useMemo<BatchT>(() => {
      return {
        todhDiscountPct: pack?.todhDiscountPct,
        todhDiscountIlliquidBlocked: pack?.todhDiscountIlliquidBlocked,
        illiquidDefectCode: pack?.illiquidDefectCode,
        illiquidDefectTitle: pack?.illiquidDefectTitle,
        illiquidRustCode: pack?.illiquidRustCode,
        illiquidRustTitle: pack?.illiquidRustTitle,
        illiquidDiscountPct: pack?.illiquidDiscountPct,
        isSafeCustody: pack?.isSafeCustody,
        selfPurchase: pack?.batchSelfPurchase,
      };
    }, [pack]);

    return (
      <>
        <TableRow
          sx={{
            cursor: 'pointer',
            '& > td': { borderBottom: 'unset' },
            backgroundColor: pack.isSafeCustody ? '#FFFBD8' : undefined,
          }}
          onClick={handleToggleRowClick}
        >
          <TableCell align="left">{pack.warehouseName || '-'}</TableCell>
          <TableCell
            align="left"
            sx={{
              backgroundColor: pack.batchDoNotSell ? red[100] : undefined,
            }}
          >
            {pack.batchNumber ? (
              <Link href={`/app/batches/${pack.batchNumber}`} color="inherit" title="Перейти к партии">
                {pack.batchNumber}
              </Link>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell align="right">{pack.number}</TableCell>
          <TableCell align="right">{pack.cassette}</TableCell>
          <TableCell align="right">
            {hasQuantityUnit ? (
              <LightTooltip
                arrow
                placement="bottom"
                TransitionComponent={Fade}
                title={<Box p={2}>{formatNumberByUnit(pack.quantity, pack.quantityUnitCode, pack.quantityUnitShort)}</Box>}
              >
                <span>{`${formatNumber3(pack.weight)} т`}</span>
              </LightTooltip>
            ) : (
              `${formatNumber3(pack.weight)} т`
            )}
          </TableCell>
          <TableCell align="right">{formatNumberByUnit(pack.inStock * inStockToTneFactor, pack.unitCode, pack.unitShortName)}</TableCell>
          <TableCell align="right">{pack.receiptDate}</TableCell>
          <TableCell align="right" ref={tableCellRef}>
            <ProductIndicators batch={indicatorsBatch} tableCellRef={tableCellRef} costs={indicatorsCosts} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <Collapse in={open} unmountOnExit>
              <Box pt={1} pb={1} pl={2} display="flex">
                <Box width={'70%'}>
                  <Box display={'flex'}>
                    <Box width={'50%'}>Сертификат</Box>
                    <Box>{pack.numberInCertificate ?? '-'}</Box>
                  </Box>
                  <Box display={'flex'}>
                    <Box width={'50%'}>Упаковка</Box>
                    <Box>{pack.boxingNumber ?? '-'}</Box>
                  </Box>
                  <Box display={'flex'}>
                    <Box width={'50%'}>Параметр</Box>
                    <Box>{pack.parameter ?? '-'}</Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems={'flex-end'}>
                  {isShowButton && (
                    <Box>
                      {!added ? (
                        <Button
                          variant="outlined"
                          size="small"
                          title="Добавить запись с номером пачки в комментарий позиции"
                          sx={{ borderColor: '#bdbdbd' }}
                          onClick={() => handleAddToCommentClick(pack)}
                        >
                          В&nbsp;комментарий
                        </Button>
                      ) : (
                        <Typography style={{ fontSize: '12px', textAlign: 'center' }}>Добавлено</Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

export const PackByProductTableDetailsMobile: FC<PackByProductTableDetailsProps> = observer(
  ({ dealStore, dealPosition, pack, product }): ReactElement => {
    const tableCellRef = useRef();
    const [added, setAdded] = useState<boolean>(false);
    const handleAddToCommentClick = useCallback(
      (pack: ProductItemPack): void => {
        if (dealStore) {
          dealStore.addPackToPositionComment(dealPosition, pack).then(() => {
            setAdded(true);
          });
        }
      },
      [dealStore, dealPosition]
    );
    const isShowButton = dealStore && !dealStore.isViewOnly() && !pack.batchDoNotSell;

    const inStockToTneRequired =
      pack.inStockUnitCode && pack.inStockUnitCode == product.multiplicityUnitCode && product.multiplicity && product.multiplicity !== 1;
    const inStockToTneFactor = inStockToTneRequired ? product.multiplicity : 1;
    // Есть единицы измерения, отличные от тонн.
    const hasQuantityUnit = pack.quantity > 0 && pack.quantity !== pack.weight;

    let indicatorsCosts = calculateBatchIndicators(null, pack);
    if (dealPosition) {
      indicatorsCosts = calculateBatchIndicators(dealPosition, pack);
    } else if (product) {
      indicatorsCosts = calculateBatchIndicators(product, pack);
    }

    const indicatorsBatch = useMemo<BatchT>(() => {
      return {
        todhDiscountPct: pack?.todhDiscountPct,
        todhDiscountIlliquidBlocked: pack?.todhDiscountIlliquidBlocked,
        illiquidDefectCode: pack?.illiquidDefectCode,
        illiquidDefectTitle: pack?.illiquidDefectTitle,
        illiquidRustCode: pack?.illiquidRustCode,
        illiquidRustTitle: pack?.illiquidRustTitle,
        illiquidDiscountPct: pack?.illiquidDiscountPct,
        isSafeCustody: pack?.isSafeCustody,
        selfPurchase: pack?.batchSelfPurchase,
      };
    }, [pack]);
    return (
      <Accordion
        sx={{
          margin: '0px !important',
          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
          ':before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              <Typography variant={'body2'}>
                {pack.batchNumber ? (
                  <Link href={`/app/batches/${pack.batchNumber}`} color="inherit" title="Перейти к партии">
                    {pack.batchNumber}
                  </Link>
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>{pack.number}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Divider />
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Склад</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.warehouseName || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Партия</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.batchNumber ? (
                      <Link href={`/app/batches/${pack.batchNumber}`} color="inherit" title="Перейти к партии">
                        {pack.batchNumber}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Код</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.number}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Кассета</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.cassette}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Вес, т</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {hasQuantityUnit ? (
                      <LightTooltip
                        arrow
                        placement="bottom"
                        TransitionComponent={Fade}
                        title={<Box p={2}>{formatNumberByUnit(pack.quantity, pack.quantityUnitCode, pack.quantityUnitShort)}</Box>}
                      >
                        <span>{`${formatNumber3(pack.weight)} т`}</span>
                      </LightTooltip>
                    ) : (
                      `${formatNumber3(pack.weight)} т`
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Остаток</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {formatNumberByUnit(pack.inStock * inStockToTneFactor, pack.unitCode, pack.unitShortName)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Дата поступления</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.receiptDate}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Признак</Typography>
                </Grid>
                <Grid item xs={6} ref={tableCellRef}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    <ProductIndicators batch={indicatorsBatch} tableCellRef={tableCellRef} costs={indicatorsCosts} />
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Сертификат</Typography>
                </Grid>
                <Grid item xs={6} ref={tableCellRef}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.numberInCertificate ?? '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Упаковка</Typography>
                </Grid>
                <Grid item xs={6} ref={tableCellRef}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.boxingNumber ?? '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid py={0.5} container alignItems={'center'} spacing={1}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>Параметр</Typography>
                </Grid>
                <Grid item xs={6} ref={tableCellRef}>
                  <Typography variant={'body2'} textAlign={'right'}>
                    {pack.parameter ?? '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {isShowButton && (
              <Grid item xs={12} container justifyContent={'flex-end'} py={0.5}>
                <Box>
                  {!added ? (
                    <Button
                      variant="outlined"
                      size="small"
                      title="Добавить запись с номером пачки в комментарий позиции"
                      sx={{ borderColor: '#bdbdbd' }}
                      onClick={() => handleAddToCommentClick(pack)}
                    >
                      В&nbsp;комментарий
                    </Button>
                  ) : (
                    <Typography style={{ fontSize: '12px', textAlign: 'center' }}>Добавлено</Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
