import { FC } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';
import { SvgIconPropsColorOverrides } from '@mui/material';
import { Link } from '@mx-ui/ui';

interface ThreadLinkProps {
  link: string;
  color?: OverridableStringUnion<
    'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    SvgIconPropsColorOverrides
  >;
  sx?: SxProps<Theme>;
}

export const ThreadLink: FC<ThreadLinkProps> = ({ link = '', color, sx }): JSX.Element => {
  return (
    <Link href={link} noTypography>
      <IconButton size="small" sx={{ height: '28px', width: '28px', ...sx }}>
        <LinkIcon fontSize="small" color={color} />
      </IconButton>
    </Link>
  );
};
