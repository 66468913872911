import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FC, JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react-lite';
import { useWebSocketHandler } from 'src/hooks/useWebSocketHandler';
import { formatYmdHiLZ } from '@mx-ui/helpers';
import { renderPriceCur } from 'src/utils/renderFormat';
import { AddressUpdates, ClientsItemAddressesAddResponse, ClientsItemAddressesItemSaveRequest } from '../../../api/marketx';
import { AppAddressUpdates, AppDeal, AppDealDeliveryType } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import { useAuthStore, useRootStore } from '../../../store/MobxStoreProvider';
import { pluralRus } from '@mx-ui/helpers';
import { AddressEditing } from './AddressEditing';
import { DealAddressSelect } from './DealAddressSelect';
import { DealConsigneeSelect } from './DealConsigneeSelect';
import { DealManualCostsTab } from './DealManualCostsTab';
import { DeliveryInfoBlock } from './DeliveryInfoBlock';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DealSummaryTabComment } from './DealSummaryTabComment';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { CustomIconTabs, TabValue } from '../../../components/Deals/CustomIconTabs/CustomIconTabs';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { DealMarginalityChart } from './DealMarginalityChart';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { MxLabel } from '@mx-ui/ui';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import { DealSummaryPartners } from './DealSummaryPartners';

export const LoadingSummaryDeliverySkeleton: FC = (): JSX.Element => {
  const transparentIcon = <BarChartOutlinedIcon sx={{ color: 'transparent' }} />;
  const headerTabsValue = useMemo(() => {
    return [
      { label: ``, tabPanelIndex: 0, disabled: false, icon: transparentIcon, badgeOn: false },
      { label: ``, tabPanelIndex: 1, disabled: false, icon: transparentIcon, badgeOn: false },
      { label: ``, tabPanelIndex: 2, disabled: false, icon: transparentIcon, badgeOn: false },
      { label: ``, tabPanelIndex: 3, disabled: false, icon: transparentIcon, badgeOn: false },
      { label: ``, tabPanelIndex: 4, disabled: false, icon: transparentIcon, badgeOn: false },
    ];
  }, []);
  return (
    <Grid item xs={12} md={6} lg={4} container spacing={2}>
      <Grid item xs={12} height={'calc(100% - 120px)'}>
        <Paper sx={{ height: '100%', padding: 1, position: 'relative' }}>
          <Grid container>
            <Grid item xs={12} pl={'5px'} overflow={'hidden'} mb={2}>
              <CustomIconTabs handleToggleButtonChange={() => {}} toggleButtonValue={0} tabsValue={headerTabsValue} />
            </Grid>
            {[1, 2].map(i => (
              <Grid xs={12} item key={i} mb={2}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={'80%'} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton variant="text" width={'50%'} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ height: '100%', padding: 1, position: 'relative' }}>
          <Grid container>
            {[1, 2].map(i => (
              <Grid xs={12} item key={i} mb={2}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item xs={12}>
                    <Skeleton variant="text" width={'100%'} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

interface DealSummaryDeliveryProps {
  dealStore: DealItemStore;
  selectedDeliveryTab: number;
  setSelectedDeliveryTab: React.Dispatch<React.SetStateAction<number>>;
}

const creditDictionary = [
  { label: 'Сумма кредитного лимита', value: row => renderPriceCur(row.allowedSum) },
  { label: 'Свободный остаток лимита', value: row => renderPriceCur(row.availableSum) },
  {
    label: 'Действует до',
    value: row => <>{row?.validUntil ?? '-'}</>,
  },
  {
    label: 'Срок отсрочки',
    value: row => (
      <>
        {row.delayDays || 0} {pluralRus(row.delayDays || 0, ['день', 'дня', 'дней'])}
      </>
    ),
  },
  {
    label: 'Просрочено',
    color: row => (row.overdueSum > 0 ? 'red' : undefined),
    value: row => (
      <>
        {renderPriceCur(row.overdueSum)}
        {row?.overdueDays > 0 ? ` (${row.overdueDays} ${pluralRus(row.overdueDays || 0, ['день', 'дня', 'дней'])})` : ''}
      </>
    ),
  },
];

export const DealSummaryDelivery: FC<DealSummaryDeliveryProps> = observer(
  ({ dealStore, selectedDeliveryTab, setSelectedDeliveryTab }): JSX.Element => {
    const authStore = useAuthStore();
    const hasNewPartnersAccess = authStore.isNewPartnersSchemaVisible;
    // const [selectedDeliveryTab, setSelectedDeliveryTab] = useState(0);
    // изменения адреса из окна редактирования
    const [addressUpdates, setAddressUpdates] = useState({} as AppAddressUpdates);
    // в окне редактирования адреса адрес редактировался
    const [isAddressEditingChanged, setAddressEditingChanged] = useState(false);
    // открыто окно редактирования адреса
    const [addressEditingOpen, setAddressEditingOpen] = useState(false);
    // Идет ли процесс сохранения адреса
    const [inAddressSaving, setInAddressSaving] = useState(false);

    const rootStore = useRootStore();
    const apiStore = rootStore.getApiStore();
    const snackbarStore = rootStore.getSnackbar();

    const creditState = useMemo(() => {
      const store = dealStore.getCreditStateStore();
      if (dealStore.deal?.customerCode) {
        store.loadForClient(dealStore.deal.customerCode, dealStore.deal.branchOfficeCode);
      }
      return store;
    }, [rootStore, dealStore.deal?.customerCode]);

    useWebSocketHandler(creditState.handleWs, [creditState]);

    const handleConsigneeChange = useCallback(
      (consigneeCode: string | null): void => {
        dealStore.updateDeal({ consigneeCode: consigneeCode }).catch(r => console.warn('handleConsigneeChange', r));
      },
      [dealStore]
    );

    const handleAddressChange = useCallback(
      (addressCode: string | null): void => {
        dealStore.updateDeal({ addressCode: addressCode }).catch(r => console.warn('handleAddressChange', r));
      },
      [dealStore]
    );

    const handleVatRateChange = useCallback(
      (event): void => {
        setLocalVatRateValue(Number(event.target.value));
        dealStore.updateDeal({ vatRateCode: event.target.value }).catch(r => console.warn('handleDeliveryTypeChange', r));
      },
      [dealStore]
    );

    const handleClickAddressAdd = (): void => {
      setAddressEditingOpen(true);
    };

    const handleAddressEditingClose = (): void => {
      setAddressEditingOpen(false);
    };

    // Изменение в окне/форме добавления адреса
    const handleEditingAddressChange = (updates: AppAddressUpdates): void => {
      setAddressEditingChanged(true);
      setAddressUpdates(updates);
    };

    const consigneesStore = rootStore.getDealConsigneeList();
    useEffect(() => {
      consigneesStore.loadListForDeal(dealStore.deal);
    }, [dealStore.dealCode, dealStore.deal?.consigneeCode, dealStore.deal?.customerCode]);

    const isCommentBadgeVisible = dealStore?.deal?.comments?.some(item => item.comment?.length > 0);

    // Сохранить новый адрес
    const handleAddressEditingSave = useCallback((): void => {
      if (inAddressSaving) {
        console.warn('handleAddressEditingSave already in saving');
        return;
      }
      setInAddressSaving(true);
      const uuid = uuidv4();
      apiStore
        .apiClientCustomer()
        .clientsItemAddressesAdd(dealStore.deal.customerCode, uuid)
        .then((res: AxiosResponse<ClientsItemAddressesAddResponse>) => {
          const addressCode = res?.data?.code;
          if (!addressCode) {
            console.warn('clientsItemAddressesAdd returns empty code');
            setInAddressSaving(false);
            return;
          }
          apiStore
            .apiClientCustomer()
            .clientsItemAddressesItemSave(dealStore.deal.customerCode, addressCode, {
              code: addressCode,
              consigneeCode: dealStore.deal.consigneeCode,
              updates: {
                street: addressUpdates.street,
                postcode: addressUpdates.postcode,
                cityName: addressUpdates.cityName,
                cityCode: addressUpdates.cityCode,
                flatNumber: addressUpdates.flatNumber,
                houseNumber: addressUpdates.houseNumber,
                latitude: addressUpdates.latitude,
                longitude: addressUpdates.longitude,
              } as AddressUpdates,
            } as ClientsItemAddressesItemSaveRequest)
            .then(() => {
              snackbarStore.showSuccess(`Адрес сохранен`);
              setAddressEditingOpen(false);
              setInAddressSaving(false);
              rootStore.getDealAddress().reloadAddresses(dealStore.deal.customerCode, dealStore.deal.consigneeCode, 'address saved');
            })
            .catch(() => {
              setInAddressSaving(false);
              rootStore.getDealAddress().reloadAddresses(dealStore.deal.customerCode, dealStore.deal.consigneeCode, 'address save err');
            });
        })
        .catch(() => {
          setInAddressSaving(false);
        });
    }, [addressUpdates, apiStore, dealStore.deal.consigneeCode, dealStore.deal.customerCode, inAddressSaving, rootStore, snackbarStore]);

    const deal = useMemo<AppDeal>(() => {
      return dealStore.deal;
    }, [dealStore, dealStore.deal]);

    const deliveryTypesStore = dealStore.getDeliveryTypesStore();
    const [localDeliveryType, setLocalDeliveryType] = useState<AppDealDeliveryType | null>(
      deal?.deliveryType ? deliveryTypesStore?.items.find(i => i.code === deal?.deliveryType) : null
    );
    const handleDeliveryTypeChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>, value: AppDealDeliveryType): void => {
        setLocalDeliveryType(value);
        dealStore.updateDeal({ deliveryType: value.code }).catch(r => console.warn('handleDeliveryTypeChange', r));
      },
      [dealStore]
    );
    const [localVatRateValue, setLocalVatRateValue] = useState(dealStore.deal?.vatRateValue); // vatRateValue
    useEffect(() => {
      if (deal?.deliveryType === localDeliveryType?.code || !deliveryTypesStore?.items.length) {
        return;
      }
      setLocalDeliveryType(deliveryTypesStore?.items.find(i => i.code === deal?.deliveryType));
    }, [deal?.deliveryType, deliveryTypesStore?.items.length]);
    useEffect(() => {
      setLocalVatRateValue(dealStore.deal?.vatRateValue);
    }, [dealStore.deal?.vatRateValue]);
    const isManualBillValidDaysWithBadge = !!dealStore.deal?.manualBillValidDays && dealStore.deal?.manualBillValidDays !== 4;
    const isSettingsBadgeOn = useMemo(() => {
      return (
        !!dealStore.deal?.useManualDeliveryDistance ||
        !!dealStore.deal?.useManualDeliveryCost ||
        !!dealStore.deal?.useManualDeliveryUnits ||
        !!dealStore.deal?.useManualServicesCost
      );
    }, [
      dealStore.deal?.useManualDeliveryDistance,
      dealStore.deal?.useManualDeliveryCost,
      dealStore.deal?.useManualDeliveryUnits,
      dealStore.deal?.useManualServicesCost,
    ]);
    const headerTabsValue: TabValue[] = useMemo(() => {
      return [
        {
          label: ``,
          tabPanelIndex: 0,
          disabled: false,
          icon: <HubRoundedIcon />,
          badgeOn: false,
          isHide: !hasNewPartnersAccess,
        },
        { label: ``, tabPanelIndex: 1, disabled: false, icon: <LocalShippingOutlinedIcon />, badgeOn: false },
        {
          label: ``,
          tabPanelIndex: 2,
          disabled: false,
          icon: <AddLocationAltOutlinedIcon />,
          badgeOn: false,
          isHide: hasNewPartnersAccess,
        },
        {
          label: ``,
          tabPanelIndex: 3,
          disabled: false,
          icon: <PanToolOutlinedIcon />,
          badgeOn: isSettingsBadgeOn || isManualBillValidDaysWithBadge,
        },
        { label: ``, tabPanelIndex: 4, disabled: false, icon: <ChatOutlinedIcon />, badgeOn: isCommentBadgeVisible },
        { label: ``, tabPanelIndex: 5, disabled: false, icon: <InfoOutlinedIcon />, badgeOn: false },
        { label: ``, tabPanelIndex: 6, disabled: false, icon: <BarChartOutlinedIcon />, badgeOn: false },
        { label: ``, tabPanelIndex: 7, disabled: false, icon: <CurrencyRubleIcon />, badgeOn: creditState.state?.overdueSum > 0 },
      ];
    }, [isSettingsBadgeOn, isCommentBadgeVisible, creditState.state?.overdueSum, isManualBillValidDaysWithBadge]);
    const handleTabChange = useCallback(
      (event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedDeliveryTab(newValue);
      },
      [setSelectedDeliveryTab]
    );

    const minHeightForTabSections = '216px';

    if (!dealStore.isLoaded) {
      return <LoadingSummaryDeliverySkeleton />;
    }

    return (
      <Grid item md={6} lg={4} xs={12}>
        <Paper sx={{ padding: 1, position: 'relative', height: hasNewPartnersAccess ? '100%' : 'calc(100% - 61px)' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ width: '100%', overflow: 'hidden', height: '66px' }} className="withCustomScroll">
              <Grid pl={2} className="withCustomScroll" sx={{ pl: '5px', display: 'grid', gridTemplateColumns: 'minmax(300px, 1fr)' }}>
                <CustomIconTabs
                  handleToggleButtonChange={handleTabChange}
                  toggleButtonValue={selectedDeliveryTab}
                  tabsValue={headerTabsValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container pt={1}>
              {selectedDeliveryTab === 0 && <DealSummaryPartners dealStore={dealStore} minHeightForTabSections={minHeightForTabSections} />}
              {selectedDeliveryTab === 1 && (
                <TabSelectWrapper>
                  <Grid container minHeight={minHeightForTabSections}>
                    <Grid item xs={12} mb={2}>
                      {!!deal && (
                        <Autocomplete
                          value={localDeliveryType || null}
                          onChange={handleDeliveryTypeChange}
                          disablePortal
                          getOptionLabel={i => i.name}
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          disableClearable
                          size="small"
                          options={deliveryTypesStore?.items}
                          renderInput={params => <TextField {...params} label="Способ доставки" />}
                          disabled={dealStore.isViewOnly() || dealStore.isSaving}
                        />
                      )}
                    </Grid>
                    <Grid container item xs={12}>
                      <DeliveryInfoBlock deal={deal} />
                    </Grid>
                  </Grid>
                </TabSelectWrapper>
              )}
              {selectedDeliveryTab === 2 && (
                <TabSelectWrapper>
                  <Grid container spacing={0} minHeight={minHeightForTabSections}>
                    <Grid item xs={12}>
                      <DealAddressSelect
                        isViewOnly={dealStore.isViewOnly()}
                        currentCode={deal?.deliveryAddress?.code}
                        addresses={rootStore
                          .getDealAddress()
                          .getAddressesDef(
                            deal?.customerCode,
                            deal?.consigneeCode,
                            deal?.deliveryAddress?.code,
                            deal?.deliveryAddress?.addressFull
                          )}
                        onChange={handleAddressChange}
                      />
                      {!dealStore.isViewOnly() && deal?.consigneeCode && (
                        <>
                          <Grid container spacing={0} style={{ marginTop: '8px' }}>
                            <Grid item md={6}>
                              <Button size="small" variant="outlined" color="secondary" onClick={handleClickAddressAdd}>
                                Добавить адрес
                              </Button>
                            </Grid>
                            <Grid item md={6}>
                              {(deal?.warehouseAddress?.latitude && deal?.deliveryAddress?.latitude && (
                                <>
                                  <a
                                    href={
                                      'https://yandex.ru/maps/?rtext=' +
                                      deal?.warehouseAddress?.latitude +
                                      ',' +
                                      deal?.warehouseAddress?.longitude +
                                      '~' +
                                      deal?.deliveryAddress?.latitude +
                                      ',' +
                                      deal?.deliveryAddress?.longitude +
                                      '&rtt=auto'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ fontFamily: 'Roboto,Helvetica,Arial,sansSerif', fontWeight: '400', fontSize: '0.875rem' }}
                                  >
                                    Маршрут
                                  </a>
                                </>
                              )) ||
                                (deal?.deliveryAddress?.latitude && (
                                  <>
                                    <a
                                      href={
                                        'https://yandex.ru/maps/?pt=' +
                                        deal?.deliveryAddress?.latitude +
                                        ',' +
                                        deal?.deliveryAddress?.longitude +
                                        '&l=map'
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      На карте
                                    </a>
                                  </>
                                )) ||
                                ''}
                            </Grid>
                          </Grid>
                          {/* Окно подтверждения отправки */}
                          <Dialog
                            open={addressEditingOpen}
                            onClose={handleAddressEditingClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{'Добавление адреса'}</DialogTitle>

                            <DialogContent>
                              <AddressEditing
                                startLatitude={deal?.warehouseAddress?.latitude}
                                startLongitude={deal?.warehouseAddress?.longitude}
                                onChange={handleEditingAddressChange}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleAddressEditingClose}
                                color="primary"
                                disabled={!isAddressEditingChanged || inAddressSaving}
                              >
                                Отмена
                              </Button>
                              <Button
                                onClick={handleAddressEditingSave}
                                color="primary"
                                autoFocus
                                disabled={!isAddressEditingChanged || inAddressSaving}
                              >
                                Сохранить
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </TabSelectWrapper>
              )}
              {selectedDeliveryTab === 3 && <DealManualCostsTab dealStore={dealStore} minHeightForTabSections={minHeightForTabSections} />}
              {selectedDeliveryTab === 4 && <DealSummaryTabComment dealStore={dealStore} isViewOnly={dealStore.isViewOnly()} />}
              {selectedDeliveryTab === 5 && (
                <TabSelectWrapper>
                  <Grid item xs={12} container flexDirection="column" minHeight={minHeightForTabSections}>
                    <Grid container direction="row">
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          Статус
                        </Typography>
                      </Grid>
                      <Grid item xs={6} alignItems="center">
                        <MxLabel
                          style={{ display: 'inline', paddingTop: '4px' }}
                          color={
                            dealStore.deal.customer?.activityStatusCode === 'not_active'
                              ? 'error'
                              : dealStore.deal.customer?.activityStatusCode === 'approval'
                              ? 'warning'
                              : 'success'
                          }
                        >
                          {dealStore.deal.customer?.activityStatusTitle || 'Неизвестно'}
                        </MxLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          Сегмент
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          {dealStore.deal?.customer?.segmentCode || '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          Отрасль
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          {dealStore.deal?.customer?.occupationTitle || '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          Реализатор
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color={'text.secondary'}>
                          {dealStore.deal?.customer?.mainEmployee?.surname} {dealStore.deal?.customer?.mainEmployee?.name}
                          {dealStore.deal?.customer?.mainEmployeeJoinDate &&
                            `(${formatYmdHiLZ(dealStore.deal?.customer?.mainEmployeeJoinDate)})`}
                        </Typography>
                        {/* <div>*/}
                        {/*  {dealStore.deal?.customer?.mainEmployeeJoinDate &&*/}
                        {/*    `(${formatYmdHiLZ(dealStore.deal?.customer?.mainEmployeeJoinDate)})`}*/}
                        {/* </div>*/}
                      </Grid>
                    </Grid>
                  </Grid>
                </TabSelectWrapper>
              )}
              {selectedDeliveryTab === 6 && (
                <DealMarginalityChart dealStore={dealStore} minHeightForTabSections={minHeightForTabSections} />
              )}
              {selectedDeliveryTab === 7 && (
                <TabSelectWrapper>
                  <Grid item xs={12} container flexDirection="row" sx={{ height: 'calc(100% - 34px)', minHeight: minHeightForTabSections }}>
                    {creditState.isLoaded ? (
                      <>
                        <Grid item container xs={12} mb={1}>
                          <Grid container item>
                            <Grid item xs={6} alignSelf={'center'}>
                              <Typography variant="body2" color={'text.secondary'}>
                                НДС, %
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl sx={{ minWidth: 60 }} size="small">
                                <Select
                                  disabled={(dealStore.isViewOnly() || !dealStore.deal?.customer?.nonResident) ?? true}
                                  sx={{ '.MuiSelect-select': { padding: '2px 10px', fontSize: '14px' } }}
                                  value={localVatRateValue}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                  }}
                                  label=""
                                  onChange={handleVatRateChange}
                                >
                                  {deal.availableVatRates.map(availableVatRate => (
                                    <MenuItem value={availableVatRate.code} key={availableVatRate.code}>
                                      {availableVatRate.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {/* <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>0%</Grid>
                            <Grid item>
                              <Switch
                                checked={localVatRateValue}
                                disabled={(dealStore.isViewOnly() || !dealStore.deal?.customer?.nonResident) ?? true}
                                size="small"
                                onChange={handleVatRateChange}
                              />
                            </Grid>
                            <Grid item>20%</Grid>
                          </Grid> */}
                            </Grid>
                          </Grid>
                          {creditDictionary.map((row, index) => {
                            const color = !!row?.color ? row?.color(creditState.state) ?? 'text.secondary' : 'text.secondary';
                            return (
                              <Grid key={index} container item>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color={color}>
                                    {row.label}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color={color}>
                                    {row.value(creditState.state)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    ) : (
                      <Box minHeight={300} width="100%">
                        <Skeleton variant="rectangular" height={300} />
                      </Box>
                    )}
                  </Grid>
                </TabSelectWrapper>
              )}{' '}
            </Grid>
          </Grid>
        </Paper>
        {hasNewPartnersAccess ? null : (
          <Paper sx={{ padding: 1, mt: 1 }}>
            <Grid container>
              {/* Грузополучатель */}
              <Grid item xs={12}>
                <DealConsigneeSelect
                  isViewOnly={dealStore.isViewOnly()}
                  currentCode={dealStore?.deal?.consigneeCode}
                  consignees={consigneesStore}
                  onChange={handleConsigneeChange}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    );
  }
);
