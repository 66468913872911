import { RootStore } from './StoreManager';
import { makeAutoObservable, observable, runInAction, set, toJS } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { SnackbarStore } from './SnackbarStore';
import {
  BranchOffice,
  BranchOfficeMarginalityReport,
  BudgetGroup,
  Customer,
  CustomerMarginalityReport,
  DepartmentMarginalityReport,
  Employee,
  EmployeeDepartment,
  EmployeeMarginalityReport,
  EmployeesItemMotivationResponse,
  GetSalePlanCustomersHistoryResponse,
  StatMarginalityCustomersResponse,
  StatMarginalityDepartmentsResponse,
  StatMarginalityEmployeesResponse,
  StatMarginalityHeadCompaniesResponse,
  StatMarginalityOfficesResponse,
  StatMarginalityReferencesResponse,
} from 'src/api/marketx/api';
import { AxiosResponse } from 'axios';
import { AuthStore } from './AuthStore';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { AppUserGroupCodes } from 'src/types/AppUserGroup';
import { ParsedUrlQuery } from 'querystring';
import { RouterStore } from './RouterStore';
import moment from 'moment/moment';

type StatMarginalityAllResponse =
  | StatMarginalityCustomersResponse
  | StatMarginalityDepartmentsResponse
  | StatMarginalityEmployeesResponse
  | StatMarginalityOfficesResponse;

export function transformQueryToAllowedParams<T>(query: ParsedUrlQuery, role?: RoleEmployee): T {
  const obj = {} as any;
  if (query.mode && RoleEmployeeTypes.indexOf(query.mode as any) !== -1) {
    obj.mode = query.mode as RoleEmployee;
  } else {
    obj.mode = role;
  }
  if (
    query.mode &&
    query.tab &&
    TabNamesTypes.indexOf(query.tab as any) !== -1 &&
    allowTabsByRole[query.mode as string].indexOf(query.tab as any) !== -1
  ) {
    obj.tab = query.tab as TabNames;
  } else {
    obj.tab = TAB_NAME_CODES.budgetGroups;
  }
  if (query.selectedCrumbCode) {
    obj.selectedCrumbCode = query.selectedCrumbCode as string;
  }
  if (query.date && moment(query.date, 'YYYY-MM-DD').isValid()) {
    obj.date = query.date as string;
  }
  if (query.overdueDaysFrom && !isNaN(Number(query.overdueDaysFrom))) {
    obj.overdueDaysFrom = Number(query.overdueDaysFrom);
  }
  if (query.overdueDaysTo && !isNaN(Number(query.overdueDaysTo))) {
    obj.overdueDaysTo = Number(query.overdueDaysTo);
  }
  return obj;
}
export type Code = string;
export interface selectedCrumb {
  code?: Code; //* должно быть обязательно
  title?: string;
}
export const RoleEmployeeTypes = <const>['company', 'division', 'office', 'department', 'employee'];
export type RoleEmployee = (typeof RoleEmployeeTypes)[number];

const TabNamesTypes = <const>['budgetGroups', 'clients', 'managers', 'departments', 'offices'];
export type TabNames = (typeof TabNamesTypes)[number];

export const TabNamesValue: Record<TabNames, string> = {
  budgetGroups: 'Бюджетные группы',
  clients: 'Клиенты',
  managers: 'Менеджеры',
  departments: 'Отделы',
  offices: 'Филиалы',
};

// ? division =>
// ?   (statMarginalityDivisions(auth), statMarginalityOffices(param))
// ? office =>
// ?   (statMarginalityOffices(auth), statMarginalityDepartments(param))
// ? department =>
// ?   (statMarginalityDepartments(auth), statMarginalityCustomers(param) statMarginalityEmployees(param))
// ? employee =>
// ?   (statMarginalityEmployees(auth), statMarginalityCustomers(auth))

export const ROLE_EMPLOYEE_CODES = {
  employee: 'employee' as RoleEmployee,
  department: 'department' as RoleEmployee,
  division: 'division' as RoleEmployee,
  company: 'company' as RoleEmployee,
  office: 'office' as RoleEmployee,
};
//* ключ:крошка свойство:поля у сущности "пониже" чтобы получить код сущности крошки
export const getPropertyByEntity = {
  division: 'divisionCode',
  office: 'branchOfficeCode',
  department: 'departmentCode',
  company: undefined, // компания одна так что
  employee: undefined, // последний пункт ему ничего не нужно
};
export const TAB_NAME_CODES = {
  budgetGroups: 'budgetGroups' as TabNames,
  clients: 'clients' as TabNames,
  managers: 'managers' as TabNames,
  departments: 'departments' as TabNames,
  offices: 'offices' as TabNames,
};

const allowTabsByRole: Record<RoleEmployee, TabNames[]> = {
  employee: [TAB_NAME_CODES.budgetGroups, TAB_NAME_CODES.clients],
  department: [TAB_NAME_CODES.budgetGroups, TAB_NAME_CODES.clients, TAB_NAME_CODES.managers],
  office: [TAB_NAME_CODES.budgetGroups, TAB_NAME_CODES.departments],
  division: [TAB_NAME_CODES.budgetGroups, TAB_NAME_CODES.offices],
  company: [TAB_NAME_CODES.budgetGroups],
};
// * имена крошек, когда не выбрано значение для них
export const namesCrumbsNoValue: Record<RoleEmployee, string> = {
  employee: 'Все менеджеры',
  department: 'Все отделы',
  office: 'Все филиалы',
  division: 'Все дивизионы',
  company: 'Компания',
};
export interface AllowedParams {
  tab?: TabNames;
  date?: string;
  selectedCrumbCode?: string;
  mode?: RoleEmployee;
}
export interface CustomerMarginalityInterface {
  customersForStats: Record<string, Customer>;
  isLoadedTabs: Record<string, boolean>;
  customerStats: CustomerMarginalityReport[];
  budgetGroups: Record<string, BudgetGroup>;
  statusApprovals?: Record<Code, string>;
  historyPlan?: Record<Code, Record<string, string>>;
}

export class EmployeesMarginalityStore implements CustomerMarginalityInterface {
  apiStore: ApiStore;
  snackbarStore: SnackbarStore;
  routerStore: RouterStore;
  authStore: AuthStore;

  employeesForStats: Record<Code, Employee> = {};
  customersForStats: Record<Code, Customer> = {};
  departmentForStats: Record<Code, EmployeeDepartment> = {};
  officeForStats: Record<Code, BranchOffice> = {};
  budgetGroups: Record<Code, BudgetGroup> = {};

  budgetGroupsStats: EmployeeMarginalityReport[] = []; //* статистика для таба и для диаграмм
  employeeStats: EmployeeMarginalityReport[] = [];
  officeStats: BranchOfficeMarginalityReport[] = [];
  departmentStats: DepartmentMarginalityReport[] = [];
  customerStats: CustomerMarginalityReport[] = [];

  /**
   * Управляем адресом страницы
   */
  routerControlEnabled = false;
  isLoading = false;
  isLoadedTabs = {
    budgetGroups: false,
    clients: false,
    managers: false,
    departments: false,
    offices: false,
  };

  role: RoleEmployee;
  mode: RoleEmployee;
  request: AllowedParams = null;
  statusApprovals: Record<Code, string> = {};
  historyPlan: Record<Code, Record<string, string>> = {};

  enabledCrumbs: RoleEmployee[] = [];
  selectedCrumbs: Record<RoleEmployee, selectedCrumb> = {
    company: undefined,
    division: undefined,
    office: undefined,
    department: undefined,
    employee: undefined,
  };
  crumbsValues: Record<RoleEmployee, selectedCrumb[]> = {
    company: [],
    division: [],
    office: [],
    department: [],
    employee: [],
  };

  allowTabs: TabNames[] = [];
  selectedTab: TabNames = TAB_NAME_CODES.budgetGroups;
  showConfirmPlan = false;
  employeeMotivationItem: EmployeesItemMotivationResponse = null;

  hasReports = false;

  private _dateFormat = '';
  get date(): Date {
    return new Date(this._dateFormat);
  }
  set date(v) {
    const date = v;
    date.setDate(1);
    this._dateFormat = formatDateSwaggerZ(date);
  }

  constructor(rootStore: RootStore) {
    this.snackbarStore = rootStore.getSnackbar();
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.routerStore = rootStore.getRouter();
    // this.role = 'division';
    this.request = observable({});
    this.role = this.getTypeByRole();
    this.setMode(this.role);
    makeAutoObservable(this, { apiStore: false, snackbarStore: false, getTypeByRole: false });
  }
  //* определяет по правам тип данного сотрудника из RoleEmployee
  getTypeByRole(): RoleEmployee {
    const isDivisionChief = this.authStore.inGroup(AppUserGroupCodes.DIVISION_CHIEF);
    const isDivisionMarketer = this.authStore.inGroup(AppUserGroupCodes.DIVISION_MARKETER);
    const isCallCenterOperator = this.authStore.inGroup(AppUserGroupCodes.CALL_CENTER_OPERATOR);
    const isOfficeChief = this.authStore.inGroup(AppUserGroupCodes.OFFICE_CHIEF);
    const isOfficeMarketer = this.authStore.inGroup(AppUserGroupCodes.OFFICE_MARKETER);
    const isSellerChief = this.authStore.inGroup(AppUserGroupCodes.SELLER_CHIEF);
    const isCompanyChief = this.authStore.inGroup(AppUserGroupCodes.COMPANY_CHIEF);
    const isCompanyMarketer = this.authStore.inGroup(AppUserGroupCodes.COMPANY_MARKETER);
    const isSupport = this.authStore.inGroup(AppUserGroupCodes.SUPPORT);

    if (isCompanyChief || isCompanyMarketer) {
      return ROLE_EMPLOYEE_CODES.company;
    }
    if (isDivisionChief || isDivisionMarketer) {
      return ROLE_EMPLOYEE_CODES.division;
    }
    if (isOfficeChief || isSupport || isOfficeMarketer || isCallCenterOperator) {
      return ROLE_EMPLOYEE_CODES.office;
    }
    if (isSellerChief) {
      return ROLE_EMPLOYEE_CODES.department;
    }
    return ROLE_EMPLOYEE_CODES.employee;
  }

  setSelectedTab(value: TabNames): void {
    this.selectedTab = value;
    this.setRequestFromObj(observable({ tab: value }));
    this.actualizeRouter(this.request);
  }
  /**
   * Выбираем значение из списка крошки.
   */
  async setSelectedCrumbAndSetMode(crumbName: RoleEmployee, code: Code): Promise<void> {
    const prevMode = this.mode;
    const prevSelectedCrumbsCode = this.selectedCrumbs[crumbName]?.code;
    const selectedCrumb = this.crumbsValues[crumbName].find(crumb => crumb.code === code);

    if (!this.setMode(crumbName) && prevSelectedCrumbsCode === selectedCrumb.code) {
      return;
    }
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    // * если роль последняя то обновляем References(т.к. следующей крошки после этой роли нет, значит можно не обновлять данные)
    if (crumbName !== RoleEmployeeTypes[RoleEmployeeTypes.length - 1]) {
      await this.loadStatMarginalityReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
      this.selectedCrumbs[crumbName] = selectedCrumb;
      if (this.selectedTab !== TAB_NAME_CODES.clients) {
        //* при загрузке таба Клиенты уже есть запрос getStatusEmployee. Таким образом избегаем дублирование запроса
        this.getStatusEmployee(this._dateFormat);
      }
    });

    this.loadStatByTab(TAB_NAME_CODES.budgetGroups);
    if (TAB_NAME_CODES.budgetGroups !== this.selectedTab) {
      this.loadStatByTab(this.selectedTab);
    }
  }
  actualizeRouter(request: AllowedParams): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const req = toJS(request);
    this.routerStore.replace(
      {
        pathname: this.routerStore.router.pathname,
        query: { ...req },
      },
      undefined,
      { shallow: true }
    );
  }
  async initialLoadStats(): Promise<void> {
    const role = this.role;
    const date = this._dateFormat;
    this.allowTabs = allowTabsByRole[role];
    await this.loadStatMarginalityReferences({}, true);
    await this.loadStatBudgetGroups(role);
    this.getStatusEmployee(date);
  }
  setRequestFromObj(req: AllowedParams): void {
    set(this.request, req);
  }

  async initialLoadStatsByQuery(params: AllowedParams): Promise<void> {
    if ((this.role === 'division' || this.role === 'company') && (params.mode === 'company' || !params.mode)) {
      params.mode = 'office';
      params.selectedCrumbCode = this.authStore.profile.chosenBranchOfficeCode;
    }
    const role = this.role;
    this.setDate(params.date ? new Date(params.date) : new Date());
    const date = this._dateFormat;
    runInAction(async () => {
      this.setRequestFromObj(params);
      if (params.mode) {
        this.setMode(params.mode);
        this.allowTabs = allowTabsByRole[this.mode];
        await this.loadStatMarginalityReferences({ [this.mode]: params.selectedCrumbCode }, true);
      } else {
        this.allowTabs = allowTabsByRole[role];
        await this.loadStatMarginalityReferences({}, true);
      }

      // await this.loadStatMarginalityReferences({}, true);
      // if (params?.mode && params?.selectedCrumbCode) {
      await this.loadStatBudgetGroups(role);
      if (params?.tab) {
        this.setSelectedTab(params.tab);
        this.loadStatByTab(params.tab);
      }
      this.getStatusEmployee(date);
    });
  }

  async loadStatBudgetGroups(role: RoleEmployee): Promise<void> {
    const tabName = TAB_NAME_CODES.budgetGroups;
    switch (role) {
      case ROLE_EMPLOYEE_CODES.division:
        this.loadStatMarginalityDivisions(tabName, this.selectedCrumbs[role]?.code);
        break;
      case ROLE_EMPLOYEE_CODES.office:
        this.loadStatMarginalityOffices(tabName, this.selectedCrumbs[role]?.code);
        break;
      case ROLE_EMPLOYEE_CODES.department:
        this.loadStatMarginalityDepartments(tabName, this.selectedCrumbs[role]?.code);
        break;
      case ROLE_EMPLOYEE_CODES.employee:
        this.loadStatMarginalityEmployees(tabName, this.selectedCrumbs[role]?.code);
        break;
      case ROLE_EMPLOYEE_CODES.company:
        this.loadStatCompany(tabName, this.selectedCrumbs[role]?.code);
        break;

      default:
        console.warn('role received does not exist');
        break;
    }
  }
  async loadStatClients(role: RoleEmployee): Promise<void> {
    const tabName = TAB_NAME_CODES.clients;
    switch (role) {
      case ROLE_EMPLOYEE_CODES.department:
        this.getStatusEmployee(this._dateFormat, undefined, this.selectedCrumbs[role]?.code);
        this.loadStatMarginalityCustomers(tabName, undefined, this.selectedCrumbs[role]?.code);
        break;
      case ROLE_EMPLOYEE_CODES.employee:
        this.getStatusEmployee(this._dateFormat, this.selectedCrumbs[role]?.code);
        this.loadStatMarginalityEmployeeCustomers(tabName, this.selectedCrumbs[role]?.code);
        break;

      default:
        console.warn('role received does not exist');
        break;
    }
  }
  loadStatByTab(tab: TabNames): void {
    const role = this.mode;
    if (this.isLoadedTabs[tab]) {
      return;
    }
    switch (tab) {
      case TAB_NAME_CODES.budgetGroups:
        // * бюджетные группы есть у всех ролей и поэтому есть отдельная функция, которая уже по роли выбирает что подгрузить
        this.loadStatBudgetGroups(role);
        break;
      case TAB_NAME_CODES.clients:
        // * клиенты тоже есть у двух ролей
        this.loadStatClients(role);
        break;
      case TAB_NAME_CODES.managers:
        this.loadStatMarginalityEmployees(tab, undefined, this.selectedCrumbs[role]?.code);
        break;
      case TAB_NAME_CODES.departments:
        this.loadStatMarginalityDepartments(tab, undefined, this.selectedCrumbs[role]?.code);
        break;
      case TAB_NAME_CODES.offices:
        this.loadStatMarginalityOffices(tab, undefined, this.selectedCrumbs[role]?.code);
        break;

      default:
        console.warn('tab received does not exist');
        break;
    }
  }
  /**
   * Обновить текущую статистику.
   * (Поменяли дату обновляем текущее состояние выбранного таба)
   */
  updateStats(): void {
    //* при обновлении даты запрашиваем еще новые данные для крошек, т.к. со временем могут добавляться новые менеджеры к отделу и новые отделы
    if (this.mode === ROLE_EMPLOYEE_CODES.department || this.mode === ROLE_EMPLOYEE_CODES.office) {
      this.loadStatMarginalityReferences({ [this.mode]: this.selectedCrumbs[this.mode]?.code });
    }

    if (this.selectedTab !== TAB_NAME_CODES.clients) {
      //* при загрузке таба Клиенты уже есть запрос getStatusEmployee. Таким образом избегаем дублирование запроса
      this.getStatusEmployee(this._dateFormat);
    }
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    this.loadStatByTab(TAB_NAME_CODES.budgetGroups);
    if (TAB_NAME_CODES.budgetGroups !== this.selectedTab) {
      this.loadStatByTab(this.selectedTab);
    }
  }
  setCrumbsValues(data: StatMarginalityReferencesResponse): void {
    this.crumbsValues[ROLE_EMPLOYEE_CODES.company] = data.headCompanies || [];
    this.crumbsValues[ROLE_EMPLOYEE_CODES.division] = data.divisions || [];
    this.crumbsValues[ROLE_EMPLOYEE_CODES.office] = [...(data.offices || [])].map(i => ({ ...i, title: i.name }));
    this.crumbsValues[ROLE_EMPLOYEE_CODES.department] = data.departments || [];
    this.crumbsValues[ROLE_EMPLOYEE_CODES.employee] = [...(data.employees || [])].map(employee => ({
      code: employee.code,
      title: `${employee.surname || ''} ${employee.name || ''} ${employee.patronymic || ''}`,
    }));
  }
  setResultMarginalityReferences({ data }: AxiosResponse<StatMarginalityReferencesResponse>, init = false): void {
    if (init) {
      const budgetGroups = data.budgetGroups || [];
      this.budgetGroups = budgetGroups.reduce<Record<Code, BudgetGroup>>((acc, i) => {
        acc[i.code] = i;
        return acc;
      }, {});
    }
    this.setCrumbsValues(data);
    if (init) {
      const roleIndex = RoleEmployeeTypes.indexOf(this.role);
      const currentMode = this.mode; //* текущий мод из урла. Если нет то тут будет мод по роли и дальше все пойдет по маслу.
      const modeParamIndex = RoleEmployeeTypes.indexOf(currentMode);
      if (roleIndex <= modeParamIndex) {
        // * подгружаем данные в крошки, которые идут ДО крошки по моду (включительно)
        const crumbsBeforeRole = RoleEmployeeTypes.slice(0, modeParamIndex + 1).reverse();

        crumbsBeforeRole.forEach((crumbs, index) => {
          if (this.crumbsValues[crumbs].length > 0) {
            if (crumbs === this.mode) {
              //* ищем, для переданной крошки из урла, значение
              const valueByUrl = this.crumbsValues[crumbs].find(crumb => crumb.code === this.request.selectedCrumbCode);
              if (valueByUrl) {
                this.selectedCrumbs[crumbs] = valueByUrl;
              } else {
                //* может быть такое что не нашлось значение и просто берем по дефолту
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            } /* else if (crumbs == this.role) {
              //* если в урле ничего не передается берем по роли
              this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
            } */ else {
              //* собираем крошку НЕ переданной из урла и формируем ее на основе Переданной крошки, и остальные так же
              const selectedCrumbsBefore = this.selectedCrumbs[crumbsBeforeRole[index - 1]];
              const selectedCrumbsBeforeCode = selectedCrumbsBefore?.[getPropertyByEntity[crumbs]];
              //* бывает так когда нет у крошки ключа по которой можно ее найти (company не имеет связки с division)
              if (selectedCrumbsBeforeCode) {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs].find(crumb => crumb.code === selectedCrumbsBeforeCode);
              } else {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            }
          } else {
            console.warn('crumbsValues with "crumbs" is not exist');
          }
        });
      } else {
        this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня.');
        console.warn('Вам недоступен просмотр этого уровня');
      }
      this.enabledCrumbs = RoleEmployeeTypes.slice(roleIndex, modeParamIndex + 1);
      const nextMode = RoleEmployeeTypes[modeParamIndex + 1];
      if (nextMode && this.crumbsValues[nextMode].length >= 1) {
        if (this.crumbsValues[nextMode].length === 1) {
          this.selectedCrumbs[nextMode] = this.crumbsValues[nextMode][0];
        }
        this.enabledCrumbs.push(nextMode);
      }
    }
  }

  setMode(mode: RoleEmployee): boolean {
    if (mode === this.mode) {
      return false;
    }
    const roleIndex = RoleEmployeeTypes.indexOf(this.role);
    const modeParamIndex = RoleEmployeeTypes.indexOf(this.request.mode);
    if (roleIndex > modeParamIndex && this.request.mode) {
      console.warn('Нельзя установить mode выше роли. Значение mode установится по умолчанию');
      this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня. Значения установятся по умолчанию');
      this.mode = this.role;
      return false;
    }
    this.setSelectedTab(TAB_NAME_CODES.budgetGroups);

    this.mode = mode;
    Object.keys(this.isLoadedTabs).forEach(tab => {
      this.isLoadedTabs[tab] = false;
    });
    return true;
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  setDate(date: Date): void {
    this.date = date;
    const localDate = new Date(date);
    localDate.setDate(1);
    this.setRequestFromObj(observable({ date: formatDateSwaggerZ(localDate) }));
    this.actualizeRouter(this.request);
  }
  unSelectCrumbs(prevMode: RoleEmployee, newMode: RoleEmployee): void {
    const rolePrevIndex = RoleEmployeeTypes.indexOf(prevMode);
    const roleIndex = RoleEmployeeTypes.indexOf(newMode);

    if (roleIndex < rolePrevIndex) {
      const unSelectedCrumb = RoleEmployeeTypes.slice(roleIndex + 1, RoleEmployeeTypes.length);
      unSelectedCrumb.forEach(crumb => {
        this.selectedCrumbs[crumb] = undefined;
      });
      this.enabledCrumbs = this.enabledCrumbs.filter(crumb => !unSelectedCrumb.includes(crumb));
    }
    for (let nextRoleIndex = roleIndex + 1; nextRoleIndex < RoleEmployeeTypes.length; nextRoleIndex++) {
      const nextRole = RoleEmployeeTypes[roleIndex + 1];
      if (nextRole && this.crumbsValues[nextRole].length >= 1) {
        if (this.crumbsValues[nextRole].length === 1) {
          // Выбираем в крошке единственный вариант
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole][0];
        } else if (this.selectedCrumbs[nextRole]) {
          // Сбрасываем выбранный вариант, если его нет в доступном списке
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole].find(crumb => crumb === this.selectedCrumbs[nextRole]);
        }
        this.enabledCrumbs.push(nextRole);
      } else {
        this.enabledCrumbs = this.enabledCrumbs.filter(crumb => crumb !== nextRole);
      }
    }
  }
  /**
   * Клик по крошке без меню
   */
  async setModeAndUpdateCrumbs(crumbName: RoleEmployee): Promise<void> {
    const prevMode = this.mode;
    const selectedCrumb = this.selectedCrumbs[crumbName];
    if (!this.setMode(crumbName)) {
      return;
    }
    if (crumbName !== RoleEmployeeTypes[RoleEmployeeTypes.length - 1] && crumbName !== this.role) {
      await this.loadStatMarginalityReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
    });
    this.setRequestFromObj(observable({ mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);
    this.loadStatByTab(TAB_NAME_CODES.budgetGroups);
    if (this.selectedTab !== TAB_NAME_CODES.clients) {
      //* при загрузке таба Клиенты уже есть запрос getStatusEmployee. Таким образом избегаем дублирование запроса
      this.getStatusEmployee(this._dateFormat);
    }
  }
  setBudgetGroupsStats(data: StatMarginalityAllResponse, tab: TabNames): void {
    this.budgetGroupsStats = data.stats || [];
    this.isLoadedTabs[tab] = true;
    this.isLoading = false;
  }
  setResultMarginalityDepartments({ data }: AxiosResponse<StatMarginalityDepartmentsResponse>, tab: TabNames): void {
    if (tab === TAB_NAME_CODES.budgetGroups) {
      this.setBudgetGroupsStats(data, tab);
    }
    if (tab === TAB_NAME_CODES.departments) {
      const departments = data.departments || [];
      this.departmentStats = data.stats || [];
      this.departmentForStats = departments.reduce<Record<Code, EmployeeDepartment>>((acc, i) => {
        acc[i.code] = i;
        return acc;
      }, {});
      this.isLoadedTabs[tab] = true;
      this.hasReports = !!data.stats?.length;
      this.isLoading = false;
    }
  }
  setResultMarginalityOffices({ data }: AxiosResponse<StatMarginalityOfficesResponse>, tab: TabNames): void {
    if (tab === TAB_NAME_CODES.budgetGroups) {
      this.setBudgetGroupsStats(data, tab);
    }
    if (tab === TAB_NAME_CODES.offices) {
      this.officeStats = data.stats || [];
      const offices = data.offices || [];
      this.officeForStats = offices.reduce<Record<Code, BranchOffice>>((acc, i) => {
        acc[i.code] = i;
        return acc;
      }, {});
      this.isLoadedTabs[tab] = true;
      this.hasReports = !!data.stats?.length;
      this.isLoading = false;
    }
  }
  setResultMarginalityDivisions({ data }: AxiosResponse<StatMarginalityEmployeesResponse>, tab: TabNames): void {
    this.setBudgetGroupsStats(data, tab);
  }
  setResultMarginalityEmployees({ data }: AxiosResponse<StatMarginalityEmployeesResponse>, tab: TabNames): void {
    if (tab === TAB_NAME_CODES.budgetGroups) {
      this.setBudgetGroupsStats(data, tab);
    }
    if (tab === TAB_NAME_CODES.managers) {
      this.employeeStats = data.stats || [];
      const employees = data.employees || [];
      this.employeesForStats = employees.reduce<Record<Code, Employee>>((acc, i) => {
        acc[i.code] = i;
        return acc;
      }, {});
      this.isLoadedTabs[tab] = true;
      this.hasReports = !!data.stats?.length;
      this.isLoading = false;
    }
  }
  async loadStatCompany(tab: TabNames, headCompanyCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore.apiStatApi().statMarginalityHeadCompanies(headCompanyCode, this._dateFormat);
    this.setResultHeadCompanies(res, tab);
  }
  setResultHeadCompanies({ data }: AxiosResponse<StatMarginalityHeadCompaniesResponse>, tab: TabNames): void {
    if (tab === TAB_NAME_CODES.budgetGroups) {
      this.setBudgetGroupsStats(data, tab);
    }
  }
  setResultMarginalityCustomers({ data }: AxiosResponse<StatMarginalityCustomersResponse>, tab: TabNames): void {
    const customers = data.customers || [];
    this.customersForStats = customers.reduce<Record<Code, Customer>>((acc, i) => {
      acc[i.code] = {
        shortTitle: i.shortTitle,
        title: i.title,
        mainEmployee: i.mainEmployee,
        monthEmployee: i.monthEmployee,
        mainEmployeeJoinDate: i.mainEmployeeJoinDate,
      };
      return acc;
    }, {});
    this.customerStats = data.stats || [];
    this.getPlanHistory(
      this.selectedCrumbs[ROLE_EMPLOYEE_CODES.office].code,
      customers.map(i => i.code)
    );
    this.isLoadedTabs[tab] = true;
    this.hasReports = !!data.stats?.length;
    this.isLoading = false;
  }
  async loadStatMarginalityEmployeeCustomers(tab: TabNames, employeeCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore
      .apiStatApi()
      .statMarginalityEmployeeCustomers(employeeCode, undefined, undefined, undefined, undefined, this._dateFormat);
    this.setResultMarginalityCustomers(res, tab);
  }
  async loadStatMarginalityCustomers(tab: TabNames, employeeCode: Code = undefined, departmentCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore
      .apiStatApi()
      .statMarginalityCustomers(undefined, employeeCode, departmentCode, undefined, undefined, undefined, this._dateFormat);
    this.setResultMarginalityCustomers(res, tab);
  }
  async loadStatMarginalityEmployees(
    tab: TabNames,
    employeeCode: Code,
    departmentCode: Code = undefined,
    branchOfficeCode: Code = undefined
  ): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore
      .apiStatApi()
      .statMarginalityEmployees(employeeCode, branchOfficeCode, this._dateFormat, undefined, departmentCode);
    this.setResultMarginalityEmployees(res, tab);
  }
  async loadStatMarginalityOffices(tab: TabNames, branchOfficeCode: Code = undefined, divisionCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore.apiStatApi().statMarginalityOffices(branchOfficeCode, this._dateFormat, divisionCode);
    this.setResultMarginalityOffices(res, tab);
  }

  async loadStatMarginalityDepartments(tab: TabNames, departmentCode: Code, branchOfficeCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore.apiStatApi().statMarginalityDepartments(departmentCode, this._dateFormat, branchOfficeCode);
    this.setResultMarginalityDepartments(res, tab);
  }

  async loadStatMarginalityDivisions(tab: TabNames, divisionCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore.apiStatApi().statMarginalityDivisions(divisionCode, this._dateFormat);
    this.setResultMarginalityDivisions(res, tab);
  }
  /**
   *
   * Справочники для отображения страницы статистики
   * @param object объект { роль: код } для подгрузки данных по определенному коду следующие, по иерархии, данные.
   * (Загрузить по коду отдела всех менеджеров)
   * @param init флаг отвечающий за первую прогрузку
   */
  async loadStatMarginalityReferences(
    {
      employee: employeeCode,
      department: departmentCode,
      office: branchOfficeCode,
      division: divisionCode,
    }: {
      employee?: Code;
      department?: Code;
      office?: Code;
      division?: Code;
    },
    init = false
  ): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await this.apiStore
      .apiStatApi()
      .statMarginalityReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, this._dateFormat);
    this.setResultMarginalityReferences(res, init);
  }
  getPlanHistory(branchOfficeCode: Code, customerCodes: Code[]): void {
    if (customerCodes.length === 0) {
      this.historyPlan = {};
      return;
    }
    const previousDate = new Date(this.date);
    previousDate.setDate(1);
    previousDate.setMonth(previousDate.getMonth() - 1);
    this.apiStore
      .apiPlan()
      .getSalePlanCustomersHistory({
        branchOfficeCode,
        customerCodes,
        months: [formatDateSwaggerZ(previousDate), this._dateFormat],
      })
      .then(res => {
        this.setResultHistoryPlan(res);
      })
      .catch(() => {
        runInAction(() => {
          this.historyPlan = {};
        });
      });
  }
  setResultHistoryPlan({ data }: AxiosResponse<GetSalePlanCustomersHistoryResponse>): void {
    const history = data.changes || [];
    this.historyPlan = history
      .filter(i => i.month === this._dateFormat)
      .reduce<Record<Code, Record<string, string>>>((acc, i) => {
        if (acc[i.customerCode]) {
          if (Object.keys(acc[i.customerCode]).includes(i.budgetGroupCode)) {
            if (new Date(acc[i.customerCode][i.budgetGroupCode]).getTime() < new Date(i.createdAt).getTime()) {
              acc[i.customerCode][i.budgetGroupCode] = i.createdAt;
            }
          } else {
            acc[i.customerCode][i.budgetGroupCode] = i.createdAt;
          }
        } else {
          acc[i.customerCode] = {
            [i.budgetGroupCode]: i.createdAt,
          };
        }
        return acc;
      }, {});
  }

  /**
   *
   * Получение Статуса планов
   * @param month
   * @param employeeCode Код другого сотрудника для проверки планов
   * @param departmentCode Код другого сотрудника для проверки планов
   */
  getStatusEmployee(month: string, employeeCode?: Code, departmentCode?: Code): void {
    const isLoadStatusForConfirmInfo = employeeCode === undefined && departmentCode === undefined; //* загружаем для информации о самих планах, но не для подтверждения планов (showConfirmPlan)
    const mode = this.mode;
    if (
      (mode === ROLE_EMPLOYEE_CODES.employee && this.selectedCrumbs[mode]?.code === this.authStore?.profile?.employee.code) ||
      employeeCode !== undefined ||
      departmentCode !== undefined
    ) {
      this.apiStore
        .apiPlan()
        .salePlanEmployeeStatus(month, employeeCode, departmentCode)
        .then(({ data }) => {
          runInAction(() => {
            this.statusApprovals = data.approvals.reduce<Record<Code, string>>((acc, i) => {
              acc[i.customerCode] = i.approvedAt;
              return acc;
            }, {});
            if (isLoadStatusForConfirmInfo || employeeCode === this.authStore?.profile?.employee.code) {
              this.showConfirmPlan = data.awaitingApprovalCustomerCodes?.length > 0;
            }
          });
        });
    } else {
      this.statusApprovals = {};
      if (isLoadStatusForConfirmInfo || employeeCode === this.authStore?.profile?.employee.code) {
        this.showConfirmPlan = false;
      }
    }
  }
  approvePlanEmployee(month: string): void {
    this.apiStore
      .apiPlan()
      .salePlanEmployeeApprove({ month })
      .then(() => {
        this.showConfirmPlan = false;
      })
      .catch(e => {
        console.warn(e);
      });
  }

  resetEmployeesItemMotivation(): void {
    this.employeeMotivationItem = null;
  }

  setEmployeesItemMotivation(data: EmployeesItemMotivationResponse): void {
    this.employeeMotivationItem = data;
  }

  loadEmployeesItemMotivation(employeeCode: string, dateBegin: Date, dateEnd: Date): void {
    this.apiStore
      .apiEmployee()
      .employeesItemMotivation(employeeCode, formatDateSwaggerZ(dateBegin), formatDateSwaggerZ(dateEnd))
      .then(res => {
        this.setEmployeesItemMotivation(res.data);
      })
      .catch(e => {
        this.setEmployeesItemMotivation({
          totalAmount: 0,
          p1amount: 0,
          p2amount: 0,
          selfPurchaseAmount: 0,
          centralPurchaseAmount: 0,
        });
        console.warn('loadEmployeesItemMotivation ERROR', e);
      });
  }
}
