import React, { useContext } from 'react';
import type { FC } from 'react';
import { AppBarProps } from '@mui/material/AppBar';
import { Env, EnvContext } from '../EnvContext';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

type StyledAppBarProps = AppBarProps & {
  env?: Env;
};
const backgroundColorByEnv: Record<Env, string> = {
  prod: '#505050',
  dev: '#b71c1c',
  qa: '#00695c',
};
const backgroundColorHoverByEnv: Record<Env, string> = {
  prod: '#3e3d3d',
  dev: '#9b1616',
  qa: '#015046',
};

export const getColorByEnv = (env: Env): Record<string, string> => {
  return {
    backgroundColor: backgroundColorByEnv[env],
  };
};
export const getColorForHoverByEnv = (env: Env): Record<string, string> => {
  return {
    backgroundColor: backgroundColorHoverByEnv[env],
  };
};
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'env',
})<StyledAppBarProps>(({ theme, env }) => ({
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.complex,
  }),
  ...getColorByEnv(env),
})) as React.ComponentType<StyledAppBarProps>;

interface AppBarColorProps {
  children: React.ReactNode;
  className?: string;
}
export const AppBarColor: FC<AppBarColorProps & StyledAppBarProps> = ({ children, className, ...rest }): JSX.Element => {
  const env = useContext(EnvContext);

  return (
    <StyledAppBar position="relative" className={className} env={env} {...rest}>
      {children}
    </StyledAppBar>
  );
};
