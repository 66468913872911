import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import Grid from '@mui/material/Grid';
import { ProductPackTable } from 'src/views/catalog/Listing/ProductPackTable';
import { PackByProductTable } from 'src/views/catalog/Listing/PackByProductTable';
import { ProductPacksStore } from '../../../store/ProductPacksStore';
import { ContractCostPosition } from '../../../api/marketx';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import { CustomIconTabs } from '../../../components/Deals/CustomIconTabs/CustomIconTabs';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { Box } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

interface DealPositionAdditionalTabPacksProps {
  dealStore?: DealItemStore;
  dealPosition?: AppDealPosition;
  agreementPosition?: ContractCostPosition;
  packsStore?: ProductPacksStore;
}

// Закладка с информацией о товаре в строке товара в сделке
export const DealPositionAdditionalTabPacks: FC<DealPositionAdditionalTabPacksProps> = observer(
  ({ dealStore, dealPosition, agreementPosition, packsStore }): JSX.Element => {
    const [selectedAdditionalTab, setSelectedAdditionalTab] = useState(1);
    useEffect(() => {
      if (dealStore && dealPosition) {
        packsStore.loadForProduct(dealPosition.productCode, dealPosition?.warehouseCode || dealStore.deal?.warehouseCode);
      }
      if (agreementPosition) {
        packsStore.loadForProduct(agreementPosition.productCode, '~total');
      }
    }, [packsStore, dealStore, dealPosition, agreementPosition]);

    const innerTabsValue = useMemo(() => {
      return [
        {
          label: ``,
          title: 'Партии прихода',
          tabPanelIndex: 0,
          disabled: false,
          icon: (
            <Grid container alignItems={'center'} px={0.7}>
              <Grid item height={'18px'}>
                <WidgetsOutlinedIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item ml={1}>
                <Typography component={'span'} sx={{ fontSize: '10px', cursor: 'pointer' }}>
                  Партии
                </Typography>
              </Grid>
            </Grid>
          ),
          badgeOn: false,
        },
        {
          label: ``,
          title: 'Пачки',
          tabPanelIndex: 1,
          disabled: false,
          icon: (
            <Grid container alignItems={'center'} px={0.7}>
              <Grid item height={'18px'}>
                <ViewInArOutlinedIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item ml={1}>
                <Typography component={'span'} sx={{ fontSize: '10px', cursor: 'pointer' }}>
                  Пачки
                </Typography>
              </Grid>
            </Grid>
          ),
          badgeOn: false,
        },
      ];
    }, []);

    const handleTabChange = useCallback(
      (event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedAdditionalTab(newValue);
      },
      [setSelectedAdditionalTab]
    );
    if (!packsStore.isLoaded) {
      return (
        <Box p={2}>
          <Skeleton />
        </Box>
      );
    }
    return (
      <TabSelectWrapper infiniteScrollId={'dealPositionAdditionalTabPacks'}>
        <Grid item sx={{ width: '100%', height: '100%' }} className="withCustomScroll">
          <Grid container sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)', MozPaddingBottom: '15px' }}>
            <Grid item height={'37px'} pl={1}>
              <CustomIconTabs
                handleToggleButtonChange={handleTabChange}
                toggleButtonValue={selectedAdditionalTab}
                tabsValue={innerTabsValue}
                padding="0 2px"
                sx={{ minHeight: '34px' }}
              />
            </Grid>
            <Grid item>
              {selectedAdditionalTab === 0 && (
                <ProductPackTable
                  packsStore={packsStore}
                  dealStore={dealStore}
                  dealPosition={dealPosition}
                  product={dealPosition?.product || agreementPosition.product}
                  infiniteScrollId={'dealPositionAdditionalTabPacks'}
                />
              )}
              {selectedAdditionalTab === 1 && (
                <PackByProductTable
                  packsStore={packsStore}
                  dealStore={dealStore}
                  dealPosition={dealPosition}
                  product={dealPosition?.product || agreementPosition.product}
                  infiniteScrollId={'dealPositionAdditionalTabPacks'}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </TabSelectWrapper>
    );
  }
);
