import { action, makeAutoObservable, observable, runInAction, set, toJS } from 'mobx';
import { AppDeal, AppDealDistributor } from '../slices/AppDeal';
import {
  ClientsForFilterResponse,
  CustomerAsFilter,
  Deal,
  DealApprovalStateEnum,
  DealsStatesResponse,
  DealState,
  DocumentApprovalState,
  DocumentsApprovalStatesResponse,
  DocumentsTypesResponse,
  DocumentType,
} from '../api/marketx';
import { mapDeal } from '../slices/AppDeal/lib';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { formatDateSwagger } from '@mx-ui/helpers';
import { SnackbarStore } from './SnackbarStore';
import { RootStore } from './StoreManager';
import { MsgType } from './Global/WebSocketStore';
import { ApiStore } from './Global/ApiStore';
import { setClear } from 'src/utils/mobx';
import { RouterStore } from './RouterStore';
import { IncomeMessage } from './Global/EventManager';
import { dateEnd, dateStart, defaultQuickDate } from './Documents/utils/defaultQuickDate';
import { quickDateRanges, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
import { TotalStoreEntity, totalStoreGenerate } from 'src/components/Documents/DocumentTotalCount';
import { ReservesStore } from './ReservesStore';
import { MyApprovalsAskingStore } from './Deals/MyApprovalsAsking';

export type DealListType = 'my' | 'approval' | 'clientOrders' | 'clientDeals' | 'agreement';

export function changeDealByWs(msg: IncomeMessage, deal: AppDeal): void {
  const DealApprovalFromMsg = Object.keys(DealApprovalStateEnum).find(i => DealApprovalStateEnum[i] === msg.data?.state);
  switch (msg.msgType) {
    case MsgType.WEB_SOCKET_DEAL_APPROVAL_ASK_WITHDRAW:
      deal.approval.state = undefined;
      deal.approval.stateTitle = undefined;
      deal.approval.selfPossible = false;
      deal.approval.askCode = undefined;
      deal.approval.resolutionComment = '';
      break;
    case MsgType.WEB_SOCKET_DEAL_APPROVAL_ASK:
      deal.approval.state = DealApprovalStateEnum.Awaiting;
      break;
    case MsgType.WEB_SOCKET_DEAL_APPROVAL_ACCEPTED:
      deal.approval.state = DealApprovalStateEnum[DealApprovalFromMsg] || DealApprovalStateEnum.Approved;
      deal.approval.resolutionDate = msg.data?.resolutionDate;
      deal.approval.resolutionComment = msg.data?.resolutionComment;
      break;
    case MsgType.WEB_SOCKET_DEAL_APPROVAL_DECLINED:
      deal.approval.state = DealApprovalStateEnum.Declined;
      deal.approval.resolutionComment = msg.data?.resolutionComment;
      deal.approval.resolutionDate = msg.data?.resolutionDate;
      break;
  }
}
export interface DealListRequest {
  code?: string;
  type?: DealListType;
  query?: string;
  queryCustomer?: string;
  clientCode?: string;
  agreementCode?: string;
  employeeCode?: string; // Код сотрудника
  employeeSetCode?: string; // Код набора сотрудников
  officeCodes?: string[];
  creationMonth?: Date;

  page?: number;
  count?: number;
  customerFilterPage?: number;
  customerFilterCount?: number;
  quickRange?: string;
  dateFrom?: Date;
  dateTo?: Date;

  containsAttachments?: boolean;
  hasBills?: boolean;
  hasPS?: boolean;

  status?: string[];
  customerCodes?: string[];
  approvalStateCodes?: string[];
  documentTypeCodes?: string[];
}

const defaultPageSize = 12;
const defaultCustomerFilterCount = 100;

export class DealListStore {
  apiStore: ApiStore;
  reserveStore: ReservesStore;
  dealSvc: DealListService;
  distributorsStore: MyApprovalsAskingStore;
  snackbarStore: SnackbarStore;
  routerStore: RouterStore;
  request: DealListRequest = {
    type: 'my',
    count: defaultPageSize,
    dateFrom: dateStart,
    dateTo: dateEnd,
  };
  /**
   * первоначальный фильтры, при котором показываются стандартные данные
   *
   *
   * @example ({employeeSetCode: currentRole})
   *
   *  @remarks
   *
   * currentRole является первоначальным фильтром. Его можно не указывать в урле
   */
  requestInit: DealListRequest = {};
  ignoreBeforeDate?: Date;

  lastLoadStarted?: Date;

  // isLoaded станет true после получения первых данных от бэкенда
  isLoaded = false;
  quickRange: RangeVariant = defaultQuickDate;

  isLoading = true;
  isFilterCustomerLoading = true;
  routerControlEnabled = false;

  // признак того, что сейчас происходит загрузка следующей страницы
  isMoreLoading = false;

  /**
   * Желательно обновить список.
   * Например, для списка заявок на согласование, когда по веб-сокету пришло уведомление о новом запросе.
   */
  isRefreshAdvised = false;

  items: AppDeal[] = new Array<AppDeal>();
  itemsIndex: Record<string, AppDeal> = {};
  dealStatuses: DealState[] = [];
  documentsCustomersFilterList: CustomerAsFilter[] = [];
  approvalStatuses: DocumentApprovalState[] = [];
  docTypes: DocumentType[] = [];

  loadedEpoch = 0;
  hasMore = true;
  totalStoreEntity: TotalStoreEntity = null;

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.reserveStore = new ReservesStore(rootStore);
    this.dealSvc = new DealListService();
    this.snackbarStore = rootStore.getSnackbar();
    this.routerStore = rootStore.getRouter();
    // фикс для безопасной подстановки в InfiniteScroll и useWebSocketHandler
    this.loadMore = this.loadMore.bind(this);
    this.handleWs = this.handleWs.bind(this);

    makeAutoObservable(this, {
      apiStore: false,
      dealSvc: false,
      snackbarStore: false,
      setRouterControl: action,
    });
  }

  loadMyApprovalsAskingStore(rootStore: RootStore, initEmployee?: string): void {
    this.distributorsStore = new MyApprovalsAskingStore(rootStore);
    this.distributorsStore.loadMyList(initEmployee);
  }

  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }

  handleWs(msg: IncomeMessage): void {
    const msgDealCode = msg.data?.dealCode;
    if (!msgDealCode) {
      return;
    }
    if (this.request.type === 'approval') {
      this.isRefreshAdvised = true;
      if (!this.items.length) {
        this.refresh();
        return;
      }
    }
    this.items.map(deal => {
      if (msgDealCode !== deal.code || !deal.approval) {
        return;
      }
      changeDealByWs(msg, deal);
    });
  }

  // Список заявок для соглашения
  loadAgreementDeals(agreementCode: string): void {
    this.request = Object.assign({}, <DealListRequest>{
      type: 'agreement',
      agreementCode: agreementCode,
      count: defaultPageSize,
    });
    this.dealSvc.executeOne(this);
  }

  // Список заявок для согласования
  loadMyApprovals(req: DealListRequest): void {
    this.request = observable(
      Object.assign({}, req, <DealListRequest>{
        type: 'approval',
        count: defaultPageSize,
      })
    );
    this.dealSvc.executeOne(this);
  }

  setRequest(req: DealListRequest): void {
    set(this.request, req);
  }

  setRequestInit(req: DealListRequest): void {
    setClear(this.requestInit, req);
  }

  // Список своих заявок
  loadMyDeals(req: DealListRequest): void {
    this.setRequest(req);
    this.dealSvc.loadDealStatuses(this);
    this.dealSvc.loadApprovalStatuses(this);
    this.dealSvc.loadDocTypes(this);
    this.loadCustomersForFilter();
    this.dealSvc.executeOne(this);
  }

  loadCustomersForFilter(queryCustomer?: string): void {
    if (queryCustomer) {
      this.request.queryCustomer = queryCustomer;
    } else {
      this.request.queryCustomer = undefined;
    }
    if (this.routerControlEnabled) {
      this.dealSvc.loadFilterCustomerCodes(this);
    }
  }

  // запуск загрузки следующего блока (следующей страницы)
  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.request.page = (this.request?.page || 1) + 1;
    this.dealSvc.executeOne(this);
  }

  employeeSetMergeRequest(req: DealListRequest, emp: AppDealDistributor): void {
    this.distributorsStore.loadMyList(emp.employeeCode);
    this.mergeRequest(req);
  }

  mergeRequest(req: DealListRequest): void {
    set(this.request, req);
    this.request.page = undefined;
    this.ignoreBeforeDate = new Date();
    this.isLoaded = false;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.isRefreshAdvised = false;
    this.dealSvc.executeOne(this);
  }

  setRouterControl(enabled: boolean): void {
    runInAction(() => {
      this.routerControlEnabled = enabled;
    });
  }

  actualizeRouter(req: DealListRequest): void {
    const reqInit = toJS(this.requestInit);
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.query) {
      params.set('query', req.query);
    }
    if (req.employeeSetCode && req.employeeSetCode !== reqInit.employeeSetCode) {
      params.set('employeeSet', req.employeeSetCode);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }
    if (req.containsAttachments) {
      params.set('containsAttachments', `${req.containsAttachments}`);
    }
    if (req.hasBills) {
      params.set('hasBills', `${req.hasBills}`);
    }
    if (req.hasPS) {
      params.set('hasPS', `${req.hasPS}`);
    }
    if (req.status) {
      params.set('status', `${req.status.join(',')}`);
    }
    if (req.customerCodes) {
      params.set('customerCodes', `${req.customerCodes.join(',')}`);
    }
    if (req.approvalStateCodes) {
      params.set('approvalStateCodes', `${req.approvalStateCodes.join(',')}`);
    }
    if (req.documentTypeCodes) {
      params.set('documentTypeCodes', `${req.documentTypeCodes.join(',')}`);
    }
    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/deals';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }

  /**
   * Перезапрос данных с бэка
   */
  refresh(): void {
    this.ignoreBeforeDate = new Date();
    this.isRefreshAdvised = false;
    this.dealSvc.executeOne(this);
  }

  setDealStatusesResult(statuses: DealsStatesResponse): void {
    this.dealStatuses = statuses.states;
  }

  setFilterCustomerResult(statuses: ClientsForFilterResponse): void {
    if (statuses.customers?.length) {
      const prevCheckedCustomers = [];
      this.documentsCustomersFilterList.forEach(prevI => {
        if (this.request.customerCodes.includes(prevI.code) && !statuses.customers.find(i => i.code === prevI.code)) {
          prevCheckedCustomers.push(prevI);
        }
      });

      if (this.request?.customerCodes?.length) {
        this.documentsCustomersFilterList = [...prevCheckedCustomers, ...statuses.customers];
      } else {
        this.documentsCustomersFilterList = [...statuses.customers];
      }
    }
    runInAction(() => {
      this.isFilterCustomerLoading = false;
    });
  }

  setApprovalStatusesResult(data: DocumentsApprovalStatesResponse): void {
    this.approvalStatuses = data.states;
  }

  setDocTypesResult(data: DocumentsTypesResponse): void {
    this.docTypes = data.types;
  }

  setResult(ctx: AxiosCallContext, req: DealListRequest, resDeals: Deal[]): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      return;
    }
    this.actualizeRouter(toJS(this.request));
    this.loadCustomersForFilter();
    this.ignoreBeforeDate = ctx.startTime;
    runInAction(() => {
      if (!this.isMoreLoading) {
        this.items.splice(0);
        this.itemsIndex = observable({});
      }
      resDeals.map((d: Deal) => {
        if (!d?.code) {
          return;
        }
        if (this.itemsIndex[d.code]) {
          // дубль, исключаем.
          return;
        }
        const dd = observable(mapDeal(d));
        this.itemsIndex[d.code] = dd;
        this.items.push(dd);
      });

      this.isMoreLoading = false;
      this.isLoaded = true;
      this.isLoading = false;
      this.hasMore = resDeals.length >= req.count;
      this.loadedEpoch++;
    });
  }
}

export class DealListService {
  constructor() {}

  // загрузка справочника статусов согласований
  loadApprovalStatuses(store: DealListStore): void {
    store.apiStore
      .apiDocuments()
      .documentsApprovalStates()
      .then(res => {
        store.setApprovalStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadApprovalStatuses req', e);
      });
  }

  loadDocTypes(store: DealListStore): void {
    store.apiStore
      .apiDocuments()
      .documentsTypes()
      .then(res => {
        store.setDocTypesResult(res.data);
      })
      .catch(e => {
        console.warn('loadDocTypesRequest', e);
      });
  }

  // загрузка справочника статусов сделок
  loadDealStatuses(store: DealListStore): void {
    store.apiStore
      .apiClientDeal()
      .dealsStates()
      .then(res => {
        store.setDealStatusesResult(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses req', e);
      });
  }

  loadFilterCustomerCodes(store: DealListStore): void {
    runInAction(() => {
      store.isFilterCustomerLoading = true;
    });
    const req = Object.assign({}, store.request);
    req.customerFilterPage = req.customerFilterPage > 1 ? req.customerFilterPage : undefined;
    req.customerFilterCount = req.customerFilterCount || defaultCustomerFilterCount;
    store.apiStore
      .apiClientCustomer()
      .clientsForFilter({
        documentType: 'deal',
        agreementCode: undefined,
        approvalStateCodes: req.approvalStateCodes?.length ? req.approvalStateCodes : undefined,
        branchOfficeCodes: undefined,
        containsAttachments: req.containsAttachments || undefined,
        requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        employeeSet: req.employeeSetCode || undefined,
        hasBills: req.hasBills || undefined,
        hasPS: req.hasPS || undefined,
        queryDocuments: req.query || undefined,
        dealStateCodes: req.status?.length ? req.status : undefined,
        dealListType: req.type,
        page: req.customerFilterPage || undefined,
        count: req.customerFilterCount,
        queryCustomers: req.queryCustomer || undefined,
        documentTypeCodes: req.documentTypeCodes?.length ? req.documentTypeCodes : undefined,
      })
      .then(res => {
        store.setFilterCustomerResult(res.data);
      })
      .catch(e => {
        console.warn('loadFilterCustomerCodes req', e);
        runInAction(() => {
          store.isFilterCustomerLoading = false;
        });
      });
  }

  // запрос данных по сделкам с бэкенда
  executeOne(store: DealListStore): void {
    const lastLoadStarted = new Date();
    const req = Object.assign({}, store.request);
    runInAction(() => {
      if (req.quickRange) {
        store.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
      store.lastLoadStarted = lastLoadStarted;
      store.isLoading = true;
    });
    req.page = req.page > 1 ? req.page : undefined;
    req.count = req.count || defaultPageSize;
    if (req.type === 'clientOrders') {
      store.apiStore
        .apiClientCustomer()
        .clientsItemOrders(
          req.clientCode,
          req.page || undefined,
          req.count,
          req.creationMonth ? formatDateSwagger(req.creationMonth) : undefined,
          req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
          req.dateTo ? formatDateSwagger(req.dateTo) : undefined
        )
        .then((res): void => {
          store.setResult(getCallContext(res), req, res.data.orders);
          runInAction(() => {
            store.totalStoreEntity = totalStoreGenerate(res.data.ordersTotalCount, null, null);
            // store.totalStoreEntity = totalStoreGenerate(res.data.ordersTotalCount, res.data.ordersTotalWeight, res.data.ordersTotalCost);
          });
        });
      return;
    }

    if (req.type === 'clientDeals') {
      store.apiStore
        .apiClientDeal()
        .dealsList(
          req.agreementCode || undefined,
          null,
          req.hasBills || undefined,
          req.hasPS || undefined,
          req.containsAttachments || undefined,
          req.clientCode || undefined,
          req.customerCodes?.length ? req.customerCodes : undefined,
          req.count,
          req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
          req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
          req.employeeCode || undefined,
          req.employeeSetCode || undefined,
          req.creationMonth ? formatDateSwagger(req.creationMonth) : undefined,
          req.page || undefined,
          req.query || undefined,
          undefined,
          req.status?.length ? req.status : undefined,
          req.approvalStateCodes || undefined,
          req.documentTypeCodes || undefined,
          req.type
        )
        .then((res): void => {
          store.setResult(getCallContext(res), req, res.data.deals);
          runInAction(() => {
            store.totalStoreEntity = totalStoreGenerate(res.data.dealsTotalCount, res.data.dealsTotalWeight, res.data.dealsTotalCost);
          });
        });
      return;
    }

    store.apiStore
      .apiClientDeal()
      .dealsList(
        req.agreementCode || undefined,
        null,
        req.hasBills || undefined,
        req.hasPS || undefined,
        req.containsAttachments || undefined,
        undefined, // ?
        req.customerCodes?.length ? req.customerCodes : undefined,
        req.count,
        req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        req.employeeCode || undefined,
        req.employeeSetCode || undefined,
        undefined, // ?
        req.page || undefined,
        req.query || undefined,
        undefined,
        req.status?.length ? req.status : undefined,
        req.approvalStateCodes || undefined,
        req.documentTypeCodes || undefined,
        req.type
      )
      .then((res): void => {
        store.setResult(getCallContext(res), req, res.data.deals);
        runInAction(() => {
          store.totalStoreEntity = totalStoreGenerate(res.data.dealsTotalCount, res.data.dealsTotalWeight, res.data.dealsTotalCost);
        });
      });

    // store.apiStore
    //   .apiClientMy()
    //   .myDeals(
    //     req.query || undefined,
    //     req.page || undefined,
    //     req.count,
    //     req.status || undefined,
    //     req.type,
    //     null,
    //     req.employeeCode || undefined,
    //     req.employeeSetCode || undefined,
    //     req.agreementCode || undefined,
    //     req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
    //     req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
    //     req.containsAttachments || undefined
    //   )
    //   .then((res: AxiosResponse<MyDealsResponse>): void => {
    //     store.setResult(getCallContext(res), req, res.data.deals, res.data.dealsTotalCount);
    //   });
  }
}
