import React, { FC, JSX, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { SaleShipment } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { formatDateSwagger, formatNumber3 } from '@mx-ui/helpers';
import { ThreadLink } from '../Bills/ThreadLink';
import { renderPriceCur } from '../../utils/renderFormat';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import { ShipmentsListStore } from '../../store/Documents/ShipmentsListStore';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from '@mx-ui/ui';

interface ShipmentItemTableViewProps {
  shipment: SaleShipment;
  shipmentsListStore?: ShipmentsListStore;
}

export const ShipmentItemTableView: FC<ShipmentItemTableViewProps> = observer(({ shipment, shipmentsListStore }): JSX.Element => {
  const handleCustomerChange = useCallback(
    (customerCode?: string) => {
      if (shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      shipmentsListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
    },
    [shipmentsListStore]
  );

  const handleCustomerRemoveChange = useCallback(
    (customerCode?: string) => {
      if (!shipmentsListStore.request?.customerCodes?.includes(customerCode)) {
        return;
      }
      const newCustomerCodes = shipmentsListStore.request?.customerCodes?.filter(i => i !== customerCode);
      shipmentsListStore.mergeRequest({ customerCodes: newCustomerCodes });
    },
    [shipmentsListStore]
  );
  return (
    <Paper sx={{ p: 1, mb: 1.5, width: '100%' }}>
      <Grid container alignItems="center" justifyContent={'space-between'} spacing={1}>
        <Grid item flex={'0 0 400px'}>
          <Grid container alignItems="center" flexWrap={'nowrap'}>
            <Grid item mr={1}>
              <CardTravelOutlinedIcon fontSize="medium" color="primary" />
            </Grid>
            <Grid item>
              <Link href={`/app/shipments/${encodeURIComponent(shipment.documentNumber)}`} color="primary" sx={{ fontWeight: 600 }}>
                {`${shipment.documentNumber}`}
              </Link>
            </Grid>

            <Grid item>
              <ThreadLink color="primary" link={`/app/thread/shipments/${encodeURIComponent(shipment.documentNumber)}`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item flex={'0 1 220px'} textAlign={'left'}>
          <Typography color="text.secondary">{' от ' + formatDateSwagger(shipment.documentDate)}</Typography>
        </Grid>
        <Grid item flex={'0 1 90px'} textAlign={'right'}>
          <Typography color="text.secondary">
            {formatNumber3(shipment.positions?.reduce((acc, p) => acc + p.quantityTne, 0))}
            {` т`}
          </Typography>
        </Grid>
        <Grid item flex={'0 1 25%'} textAlign={'right'}>
          <Typography style={{ fontWeight: 700 }} color="textPrimary">
            {renderPriceCur(shipment.positions?.reduce((acc, p) => acc + p.totalCost, 0))}
          </Typography>
        </Grid>
        {shipment.recipientCustomer && (
          <Grid item xs={12} mt={1}>
            <Grid container alignItems={'center'}>
              <Grid item xs={12} md={'auto'} mr={1}>
                <Typography variant="body2">ИНН {shipment.recipientCustomer?.inn || '—'}</Typography>
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <Link
                  href={`/app/clients/${encodeURIComponent(shipment.recipientCustomer?.code)}`}
                  color="text.secondary"
                  sx={{ fontSize: 15, fontWeight: 600 }}
                >
                  {shipment.recipientCustomer?.shortTitle || shipment.recipientCustomer?.title}
                </Link>
              </Grid>
              {shipmentsListStore && shipment.recipientCustomer.code ? (
                shipmentsListStore.request?.customerCodes?.includes(shipment.recipientCustomer.code) ? (
                  <Grid item ml={1}>
                    <IconButton
                      size={'small'}
                      onClick={() => handleCustomerRemoveChange(shipment.recipientCustomer.code)}
                      title={`Убрать фильтрацию по ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                    >
                      <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid item ml={1}>
                    <IconButton
                      size={'small'}
                      onClick={() => handleCustomerChange(shipment.recipientCustomer.code)}
                      title={`Найти отгрузки ${shipment.recipientCustomer.shortTitle || shipment.recipientCustomer.title}`}
                    >
                      <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Grid>
                )
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
});
