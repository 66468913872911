import React, { CSSProperties, FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { BillListItem } from './BillListItem';
import { Bill, Customer } from '../../api/marketx';
import { BillListStore } from '../../store/Documents/BillListStore';
import { ClientItemStore } from '../../store/ClientItemStore';
import { BillListItemShortened } from './BillListItemShortened';
import { BillListTableView } from './BillListTableView';
import { SalesListSkeleton } from '../Agreements/AgreementList';
import { BillItemInvoicingDialog } from './BillInvoicing/BillItemInvoicingDialog';

interface BillListProps {
  bills: BillListStore;
  client?: ClientItemStore;
  customer?: Customer;
  viewType?: string;
  withoutFilters?: boolean;
}
interface BillStackListProps {
  billsStore: BillListStore;
  items: Bill[];
  client?: ClientItemStore;
  customer?: Customer;
  viewType?: string;
  withoutFilters?: boolean;
}

const BillStackList: FC<BillStackListProps> = observer(
  ({ items = [], client, customer, viewType, billsStore, withoutFilters = false }): JSX.Element => (
    <Stack width="100%">
      {viewType === 'list'
        ? items.map((bill: Bill) => (
            <BillListItem
              key={bill.documentNumber}
              bill={bill}
              client={client}
              customer={customer}
              invoicingStore={billsStore.invoicingStore}
              billListStore={billsStore}
              withoutFilters={withoutFilters}
            />
          ))
        : viewType === 'table-view'
        ? items.map((bill: Bill) => (
            <BillListTableView
              key={bill.documentNumber}
              bill={bill}
              client={client}
              customer={customer}
              billListStore={billsStore}
              withoutFilters={withoutFilters}
            />
          ))
        : items.map((bill: Bill) => (
            <BillListItemShortened
              key={bill.documentNumber}
              bill={bill}
              invoicingStore={billsStore.invoicingStore}
              client={client}
              customer={customer}
              billListStore={billsStore}
              withoutFilters={withoutFilters}
            />
          ))}
      <BillItemInvoicingDialog billStore={billsStore} />
    </Stack>
  )
);

const listStyle = { overflowX: 'hidden', overflowY: 'hidden', padding: 1 } as CSSProperties;
const listSkeleton = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;

export const BillList: FC<BillListProps> = observer(
  ({ bills, client, customer, viewType = 'list', withoutFilters = false }): JSX.Element => {
    if (!bills.isLoaded) {
      return <SalesListSkeleton viewType={viewType} />;
    }

    if (!bills.items?.length) {
      return <Box minHeight={300}>Нет данных</Box>;
    }

    return (
      <Box width={'inherit'}>
        <InfiniteScroll
          dataLength={bills.items.length}
          next={bills.loadMore}
          hasMore={bills.hasMore}
          style={listStyle}
          loader={listSkeleton}
        >
          <BillStackList
            items={bills.items}
            client={client}
            customer={customer}
            billsStore={bills}
            viewType={viewType}
            withoutFilters={withoutFilters}
          />
        </InfiniteScroll>
      </Box>
    );
  }
);
