import React, { FC, useEffect, useRef, useState } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from './Modal';

import { DateRange } from './DateRange';
import {
  convertToDate,
  getCurrentMonthRange,
  getCurrentWeekRange,
  getDateRangeFromNow,
  getPrevMonthRange,
  getPrevWeekRange,
  getWeekRangeByQuantity,
} from '@mx-ui/helpers';

export const quickDateRangesTypes = [
  'today',
  'yesterday',
  'current_week',
  'previous_week',
  'two_weeks',
  'current_month',
  'not_selected',
  'previous_month',
  'custom_period',
] as const;
export type QuickDateRangesType = (typeof quickDateRangesTypes)[number];

export type RangeVariant = {
  value: QuickDateRangesType;
  text: string;
  label?: string;
};
export const quickDateRanges: RangeVariant[] = [
  // {
  //   value: 'today',
  //   text: 'Сегодня',
  // },
  // {
  //   value: 'yesterday',
  //   text: 'Вчера',
  // },
  // {
  //   value: 'current_week',
  //   text: 'За текущую неделю',
  // },
  // {
  //   value: 'previous_week',
  //   text: 'За предыдущую неделю',
  // },
  {
    value: 'not_selected',
    text: 'Выберите дату',
    label: 'Не выбрано',
  },
  {
    value: 'two_weeks',
    text: 'За две недели',
  },
  {
    value: 'current_month',
    text: 'За текущий месяц',
  },

  {
    value: 'previous_month',
    text: 'За предыдущий месяц',
  },
  {
    value: 'custom_period',
    text: 'Выбрать период...',
  },
];

export const getDatesFromQuickRange = (timeRange: RangeVariant): DateRange => {
  if (timeRange?.value === 'current_month') {
    return getCurrentMonthRange();
  } else if (timeRange?.value === 'previous_month') {
    return getPrevMonthRange();
  } else if (timeRange?.value === 'two_weeks') {
    return getWeekRangeByQuantity(2);
  } else if (timeRange?.value === 'previous_week') {
    return getPrevWeekRange();
  } else if (timeRange?.value === 'current_week') {
    return getCurrentWeekRange();
  } else if (timeRange?.value === 'yesterday') {
    return getDateRangeFromNow(1, false);
  } else if (timeRange?.value === 'not_selected') {
    return { start: null, end: null };
  }
  return getDateRangeFromNow(0, true);
};

interface MenuButtonOnDateRangeSelect {
  (rangeVariant: RangeVariant, value: DateRange): void;
}

type MenuButtonProps = {
  onDateRangeSelect: MenuButtonOnDateRangeSelect;
  defaultQuickRangeValue: string;
  defaultStart?: Date;
  quickRange?: RangeVariant;
  defaultEnd?: Date;
  rangeModalText?: string;
  onSetQuickRange?: any;
  rangeModalStartDateDefault?: Date;
  rangeModalEndDateDefault?: Date;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};
/**
 * дейтпикер, более функциональный
 * начинает работать не при запуске (как MenuButton), а по функции
 */
const MenuButtonNew: FC<MenuButtonProps> = ({
  onDateRangeSelect,
  defaultQuickRangeValue = 'current_week',
  defaultStart = null,
  quickRange,
  onSetQuickRange,
  defaultEnd = null,
  rangeModalText = 'Укажите период',
  rangeModalStartDateDefault = null,
  rangeModalEndDateDefault = null,
  color = 'primary',
}): JSX.Element => {
  const actionRef = useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const [selectedQuickRangeText, setSelectedQuickRangeText] = useState<string>(quickRange?.text || '');
  useEffect(() => {
    setSelectedQuickRangeText(quickRange?.text);
  }, [quickRange?.value]);
  useEffect(() => {
    if (defaultQuickRangeValue === 'custom_period' && defaultStart !== null && defaultEnd !== null) {
      handleSetDateRange(quickRange, { start: defaultStart, end: defaultEnd }, true);
    } else {
      const range = getDatesFromQuickRange(quickRange);
      handleSetDateRange(quickRange, range, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickRange?.value]);

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  const handleModalSave = (range: DateRange): void => {
    const newQuickRange = quickDateRanges.find(r => r.value === 'custom_period');

    if (onSetQuickRange) {
      onSetQuickRange(newQuickRange);
    }
    handleSetDateRange(newQuickRange, range);
  };

  const handleSetDateRange = (rangeVariant: RangeVariant, range: DateRange, isFirst = false): void => {
    if (rangeVariant?.value === 'custom_period') {
      setSelectedQuickRangeText(`период с ${convertToDate(range.start)} по ${convertToDate(range.end)}`);
      if (!isFirst) {
        onDateRangeSelect(rangeVariant, range);
      }
    } else {
      const value = getDatesFromQuickRange(rangeVariant);
      if (!isFirst) {
        onDateRangeSelect(rangeVariant, {
          start: value.start,
          end: value.end,
        } as DateRange);
      }
    }
  };

  const handleMenuItemClick = (selectedTimeRange: RangeVariant): void => {
    if (selectedTimeRange.value === 'custom_period') {
      setIsModalOpen(true);
    } else {
      if (onSetQuickRange) {
        onSetQuickRange(selectedTimeRange);
      }
      setSelectedQuickRangeText(selectedTimeRange.text);
      const range = getDatesFromQuickRange(selectedTimeRange);
      handleSetDateRange(selectedTimeRange, range);
    }
    setTimeout(() => {
      setMenuOpen(false);
    }, 100);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={handleModalSave}
        text={rangeModalText}
        startDateDefault={rangeModalStartDateDefault}
        endDateDefault={rangeModalEndDateDefault}
      />
      <Button ref={actionRef} onClick={() => setMenuOpen(true)} color={color} startIcon={<CalendarTodayIcon fontSize="small" />}>
        {selectedQuickRangeText}
      </Button>
      <Menu
        anchorEl={actionRef.current}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {quickDateRanges.map(timeRange => (
          <MenuItem key={timeRange.value} onClick={() => handleMenuItemClick(timeRange)}>
            {timeRange.label || timeRange.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default MenuButtonNew;
